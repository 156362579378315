import { useCallback } from 'react';

export const useCalendarHandlers = ({
  mounted,
  prepareTaskForEdit,
  prepareEventForEdit,
  availableUsers,
  setSelectedEvent,
  setTaskDialogOpen,
  setEventDialogOpen
}) => {
  const handleEventClick = useCallback((event) => {
    if (!event) return;

    if (event.type === 'task') {
      const preparedTask = prepareTaskForEdit(event, availableUsers);
      setSelectedEvent(preparedTask);
      setTaskDialogOpen(true);
    } else {
      const preparedEvent = prepareEventForEdit(event);
      setSelectedEvent(preparedEvent);
      setEventDialogOpen(true);
    }
  }, [prepareTaskForEdit, prepareEventForEdit, setSelectedEvent, setTaskDialogOpen, setEventDialogOpen, availableUsers]);

  const handleSlotSelect = useCallback((slotInfo) => {
    if (!slotInfo) return;

    const newEvent = {
      start: slotInfo.start,
      end: slotInfo.end,
      allDay: slotInfo.slots.length === 1,
      assignedUsers: [],
      status: 'pending'
    };

    setSelectedEvent(newEvent);
    setEventDialogOpen(true);
  }, [setSelectedEvent, setEventDialogOpen]);

  return {
    handleEventClick,
    handleSlotSelect
  };
}; 