import { useCallback } from 'react';
import { format } from 'date-fns';
import { createNonTaskEntry, updateNonTaskEntry, deleteNonTaskEntry } from '../../../services/bidService';

export const useEventManagement = (bidId, onSuccess, onError) => {
  const handleAddEvent = useCallback(async (eventData) => {
    try {
      if (!eventData.title?.trim()) {
        throw new Error('Event title is required');
      }
      if (!eventData.start) {
        throw new Error('Date and time are required');
      }

      const eventToSave = {
        ...eventData,
        title: eventData.title.trim(),
        start: eventData.start,
        end: eventData.start,
        assignedUsers: eventData.assignedUsers || []
      };

      const result = await createNonTaskEntry(bidId, eventToSave);
      await onSuccess('Event added successfully');
      return true;
    } catch (error) {
      onError(error.message || 'Failed to add event');
      return false;
    }
  }, [bidId, onSuccess, onError]);

  const handleEditEvent = useCallback(async (eventId, eventData) => {
    try {
      if (!eventData.title?.trim()) {
        throw new Error('Event title is required');
      }
      if (!eventData.start) {
        throw new Error('Date and time are required');
      }

      const eventToSave = {
        ...eventData,
        title: eventData.title.trim(),
        start: eventData.start,
        end: eventData.start,
        assignedUsers: Array.isArray(eventData.assignedUsers) ? eventData.assignedUsers : []
      };

      const result = await updateNonTaskEntry(bidId, eventId, eventToSave);
      await onSuccess('Event updated successfully');
      return true;
    } catch (error) {
      onError(error.message || 'Failed to update event');
      return false;
    }
  }, [bidId, onSuccess, onError]);

  const handleDeleteEvent = useCallback(async (eventId) => {
    try {
      if (!eventId) {
        throw new Error('Event ID is required for deletion');
      }
      if (!bidId) {
        throw new Error('Bid ID is required for deletion');
      }

      await deleteNonTaskEntry(bidId, eventId);
      onSuccess('Event deleted successfully');
      return true;
    } catch (error) {
      const errorMessage = error.response?.status === 404 
        ? 'Event not found or already deleted'
        : error.message || 'Failed to delete event';
      onError(errorMessage);
      throw error;
    }
  }, [bidId, onSuccess, onError]);

  const prepareEventForEdit = useCallback((event) => {
    if (!event) return null;

    // Parse and format the date for the datetime-local input
    let formattedDate = '';
    try {
      const eventDate = new Date(event.start);
      formattedDate = format(eventDate, "yyyy-MM-dd'T'HH:mm");
    } catch (error) {
      // Silently handle date formatting errors
      formattedDate = '';
    }

    return {
      id: event.id,
      title: event.title || '',
      start: formattedDate,
      type: event.type || 'non-task',
      status: event.status || 'not_started',
      assignedUsers: event.assignedUsers || [],
      description: event.description || ''
    };
  }, []);

  return {
    handleAddEvent,
    handleEditEvent,
    handleDeleteEvent,
    prepareEventForEdit
  };
}; 