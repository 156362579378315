import { useState, useCallback } from 'react';

const defaultDate = new Date();

export const useCalendarState = () => {
  const [view, setView] = useState('agenda');
  const [date, setDate] = useState(defaultDate);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleViewChange = useCallback((newView) => {
    if (newView && newView !== view) {
      setView(newView);
      if (newView === 'month') {
        const now = new Date();
        now.setDate(1);
        setDate(now);
      }
    }
  }, [view]);

  const handleNavigate = useCallback((newDate) => {
    if (newDate && newDate !== date) {
      setDate(newDate);
    }
  }, [date]);

  const handleTaskDialogClose = useCallback(() => {
    setTaskDialogOpen(false);
    setSelectedEvent(null);
  }, []);

  const handleEventDialogClose = useCallback(() => {
    setEventDialogOpen(false);
    setSelectedEvent(null);
  }, []);

  const handleAddNewTask = useCallback(() => {
    const now = new Date();
    const newTask = {
      start: now,
      end: now,
      type: 'task',
      status: 'not_started',
      assignedTo: null,
      assignedToName: 'Unassigned',
      description: ''
    };
    setSelectedEvent(newTask);
    setTaskDialogOpen(true);
  }, []);

  const handleAddNewEvent = useCallback(() => {
    const now = new Date();
    const newEvent = {
      start: now,
      end: now,
      type: 'non-task',
      status: 'not_started',
      assignedTo: null,
      assignedToName: 'Unassigned',
      description: '',
      allDay: false
    };
    setSelectedEvent(newEvent);
    setEventDialogOpen(true);
  }, []);

  return {
    view,
    date,
    taskDialogOpen,
    eventDialogOpen,
    selectedEvent,
    setSelectedEvent,
    setTaskDialogOpen,
    setEventDialogOpen,
    handlers: {
      handleViewChange,
      handleNavigate,
      handleTaskDialogClose,
      handleEventDialogClose,
      handleAddNewTask,
      handleAddNewEvent
    }
  };
}; 