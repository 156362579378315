import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Chip
} from '@mui/material';

/**
 * Dialog component for creating a new account
 * Matches Pipedrive organization schema for seamless integration
 */
const CreateAccountDialog = ({ 
  open, 
  onClose, 
  onCreate, 
  formData, 
  onFormChange,
  isLoading = false,
  owners = [],
  labels = []
}) => {
  const handleChange = (field) => (event) => {
    onFormChange({ ...formData, [field]: event.target.value });
  };

  const handleLabelsChange = (event, newValue) => {
    onFormChange({ ...formData, labels: newValue });
  };

  const isValid = Boolean(formData.name?.trim());

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create New Account</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Account Name"
            fullWidth
            required
            value={formData.name || ''}
            onChange={handleChange('name')}
            error={formData.name === ''}
            helperText={formData.name === '' ? 'Account name is required' : ''}
            disabled={isLoading}
          />
          <TextField
            label="Address"
            fullWidth
            multiline
            rows={2}
            value={formData.address || ''}
            onChange={handleChange('address')}
            disabled={isLoading}
          />
          <FormControl fullWidth disabled={isLoading}>
            <InputLabel>Visibility</InputLabel>
            <Select
              value={formData.visible_to || 3}
              onChange={handleChange('visible_to')}
              label="Visibility"
            >
              <MenuItem value={1}>Owner only</MenuItem>
              <MenuItem value={3}>Entire company</MenuItem>
            </Select>
          </FormControl>
          {owners.length > 0 && (
            <FormControl fullWidth disabled={isLoading}>
              <InputLabel>Owner</InputLabel>
              <Select
                value={formData.owner_id || ''}
                onChange={handleChange('owner_id')}
                label="Owner"
              >
                {owners.map(owner => (
                  <MenuItem key={owner.id} value={owner.id}>
                    {owner.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Autocomplete
            multiple
            options={labels}
            value={formData.labels || []}
            onChange={handleLabelsChange}
            disabled={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Labels"
                placeholder="Add labels"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  color="primary"
                  variant="outlined"
                />
              ))
            }
          />
          <TextField
            label="Industry"
            fullWidth
            value={formData.industry || ''}
            onChange={handleChange('industry')}
            disabled={isLoading}
          />
          <TextField
            label="Website"
            fullWidth
            type="url"
            value={formData.website || ''}
            onChange={handleChange('website')}
            disabled={isLoading}
          />
          <TextField
            label="LinkedIn URL"
            fullWidth
            type="url"
            value={formData.linkedinUrl || ''}
            onChange={handleChange('linkedinUrl')}
            disabled={isLoading}
          />
          <TextField
            label="NZBN"
            fullWidth
            value={formData.nzbn || ''}
            onChange={handleChange('nzbn')}
            disabled={isLoading}
            helperText="New Zealand Business Number"
          />
          <TextField
            label="Phone"
            fullWidth
            value={formData.phone || ''}
            onChange={handleChange('phone')}
            disabled={isLoading}
          />
          <TextField
            label="Email"
            fullWidth
            type="email"
            value={formData.email || ''}
            onChange={handleChange('email')}
            disabled={isLoading}
          />
          <TextField
            label="Notes"
            fullWidth
            multiline
            rows={3}
            value={formData.notes || ''}
            onChange={handleChange('notes')}
            disabled={isLoading}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button 
          onClick={onCreate} 
          variant="contained" 
          disabled={!isValid || isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    visible_to: PropTypes.number,
    owner_id: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    industry: PropTypes.string,
    website: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    notes: PropTypes.string,
    linkedinUrl: PropTypes.string,
    nzbn: PropTypes.string
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  owners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  labels: PropTypes.arrayOf(PropTypes.string)
};

export default CreateAccountDialog; 