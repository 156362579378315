import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  Divider,
  CircularProgress,
  Alert,
  IconButton,
  Chip,
  Snackbar
} from '@mui/material';
import {
  TrendingUp,
  People as PeopleIcon,
  AttachMoney,
  Loyalty,
  Assessment as AssessmentIcon,
  Timeline as TimelineIcon,
  Add as AddIcon,
  Description,
  CalendarToday,
  ArrowUpward,
  ArrowDownward,
  Business,
  People,
  ArrowBack as ArrowBackIcon,
  Phone as PhoneIcon,
  Business as BusinessIcon,
  CheckCircle as CheckCircleIcon,
  CalendarToday as CalendarTodayIcon,
  Email as EmailIcon,
  Coffee as CoffeeIcon,
  Event as EventIcon,
  BusinessCenter as BusinessCenterIcon,
  LocationOn as LocationOnIcon,
  Web as WebIcon,
  LinkedIn as LinkedInIcon,
  Description as DescriptionIcon,
  Notes as NotesIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import { accountManagementService } from '../../services/accountManagementService';
import { dealService } from '../../services/dealService';
import { contactService } from '../../services/contactService';
import { accountService } from '../../services/accountService';
import { logError } from '../../utils/errorLogging';
import { Link } from '@mui/material';

// Add stage mapping at the top of the file
const dealStages = {
  '1': 'Qualified',
  '2': 'Contact Made',
  '3': 'Needs Defined',
  '4': 'Proposal Made',
  '5': 'Negotiations Started'
};

// Helper function to get stage name
const getStageNameFromId = (stageId) => {
  return dealStages[stageId] || 'Unknown';
};

// Helper function to format currency
const formatCurrency = (value) => {
  const num = parseFloat(value);
  if (num >= 1000000) {
    return `$${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `$${(num / 1000).toFixed(1)}K`;
  }
  return `$${num.toFixed(0)}`;
};

// Helper component for metric display with better text handling
const MetricDisplay = ({ label, value, trend, prefix = '', suffix = '' }) => (
  <Box sx={{ textAlign: 'center', p: 2 }}>
    <Typography 
      variant="subtitle1" 
      gutterBottom 
      sx={{ 
        height: 40,
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2
      }}
    >
      {label}
    </Typography>
    <Typography 
      variant="h4" 
      color="primary"
      sx={{ 
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {prefix}{value}{suffix}
    </Typography>
    {trend && (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
        {trend.startsWith('+') ? (
          <ArrowUpward fontSize="small" color="success" />
        ) : (
          <ArrowDownward fontSize="small" color="error" />
        )}
        <Typography 
          variant="body2" 
          color={trend.startsWith('+') ? 'success.main' : 'error.main'}
          sx={{ ml: 0.5 }}
        >
          {trend}
        </Typography>
      </Box>
    )}
  </Box>
);

// Update ContactRolesList to use error logging utility
const ContactRolesList = ({ contacts }) => {
  if (contacts.length === 0) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography component="div" color="text.secondary">
          No contacts found in this category
        </Typography>
      </Box>
    );
  }

  return (
    <List>
      {contacts.map((contact) => (
        <ListItem key={contact.id}>
          <ListItemAvatar>
            <Avatar>
              <PeopleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={contact.name}
            secondary={
              <Box>
                <Typography component="div" variant="body2">
                  {contact.email || 'No email'}
                </Typography>
                <Typography 
                  component="div" 
                  sx={{ display: 'block', color: 'text.secondary' }}
                >
                  {contact.phone || 'No phone'}
                </Typography>
                <Typography 
                  component="div" 
                  sx={{ display: 'block', color: 'text.secondary' }}
                >
                  Role: {contact.role}
                </Typography>
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const AccountsDashboard = ({ userData, selectedOrg }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useOutletContext();
  const selectedAccount = context?.selectedAccount;
  const refreshAccounts = context?.refreshAccounts;
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [activities, setActivities] = useState([]);
  const [health, setHealth] = useState(null);
  const [deals, setDeals] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    if (selectedOrg) {
      fetchDashboardData();
    }
  }, [selectedOrg, selectedAccount?.id]);

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Determine if we need to fetch Pipedrive data
      const isPipedriveAccount = selectedAccount?.source === 'pipedrive';
      const pipedriveId = isPipedriveAccount ? selectedAccount.pipedriveId?.split('_').pop() : null;

      // Fetch all required data in parallel with better error handling
      const [metricsData, activitiesData, healthData, dealsResponse, contactsResponse, orgsResponse] = await Promise.allSettled([
        accountManagementService.getAccountAnalytics(
          selectedOrg, 
          selectedAccount?.id,
          selectedAccount?.source
        ),
        accountManagementService.listActivities(selectedOrg, selectedAccount?.id),
        accountManagementService.getAccountHealth(selectedOrg, selectedAccount?.id),
        dealService.listDeals(selectedOrg, selectedAccount),
        contactService.listContacts(selectedOrg, selectedAccount?.id),
        accountService.listAccounts(selectedOrg)
      ]);

      // Handle any rejected promises
      const rejectedPromises = [
        { name: 'metrics', result: metricsData },
        { name: 'activities', result: activitiesData },
        { name: 'health', result: healthData },
        { name: 'deals', result: dealsResponse },
        { name: 'contacts', result: contactsResponse },
        { name: 'organizations', result: orgsResponse }
      ].filter(p => p.result.status === 'rejected');

      if (rejectedPromises.length > 0) {
        showSnackbar('Some dashboard data could not be loaded', 'warning');
      }

      // Safely extract data from Promise.allSettled results
      const metrics = metricsData.status === 'fulfilled' ? metricsData.value?.data || {} : {};
      const activities = activitiesData.status === 'fulfilled' ? activitiesData.value?.activities || [] : [];
      const deals = dealsResponse.status === 'fulfilled' ? dealsResponse.value?.deals || [] : [];
      const contacts = contactsResponse.status === 'fulfilled' ? contactsResponse.value?.contacts || [] : [];
      const health = healthData.status === 'fulfilled' ? healthData.value?.health || {} : {};
      const orgs = orgsResponse.status === 'fulfilled' ? orgsResponse.value?.accounts || [] : [];

      setOrganizations(orgs);

      // Filter deals for specific account
      const filteredDeals = selectedAccount ? deals.filter(deal => {
        if (isPipedriveAccount) {
          const dealOrgId = String(deal.organization_id || deal.account?.id || '');
          const dealAccountId = String(deal.account?.id || '');
          const targetId = String(pipedriveId || '');
          
          return deal.account?.source === 'pipedrive' && 
            (dealOrgId === targetId || dealAccountId === targetId || 
             deal.organization_name === selectedAccount.name);
        }
        
        // For Puāwai accounts
        return deal.account?.id === selectedAccount.id || 
               deal.account_id === selectedAccount.id ||
               deal.organization_name === selectedAccount.name;
      }) : deals;

      // Filter contacts for specific account
      const filteredContacts = selectedAccount ? contacts.filter(contact => {
        if (isPipedriveAccount) {
          // For Pipedrive accounts, check organization_id and account reference
          const contactOrgId = contact.organization_id || contact.pipedriveData?.org_id;
          const contactAccountId = contact.account?.id;
          
          return (contact.source === 'pipedrive' || contact.source === 'both') && 
            (String(contactOrgId) === String(pipedriveId) || 
             String(contactAccountId) === String(selectedAccount.id) ||
             contact.organization_name === selectedAccount.name);
        }
        // For local contacts, check all possible account references
        return contact.accountId === selectedAccount.id || 
               contact.account?.id === selectedAccount.id ||
               contact.account === selectedAccount.id ||
               contact.accounts?.includes(selectedAccount.id) ||
               contact.organization_id === selectedAccount.id;
      }) : contacts;

      // Calculate combined metrics
      const combinedMetrics = {
        totalDeals: filteredDeals.length,
        totalDealValue: filteredDeals.reduce((sum, deal) => {
          const value = parseFloat(deal.value) || 0;
          return sum + value;
        }, 0),
        totalContacts: filteredContacts.length,
        averageDealSize: filteredDeals.length > 0 
          ? filteredDeals.reduce((sum, deal) => sum + (parseFloat(deal.value) || 0), 0) / filteredDeals.length 
          : 0,
        trends: metrics.trends || {
          deals: '+0%',
          value: '+0%',
          contacts: '+0%',
          averageSize: '+0%'
        }
      };

      // Update state with filtered data
      setMetrics(combinedMetrics);
      setActivities(activities);
      setHealth(health);
      setDeals(filteredDeals);
      setContacts(filteredContacts);
      setError(null);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data');
      setMetrics(null);
      setActivities([]);
      setHealth(null);
      setDeals([]);
      setContacts([]);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Dashboard Header */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">
          {selectedAccount ? selectedAccount.name : 'Company Overview'}
        </Typography>
        {selectedAccount && (
          <Chip 
            label={selectedAccount.source}
            color={selectedAccount.source === 'puawai' ? 'primary' : 'default'}
          />
        )}
      </Box>

      {/* Key Metrics */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2 }}>
            <MetricDisplay
              label="Total Deals"
              value={metrics?.totalDeals || 0}
              trend={metrics?.trends?.deals}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2 }}>
            <MetricDisplay
              label="Total Deal Value"
              value={formatCurrency(metrics?.totalDealValue || 0)}
              trend={metrics?.trends?.value}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2 }}>
            <MetricDisplay
              label="Total Contacts"
              value={metrics?.totalContacts || 0}
              trend={metrics?.trends?.contacts}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2 }}>
            <MetricDisplay
              label="Average Deal Size"
              value={formatCurrency(metrics?.averageDealSize || 0)}
              trend={metrics?.trends?.averageSize}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* Recent Activity and Deals */}
      <Grid container spacing={3}>
        {/* Recent Activity */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Recent Activity</Typography>
              <Typography variant="caption" color="text.secondary">
                {selectedAccount ? 'Account Activity' : 'All Activity'}
              </Typography>
            </Box>
            <List>
              {activities.slice(0, 5).map((activity, index) => (
                <React.Fragment key={activity.id || index}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        {activity.type === 'call' ? <PhoneIcon /> :
                         activity.type === 'meeting' ? <BusinessIcon /> :
                         activity.type === 'task' ? <CheckCircleIcon /> :
                         activity.type === 'deadline' ? <CalendarTodayIcon /> :
                         activity.type === 'email' ? <EmailIcon /> :
                         activity.type === 'lunch' ? <CoffeeIcon /> :
                         <EventIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Chip
                            size="small"
                            label={activity.type?.charAt(0).toUpperCase() + activity.type?.slice(1) || 'Event'}
                            color="primary"
                            variant="outlined"
                            sx={{ minWidth: '70px' }}
                          />
                          <Typography component="span" variant="body1" sx={{ ml: 1 }}>
                            {activity.pipedriveId ? 
                              (activity.data?.subject || activity.data?.title || 'Untitled Activity') :
                              (activity.subject || activity.title || 'Untitled Activity')}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <CalendarToday fontSize="small" />
                            {(() => {
                              let dateStr;
                              if (activity.pipedriveId) {
                                const dueDate = activity.data?.due_date;
                                const dueTime = activity.data?.due_time;
                                try {
                                  dateStr = dueDate ? 
                                    `${new Date(dueDate).toLocaleDateString()} ${dueTime || ''}` :
                                    activity.data?.add_time ? 
                                      `${new Date(activity.data.add_time).toLocaleDateString()}` :
                                      'No date set';
                                } catch (err) {
                                  dateStr = 'No date set';
                                }
                              } else {
                                const date = activity.due_date || activity.date;
                                const time = activity.due_time || activity.time;
                                try {
                                  dateStr = date ? 
                                    `${new Date(date).toLocaleDateString()} ${time || ''}` :
                                    'No date set';
                                } catch (err) {
                                  dateStr = 'No date set';
                                }
                              }
                              return dateStr;
                            })()}
                          </Box>
                          {(activity.description || activity.data?.note) && (
                            <Typography variant="body2" color="text.secondary">
                              {activity.description || activity.data?.note}
                            </Typography>
                          )}
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Chip 
                              size="small" 
                              label={activity.status === 'completed' || activity.data?.done ? 'Done' : 'Open'} 
                              color={activity.status === 'completed' || activity.data?.done ? 'success' : 'default'}
                            />
                            <Chip 
                              size="small" 
                              label={activity.pipedriveId ? 'pipedrive' : 'puawai'} 
                              color={activity.pipedriveId ? 'primary' : 'default'}
                            />
                          </Box>
                        </Box>
                      }
                      secondaryTypographyProps={{ component: 'div' }}
                    />
                  </ListItem>
                  {index < activities.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {activities.length === 0 && (
                <ListItem>
                  <ListItemText 
                    primary="No recent activity"
                    secondary={`No activity found for ${selectedAccount ? 'this account' : 'any account'}`}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>

        {/* Recent Deals */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Recent Deals</Typography>
              <Typography variant="caption" color="text.secondary">
                {selectedAccount ? 'Account Deals' : 'All Deals'}
              </Typography>
            </Box>
            <List>
              {deals.slice(0, 5).map((deal, index) => {
                const accountInfo = deal.account?.name || 
                                  organizations.find(org => org.id === deal.account?.id)?.name ||
                                  deal.organization_name ||
                                  'Unassigned';
                
                const contactInfo = deal.person_name || 
                                  (deal.contact?.name) ||
                                  (deal.person_id ? `Contact ${deal.person_id}` : 'No Contact');

                return (
                  <React.Fragment key={deal.id || index}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <AttachMoney />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography component="div" variant="subtitle1">{deal.title}</Typography>
                            <Chip 
                              size="small" 
                              label={deal.source || 'puawai'} 
                              color={deal.source === 'pipedrive' ? 'secondary' : 'primary'}
                            />
                          </Box>
                        }
                        secondary={
                          <Box component="div">
                            <Typography component="div" variant="body2" sx={{ fontWeight: 'bold' }}>
                              {formatCurrency(deal.value)}
                            </Typography>
                            <Typography 
                              component="div" 
                              sx={{ display: 'block', color: 'text.secondary' }}
                            >
                              Stage: {getStageNameFromId(deal.stage_id)}
                            </Typography>
                            <Typography 
                              component="div" 
                              sx={{ display: 'block', color: 'text.secondary' }}
                            >
                              Account: {accountInfo}
                            </Typography>
                            <Typography 
                              component="div" 
                              sx={{ display: 'block', color: 'text.secondary' }}
                            >
                              Contact: {contactInfo}
                            </Typography>
                          </Box>
                        }
                        secondaryTypographyProps={{ component: 'div' }}
                      />
                    </ListItem>
                    {index < deals.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
              {deals.length === 0 && (
                <ListItem>
                  <ListItemText 
                    primary="No recent deals"
                    secondary={`No deals found for ${selectedAccount ? 'this account' : 'any account'}`}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AccountsDashboard; 