import React, { useState } from 'react';
import { 
  Paper, Typography, Box, Chip, IconButton, 
  TextField, MenuItem, Button, Grid,
  Tooltip, Divider, Link
} from '@mui/material';
import { 
  Visibility, VisibilityOff, Edit as EditIcon,
  Save as SaveIcon, Cancel as CancelIcon,
  Assignment as AssignmentIcon,
  Info as InfoIcon,
  Business as ClientIcon,
  Flag as ProgressIcon,
  RadioButtonChecked as StatusIcon,
  Event as DueDateIcon,
  Link as CrmIcon,
  Send as SubmissionIcon,
  Person as ContactIcon,
  Schedule as ValidityIcon,
  AccountCircle as OwnerIcon
} from '@mui/icons-material';
import BidDocumentLinks from './BidDocumentLinks';

const BidDetails = React.memo(({ 
  bid, 
  ownerDetails,
  onVisibilityChange,
  onUpdateBid,
  bidStatusOptions,
  currentGate,
  onGateChange
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedBid, setEditedBid] = useState(bid);

  const handleEdit = () => {
    setEditedBid(bid);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditedBid(bid);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      await onUpdateBid(editedBid);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving bid:', error);
      // Add error handling here if needed
    }
  };

  const handleChange = (field) => (event) => {
    setEditedBid(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const gateOptions = [
    { value: 0, label: 'Budget Setup' },
    { value: 1, label: 'Team Assembly' },
    { value: 2, label: 'Strategy Development' },
    { value: 3, label: 'Proposal Development' },
    { value: 4, label: 'Review & Submit' }
  ];

  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      {/* Header with Edit Controls */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Bid Details</Typography>
        {isEditing ? (
          <Box>
            <Button 
              startIcon={<SaveIcon />}
              onClick={handleSave} 
              variant="contained" 
              color="primary" 
              sx={{ mr: 1 }}
            >
              Save
            </Button>
            <Button 
              startIcon={<CancelIcon />}
              onClick={handleCancel} 
              variant="outlined" 
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <IconButton onClick={handleEdit} color="primary">
            <EditIcon />
          </IconButton>
        )}
      </Box>

      <Grid container spacing={3}>
        {/* Basic Information Group */}
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: 'primary.main' }}>
              Basic Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* Bid Name field */}
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Bid Name"
                    value={editedBid.name}
                    onChange={handleChange('name')}
                    margin="normal"
                    InputProps={{
                      startAdornment: <AssignmentIcon sx={{ mr: 1, color: 'text.secondary' }} />
                    }}
                  />
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AssignmentIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">Bid Name</Typography>
                    </Box>
                    <Typography variant="body1">{bid.name}</Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Client field */}
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Client"
                    value={editedBid.client}
                    onChange={handleChange('client')}
                    margin="normal"
                    InputProps={{
                      startAdornment: <ClientIcon sx={{ mr: 1, color: 'text.secondary' }} />
                    }}
                  />
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ClientIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">Client</Typography>
                    </Box>
                    <Typography variant="body1">{bid.client}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Status and Progress Group */}
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: 'primary.main' }}>
              Status & Progress
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* Progress Status field */}
                {isEditing ? (
                  <TextField
                    select
                    fullWidth
                    label="Progress Status"
                    value={currentGate}
                    onChange={(e) => onGateChange(e.target.value)}
                    margin="normal"
                    InputProps={{
                      startAdornment: <ProgressIcon sx={{ mr: 1, color: 'text.secondary' }} />
                    }}
                  >
                    {gateOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ProgressIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">Progress Status</Typography>
                    </Box>
                    <Typography variant="body1">
                      {gateOptions.find(option => option.value === currentGate)?.label || 'Budget Setup'}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Bid Status field */}
                {isEditing ? (
                  <TextField
                    select
                    fullWidth
                    label="Bid Status"
                    value={editedBid.status}
                    onChange={handleChange('status')}
                    margin="normal"
                    InputProps={{
                      startAdornment: <StatusIcon sx={{ mr: 1, color: 'text.secondary' }} />
                    }}
                  >
                    {bidStatusOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StatusIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">Bid Status</Typography>
                    </Box>
                    <Chip 
                      label={bid.status.charAt(0).toUpperCase() + bid.status.slice(1)}
                      color="primary"
                      variant="outlined"
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Due Date field */}
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Due Date"
                    type="datetime-local"
                    value={editedBid.dueDate}
                    onChange={handleChange('dueDate')}
                    margin="normal"
                    InputProps={{
                      startAdornment: <DueDateIcon sx={{ mr: 1, color: 'text.secondary' }} />
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <DueDateIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">Due Date</Typography>
                    </Box>
                    <Typography variant="body1">
                      {new Date(bid.dueDate).toLocaleString(undefined, { 
                        year: 'numeric', 
                        month: 'numeric', 
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      })}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Validity Period field */}
                {isEditing ? (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary">Validity Period (days)</Typography>
                      <Tooltip title="Number of days the bid remains valid after submission">
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      type="number"
                      value={editedBid.validityPeriod || ''}
                      onChange={handleChange('validityPeriod')}
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                    />
                  </Box>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">Validity Period</Typography>
                    <Typography variant="body1">
                      {bid.validityPeriod ? `${bid.validityPeriod} days` : 'Not specified'}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Client Information Group */}
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: 'primary.main' }}>
              Client Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* CRM Deal URL field */}
                {isEditing ? (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CrmIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">CRM Deal URL</Typography>
                      <Tooltip title="Link to the opportunity in your CRM system (e.g., Salesforce, HubSpot)">
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      value={editedBid.crmDealUrl || ''}
                      onChange={handleChange('crmDealUrl')}
                      placeholder="https://..."
                      InputProps={{
                        startAdornment: <CrmIcon sx={{ mr: 1, color: 'text.secondary' }} />
                      }}
                    />
                  </Box>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CrmIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">CRM Deal URL</Typography>
                    </Box>
                    {bid.crmDealUrl ? (
                      <Link href={bid.crmDealUrl} target="_blank" rel="noopener noreferrer">
                        View CRM Deal
                      </Link>
                    ) : (
                      <Typography variant="body1" color="text.secondary">Not specified</Typography>
                    )}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Client Contact Name field */}
                {isEditing ? (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary">Client Contact Name</Typography>
                      <Tooltip title="Name of the primary contact person for this bid">
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      value={editedBid.clientContactName || ''}
                      onChange={handleChange('clientContactName')}
                      placeholder="Full Name"
                    />
                  </Box>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">Client Contact Name</Typography>
                    <Typography variant="body1">
                      {bid.clientContactName || 'Not specified'}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Client Contact Email field */}
                {isEditing ? (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary">Client Contact Email</Typography>
                      <Tooltip title="Email address of the primary bid contact">
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      type="email"
                      value={editedBid.clientContactEmail || ''}
                      onChange={handleChange('clientContactEmail')}
                      placeholder="email@example.com"
                    />
                  </Box>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">Client Contact Email</Typography>
                    <Typography variant="body1">
                      {bid.clientContactEmail || 'Not specified'}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Submission Method field */}
                {isEditing ? (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <SubmissionIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">Submission Method</Typography>
                      <Tooltip title="How the final bid will be submitted to the client">
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Box>
                    <TextField
                      select
                      fullWidth
                      value={editedBid.submissionMethod || ''}
                      onChange={handleChange('submissionMethod')}
                      InputProps={{
                        startAdornment: <SubmissionIcon sx={{ mr: 1, color: 'text.secondary' }} />
                      }}
                    >
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="portal">Portal</MenuItem>
                      <MenuItem value="physical">Physical Delivery</MenuItem>
                      <MenuItem value="electronic">Electronic System</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  </Box>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <SubmissionIcon color="action" />
                      <Typography variant="subtitle2" color="text.secondary">Submission Method</Typography>
                    </Box>
                    <Typography variant="body1">
                      {bid.submissionMethod ? bid.submissionMethod.charAt(0).toUpperCase() + bid.submissionMethod.slice(1) : 'Not specified'}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Access Control Group */}
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: 'primary.main' }}>
              Access Control
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* Owner field */}
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <OwnerIcon color="action" />
                    <Typography variant="subtitle2" color="text.secondary">Owner</Typography>
                  </Box>
                  <Typography variant="body1">
                    {ownerDetails[bid.owner] ? (
                      ownerDetails[bid.owner].givenName && ownerDetails[bid.owner].surname ? 
                      `${ownerDetails[bid.owner].givenName} ${ownerDetails[bid.owner].surname}` :
                      ownerDetails[bid.owner].email
                    ) : (
                      'Loading...'
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Visibility field */}
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {bid.visibility === 'organisation' ? <Visibility color="action" /> : <VisibilityOff color="action" />}
                    <Typography variant="subtitle2" color="text.secondary">Visibility</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Chip
                      icon={bid.visibility === 'organisation' ? <Visibility /> : <VisibilityOff />}
                      label={bid.visibility === 'organisation' ? 'Organisation' : 'Invite-only'}
                      onClick={() => onVisibilityChange(bid)}
                      color={bid.visibility === 'organisation' ? 'primary' : 'default'}
                      variant="outlined"
                      sx={{ cursor: 'pointer' }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default BidDetails; 