import React, { useCallback, memo } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Today,
  ViewAgenda,
  CalendarViewMonth,
  Add as AddIcon,
  Email as EmailIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import emailService from '../../../services/emailService';
import { useSnackbar } from 'notistack';

export const CalendarToolbar = memo(({
  date,
  view,
  onNavigate,
  onView,
  onAddTask,
  onAddEvent,
  label,
  loading,
  events,
  bidName,
  organisationId,
  userEmail
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleNavigate = useCallback((action) => {
    if (onNavigate && !loading) {
      onNavigate(action);
    }
  }, [onNavigate, loading]);

  const handleViewChange = useCallback((newView) => {
    if (onView && !loading && newView !== view) {
      onView(newView);
    }
  }, [onView, loading, view]);

  const handleAddTaskClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onAddTask && !loading) {
      onAddTask();
    }
  }, [onAddTask, loading]);

  const handleAddEventClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onAddEvent && !loading) {
      onAddEvent();
    }
  }, [onAddEvent, loading]);

  const handleEmailAgenda = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) return;

    try {
      enqueueSnackbar('Sending agenda email...', { variant: 'info' });
      
      // Format events into table structure
      const tableData = events
        .sort((a, b) => new Date(a.start) - new Date(b.start))
        .map(event => {
          const date = format(new Date(event.start), 'dd MMM yyyy');
          const time = format(new Date(event.start), 'HH:mm');
          const type = event.type === 'task' ? 'Task' : 'Key Date';
          const assignedTo = event.assignedUsers?.length > 0 
            ? event.assignedUsers.map(u => u.name).join(', ')
            : event.assignedToName || 'Unassigned';
          const description = event.description || '-';
          const status = event.status || 'Not Started';
          
          return {
            date,
            time,
            type,
            title: event.title,
            assignedTo,
            description,
            status
          };
        });

      // Convert to HTML-friendly format
      const agendaText = JSON.stringify(tableData);

      await emailService.sendAgendaEmail(bidName, agendaText, organisationId, userEmail);
      enqueueSnackbar(`Agenda for "${bidName}" sent to ${userEmail}`, { 
        variant: 'success',
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      });
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to send agenda email', { 
        variant: 'error',
        autoHideDuration: 6000
      });
    }
  }, [events, bidName, organisationId, userEmail, loading, enqueueSnackbar]);

  const formattedDate = label || format(date, 'MMMM yyyy');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        position: 'sticky',
        top: 0,
        zIndex: 1
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <ButtonGroup variant="outlined" size="small">
          <Tooltip title="Previous">
            <span>
              <IconButton 
                onClick={() => handleNavigate('PREV')}
                disabled={loading}
              >
                <ChevronLeft />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Today">
            <span>
              <IconButton
                onClick={() => handleNavigate('TODAY')}
                disabled={loading}
              >
                <Today />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Next">
            <span>
              <IconButton
                onClick={() => handleNavigate('NEXT')}
                disabled={loading}
              >
                <ChevronRight />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup>

        <Typography variant="h6" component="h2">
          {formattedDate}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <ButtonGroup variant="outlined" size="small">
          <Tooltip title="Month view">
            <span>
              <IconButton
                onClick={() => handleViewChange('month')}
                color={view === 'month' ? 'primary' : 'default'}
                disabled={loading}
              >
                <CalendarViewMonth />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Agenda view">
            <span>
              <IconButton
                onClick={() => handleViewChange('agenda')}
                color={view === 'agenda' ? 'primary' : 'default'}
                disabled={loading}
              >
                <ViewAgenda />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup>

        <ButtonGroup variant="outlined" size="small">
          <Tooltip title="Add task">
            <span>
              <Button
                onClick={handleAddTaskClick}
                startIcon={<AddIcon />}
                disabled={loading}
              >
                Task
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Add event">
            <span>
              <Button
                onClick={handleAddEventClick}
                startIcon={<AddIcon />}
                disabled={loading}
              >
                Event
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Email agenda">
            <span>
              <Button
                onClick={handleEmailAgenda}
                startIcon={<EmailIcon />}
                disabled={loading || !events?.length}
              >
                Email
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
      </Box>
    </Box>
  );
});

CalendarToolbar.displayName = 'CalendarToolbar';

export default CalendarToolbar; 