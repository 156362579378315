import React, { useRef, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCalendarData } from '../calendar/hooks/useCalendarData';
import { useTaskManagement } from '../calendar/hooks/useTaskManagement';
import { useEventManagement } from '../calendar/hooks/useEventManagement';
import { useCalendarState } from '../calendar/hooks/useCalendarState';
import { useCalendarHandlers } from '../calendar/hooks/useCalendarHandlers';
import { useCalendarProps } from '../calendar/hooks/useCalendarProps';
import { TaskDialog } from '../calendar/dialogs/TaskDialog';
import { EventDialog } from '../calendar/dialogs/EventDialog';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../calendar/styles/calendar.css';
import moment from 'moment-timezone';

// Set up moment timezone
const localizer = momentLocalizer(moment);

// Helper functions for timezone handling
const convertToLocalTime = (date) => {
  if (!date) return date;
  // Convert UTC to local time
  return moment.utc(date).local();
};

const convertToUTC = (date) => {
  if (!date) return date;
  // Convert local time to UTC
  return moment(date).utc().format();
};

export const CalendarTimeline = React.memo(({ 
  bidId, 
  organisationId, 
  bidVisibility,
  userProfile
}) => {
  const mounted = useRef(true);
  const dataFetchedRef = useRef(false);
  const lastRefreshRef = useRef(Date.now());
  const { enqueueSnackbar } = useSnackbar();

  // Set mounted ref in useEffect
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // Calendar state management
  const {
    view,
    date,
    taskDialogOpen,
    eventDialogOpen,
    selectedEvent,
    setSelectedEvent,
    setTaskDialogOpen,
    setEventDialogOpen,
    handlers: calendarStateHandlers
  } = useCalendarState();

  // Data management
  const {
    events,
    loading,
    error,
    availableUsers,
    refreshData
  } = useCalendarData(
    bidId, 
    organisationId, 
    bidVisibility,
    userProfile,
    (message, severity) => {
      if (mounted.current) {
        enqueueSnackbar(message, { variant: severity });
      }
    }
  );

  // Process events to ensure proper timezone conversion
  const processedEvents = events?.map(event => ({
    ...event,
    start: convertToLocalTime(event.start),
    end: convertToLocalTime(event.end)
  })) || [];

  // Task and Event management
  const {
    handleAddTask,
    handleEditTask,
    handleDeleteTask,
    prepareTaskForEdit
  } = useTaskManagement(bidId, 
    async (message) => {
      if (mounted.current) {
        // First refresh the data
        await refreshData();
        // Then show the success message
        enqueueSnackbar(message, { variant: 'success' });
        return true;
      }
      return false;
    },
    (message) => {
      if (mounted.current) {
        enqueueSnackbar(message, { variant: 'error' });
      }
      return false;
    }
  );

  const {
    handleAddEvent,
    handleEditEvent,
    handleDeleteEvent,
    prepareEventForEdit
  } = useEventManagement(bidId,
    async (message) => {
      if (mounted.current) {
        // First refresh the data
        await refreshData();
        // Then show the success message
        enqueueSnackbar(message, { variant: 'success' });
        return true;
      }
      return false;
    },
    (message) => {
      if (mounted.current) {
        enqueueSnackbar(message, { variant: 'error' });
      }
      return false;
    }
  );

  // Event handlers
  const { handleEventClick, handleSlotSelect } = useCalendarHandlers({
    mounted,
    prepareTaskForEdit,
    prepareEventForEdit,
    availableUsers,
    setSelectedEvent,
    setTaskDialogOpen,
    setEventDialogOpen
  });

  // Calendar props with timezone support
  const calendarProps = useCalendarProps({
    events: processedEvents,
    view,
    date,
    localizer,
    handlers: {
      ...calendarStateHandlers,
      handleEventClick,
      handleSlotSelect
    },
    toolbarHandlers: {
      onAddTask: () => {
        setSelectedEvent(null);
        calendarStateHandlers.handleAddNewTask();
      },
      onAddEvent: () => {
        setSelectedEvent(null);
        calendarStateHandlers.handleAddNewEvent();
      }
    },
    bidName: bidVisibility?.name || bidVisibility?.title || 'Untitled Bid',
    organisationId,
    userEmail: userProfile?.email,
    userProfile,
    organisation: bidVisibility?.organisation || {}
  });

  // Initial data fetch
  useEffect(() => {
    if (!dataFetchedRef.current && !loading && mounted.current && refreshData) {
      dataFetchedRef.current = true;
      refreshData();
    }
    return () => {
      mounted.current = false;
      dataFetchedRef.current = false;
    };
  }, [refreshData, loading]);

  // Render loading and error states
  let content = <Calendar {...calendarProps} />;
  
  if (error) {
    content = <Box p={3}>{error}</Box>;
  } else if (loading && !events?.length) {
    content = <Box p={3}>Loading events...</Box>;
  }

  return (
    <Box sx={{ height: 'calc(100vh - 64px)' }}>
      {content}
      <TaskDialog
        open={taskDialogOpen}
        onClose={calendarStateHandlers.handleTaskDialogClose}
        task={selectedEvent}
        onSave={selectedEvent?.id ? handleEditTask : handleAddTask}
        onDelete={handleDeleteTask}
        availableUsers={availableUsers}
        loading={loading}
      />
      <EventDialog
        open={eventDialogOpen}
        onClose={calendarStateHandlers.handleEventDialogClose}
        event={selectedEvent}
        onSave={selectedEvent?.id ? handleEditEvent : handleAddEvent}
        onDelete={handleDeleteEvent}
        availableUsers={availableUsers}
        loading={loading}
      />
    </Box>
  );
});

CalendarTimeline.displayName = 'CalendarTimeline';

export default CalendarTimeline; 