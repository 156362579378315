import { useCallback } from 'react';
import moment from 'moment-timezone';
import tasksService from '../../../services/tasksService';

// Helper functions for timezone handling
const convertToLocalTime = (date) => {
  if (!date) return '';
  return moment.utc(date).local().format('YYYY-MM-DDTHH:mm');
};

const convertToUTC = (date) => {
  if (!date) return '';
  return moment(date).utc().format();
};

export const useTaskManagement = (bidId, onSuccess, onError) => {
  const handleAddTask = useCallback(async (taskData) => {
    try {
      if (!taskData.title?.trim()) {
        throw new Error('Task title is required');
      }
      if (!taskData.dueDate) {
        throw new Error('Due date is required');
      }

      const taskToSave = {
        ...taskData,
        title: taskData.title.trim(),
        dueDate: convertToUTC(taskData.dueDate),
        assignedTo: taskData.assignedTo || null,
        assignedToName: taskData.assignedToName || 'Unassigned'
      };

      await tasksService.createTask(bidId, taskToSave);
      await onSuccess('Task added successfully');
      return true;
    } catch (error) {
      onError(error.message || 'Failed to add task');
      return false;
    }
  }, [bidId, onSuccess, onError]);

  const handleEditTask = useCallback(async (taskId, taskData) => {
    try {
      if (!taskData.title?.trim()) {
        throw new Error('Task title is required');
      }
      if (!taskData.dueDate) {
        throw new Error('Due date is required');
      }

      const taskToSave = {
        ...taskData,
        title: taskData.title.trim(),
        dueDate: convertToUTC(taskData.dueDate),
        assignedTo: taskData.assignedTo || null,
        assignedToName: taskData.assignedToName || 'Unassigned'
      };

      await tasksService.updateTask(bidId, taskId, taskToSave);
      await onSuccess('Task updated successfully');
      return true;
    } catch (error) {
      onError(error.message || 'Failed to update task');
      return false;
    }
  }, [bidId, onSuccess, onError]);

  const handleDeleteTask = useCallback(async (taskId) => {
    try {
      await tasksService.deleteTask(bidId, taskId);
      onSuccess('Task deleted successfully');
    } catch (error) {
      onError(error.message || 'Failed to delete task');
    }
  }, [bidId, onSuccess, onError]);

  const prepareTaskForEdit = useCallback((task, availableUsers) => {
    // Ensure we have valid user data
    if (!Array.isArray(availableUsers)) {
      availableUsers = [];
    }

    // First try to get the assigned user ID from the task
    let assignedUserId = task.assignedTo || task.assignedUserId;
    let assignedToName = task.assignedToName || 'Unassigned';

    // If we have availableUsers, verify the assigned user exists and get their current details
    if (availableUsers.length > 0 && assignedUserId) {
      const assignedUser = availableUsers.find(u => u.id === assignedUserId);
      
      if (assignedUser) {
        // Update with current user details
        assignedToName = assignedUser.name;
      } else {
        // If user no longer exists in available users, reset assignment
        assignedUserId = null;
        assignedToName = 'Unassigned';
      }
    }

    return {
      id: task.id,
      title: task.title,
      dueDate: convertToLocalTime(task.dueDate || task.start),
      status: task.status || 'not_started',
      assignedTo: assignedUserId,
      assignedToName: assignedToName,
      description: task.description || ''
    };
  }, []);

  return {
    handleAddTask,
    handleEditTask,
    handleDeleteTask,
    prepareTaskForEdit
  };
}; 