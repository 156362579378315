import api from './api';

const apiCall = async (method, endpoint, data = null, orgId = null) => {
  try {
    const config = {
      method,
      url: `/api/accounts${endpoint}`,
      headers: {
        'Content-Type': 'application/json',
        ...(orgId && { 'x-organization-id': orgId })
      }
    };

    if (data) {
      config.data = data;
    }



    const response = await api(config);
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Invalid token');
    }
    if (error.response?.status === 404) {
      throw new Error('Account not found');
    }
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

// Create the accountService object with the old interface
export const accountService = {
  async listAccounts(organizationId, userId) {
    return apiCall('get', `?userId=${userId}&organizationId=${organizationId}`, null, organizationId);
  },

  async createLocalAccount(organizationId, accountData) {
    // Create a fresh account object with default values
    const newAccountData = {
      source: 'puawai',
      organizationId: organizationId,
      status: 'active',
      name: accountData.name || '',
      // Handle all optional fields, preserving empty strings but defaulting undefined to null
      address: accountData.address ?? null,
      phone: accountData.phone ?? null,
      email: accountData.email ?? null,
      website: accountData.website ?? null,
      industry: accountData.industry ?? null,
      description: accountData.description ?? null,
      notes: accountData.notes ?? null,
      labels: accountData.labels ?? [],
      nzbn: accountData.nzbn ?? null,
      linkedinUrl: accountData.linkedinUrl ?? null,
      lastSync: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      userId: accountData.userId
    };

    // Log the data being sent for debugging

    return apiCall('post', '', newAccountData, organizationId);
  },

  async updateLocalAccount(organizationId, accountId, accountData) {
    if (!organizationId || !accountId) {
      throw new Error('Organization ID and Account ID are required');
    }
    
    // Log incoming data for debugging
    
    // Create the update object with all fields from accountData
    const updateData = {
      id: accountId,
      source: 'puawai',
      organizationId: organizationId,
      status: accountData.status || 'active',
      name: accountData.name || '',
      // Handle all optional fields, preserving empty strings but defaulting undefined to null
      website: accountData.website ?? null,
      industry: accountData.industry ?? null,
      address: accountData.address ?? null,
      phone: accountData.phone ?? null,
      email: accountData.email ?? null,
      description: accountData.description ?? null,
      notes: accountData.notes ?? null,
      labels: accountData.labels ?? [],
      nzbn: accountData.nzbn ?? null,
      linkedinUrl: accountData.linkedinUrl ?? null,
      updatedAt: new Date().toISOString(),
      userId: accountData.userId
    };

    // Log the update data for debugging

    return apiCall('put', `/${accountId}`, updateData, organizationId);
  },

  async deleteLocalAccount(organizationId, accountId) {
    if (!organizationId || !accountId) {
      throw new Error('Organization ID and Account ID are required');
    }
    // Use consistent URL format with other endpoints
    return apiCall('delete', `/${accountId}?id=${accountId}`, null, organizationId);
  },

  async getAccount(organizationId, accountId) {
    if (!organizationId || !accountId) {
      throw new Error('Organization ID and Account ID are required');
    }
    return apiCall('get', `/${accountId}?organizationId=${organizationId}`, null, organizationId);
  }
};

// Export both named and default export for backward compatibility
export default accountService; 