import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Tooltip,
  Collapse,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  Button,
  Menu,
  MenuItem,
  Snackbar
} from '@mui/material';
import debounce from 'lodash/debounce';
import {
  Dashboard as DashboardIcon,
  People as ContactsIcon,
  Description as ProposalsIcon,
  BarChart as AnalyticsIcon,
  Cloud as IntegrationsIcon,
  Settings as SettingsIcon,
  ExpandLess,
  ExpandMore,
  Search as SearchIcon,
  Business as BusinessIcon,
  Person as PersonIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Timeline as TimelineIcon,
  AttachMoney,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Event as EventIcon
} from '@mui/icons-material';
import { accountService } from '../../services/accountService';
import CreateAccountDialog from './Dialogs/CreateAccountDialog';
import EditAccountDialog from './Dialogs/EditAccountDialog';
import DeleteAccountDialog from './Dialogs/DeleteAccountDialog';
import { logError } from '../../utils/errorLogging';
import { useDataFetcher } from '../../hooks/useDataFetcher';
import { getActiveAccount } from '../../services/authService';
import { getUser } from '../../services/userService';

// Define account features
const accountFeatures = [
  { path: 'dashboard', label: 'Dashboard', icon: <DashboardIcon /> },
  { path: 'contacts', label: 'Contacts', icon: <ContactsIcon /> },
  { path: 'deals', label: 'Deals', icon: <AttachMoney /> },
  { path: 'proposals', label: 'Proposals', icon: <ProposalsIcon /> },
  { path: 'analytics', label: 'Analytics', icon: <AnalyticsIcon /> },
  { path: 'settings', label: 'Settings', icon: <SettingsIcon /> }
];

// Simplified navigation structure
const navigationItems = [
  { 
    type: 'overview',
    items: [
      { path: 'dashboard', label: 'Customer Relationship Dashboard', icon: <DashboardIcon /> },
      { path: 'contacts', label: 'Contacts', icon: <ContactsIcon /> },
      { path: 'deals', label: 'Deals', icon: <AttachMoney /> },
      { path: 'activities', label: 'Activities', icon: <EventIcon /> },
      { path: 'integrations', label: 'Integrations', icon: <IntegrationsIcon /> }
    ]
  },
  {
    type: 'accounts',
    label: 'Accounts',
    searchable: true
  }
];

const RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000;

const AccountsHome = ({ userData, selectedOrg }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [navCollapsed, setNavCollapsed] = useState(false);
  const [accountsExpanded, setAccountsExpanded] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [labels, setLabels] = useState([]);
  const [accountFormData, setAccountFormData] = useState({
    name: '',
    industry: '',
    website: '',
    address: '',
    phone: '',
    email: '',
    description: '',
    notes: '',
    labels: [],
    nzbn: '',
    linkedinUrl: ''
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedAccountForAction, setSelectedAccountForAction] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [operationLoading, setOperationLoading] = useState({
    create: false,
    update: false,
    delete: false
  });

  // Debounced search
  const debouncedSearch = useCallback(
    debounce((term) => {
      setSearchTerm(term);
    }, 300),
    []
  );

  // Memoized filtered accounts
  const filteredAccounts = useMemo(() => 
    accounts.filter(account =>
      account?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [accounts, searchTerm]
  );

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  // Update the isValidUserData function to check for Cosmos user data
  const isValidUserData = (data) => {
    console.log('Validating user data:', {
      data,
      hasData: !!data,
      keys: data ? Object.keys(data) : [],
      hasId: data?.id,
      hasEmail: data?.email
    });

    if (!data) return false;
    return !!data.id; // Cosmos users should have an ID
  };

  // Add useDataFetcher hook for user data
  const { data: cosmosUserData, isLoading: userLoading } = useDataFetcher(async () => {
    const account = await getActiveAccount();
    if (!account) return null;
    try {
      const user = await getUser(account.localAccountId);
      return user;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  }, []);

  // Update useEffect to use Cosmos user data and fetch accounts
  useEffect(() => {
    if (cosmosUserData && selectedOrg) {
      console.log('Cosmos user data received:', cosmosUserData);
      fetchAccounts();
    }
  }, [cosmosUserData, selectedOrg]);

  // Update fetchAccounts to use Cosmos user ID
  const fetchAccounts = async (retry = false) => {
    if (!selectedOrg) {
      setError('No organization selected');
      return;
    }

    if (!cosmosUserData?.id) {
      console.log('Waiting for Cosmos user data...');
      setError('Waiting for user data...');
      return;
    }

    try {
      setLoading(true);
      const response = await accountService.listAccounts(selectedOrg, cosmosUserData.id);
      // Filter accounts by organization ID as well
      const filteredResponse = {
        ...response,
        accounts: (response.accounts || []).filter(acc => acc.organizationId === selectedOrg)
      };
      setAccounts(filteredResponse.accounts);
      setError(null);
      setRetryCount(0);
    } catch (err) {
      logError('Error fetching accounts:', err);
      setError('Failed to load accounts');
      
      if (retry && retryCount < RETRY_ATTEMPTS) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          fetchAccounts(true);
        }, RETRY_DELAY * (retryCount + 1));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAccount = async (account) => {
    try {
      // Optimistic update
      setSelectedAccount(account);
      navigate(`/accounts/account/${account.id}`, {
        state: { accountId: account.id }
      });

      // Background fetch for details
      const accountDetails = await accountService.getAccount(selectedOrg, account.id);
      setSelectedAccount(prev => ({ ...prev, ...accountDetails }));
    } catch (err) {
      logError('Error in account selection:', err);
      showSnackbar('Could not load complete account details', 'warning');
    }
  };

  const handleNavigation = (path) => {
    // Clear selected account when navigating to any main view
    setSelectedAccount(null);
    
    // Navigate to the path
    navigate(`/accounts/${path}`, {
      replace: true, // Use replace to prevent building up history
      state: { clearAccount: true } // Add state to indicate we're clearing the account
    });
  };

  const resetAccountFormData = () => {
    setAccountFormData({
      name: '',
      industry: '',
      website: '',
      address: '',
      phone: '',
      email: '',
      description: '',
      notes: '',
      labels: [],
      nzbn: '',
      linkedinUrl: ''
    });
  };

  const handleCreateDialogOpen = () => {
    resetAccountFormData();
    setCreateDialogOpen(true);
  };

  // Add function to handle label updates
  const handleLabelsUpdate = async (newLabels) => {
    // Update local state with new labels
    setLabels(prevLabels => {
      const uniqueLabels = new Set([...prevLabels, ...newLabels]);
      return Array.from(uniqueLabels);
    });
  };

  // Update handleCreateAccount
  const handleCreateAccount = async () => {
    let tempId = null;
    
    try {
      setOperationLoading(prev => ({ ...prev, create: true }));
      
      // Validate required fields
      if (!accountFormData.name) {
        showSnackbar('Account name is required', 'error');
        return;
      }

      if (!cosmosUserData?.id) {
        throw new Error('Please wait for user data to load completely');
      }

      if (!selectedOrg) {
        throw new Error('Organization ID is required');
      }
      
      // Create a properly structured account object
      tempId = Date.now().toString();
      const newAccount = {
        id: tempId,
        name: accountFormData.name,
        industry: accountFormData.industry || '',
        website: accountFormData.website || '',
        address: accountFormData.address || '',
        phone: accountFormData.phone || '',
        email: accountFormData.email || '',
        description: accountFormData.description || '',
        notes: accountFormData.notes || '',
        labels: accountFormData.labels || [],
        nzbn: accountFormData.nzbn || '',
        linkedinUrl: accountFormData.linkedinUrl || '',
        source: 'puawai',
        userId: cosmosUserData.id,
        organizationId: selectedOrg
      };

      // Update labels list with any new labels
      if (accountFormData.labels?.length) {
        handleLabelsUpdate(accountFormData.labels);
      }
      
      // Log the account data being sent
      console.debug('Creating account with data:', newAccount);
      
      // Optimistic update with validated object
      setAccounts(prev => [...prev, newAccount]);
      
      // Actual API call with userId and organizationId
      const response = await accountService.createLocalAccount(selectedOrg, newAccount);
      
      if (!response || !response.id) {
        throw new Error('Invalid account data received from server');
      }
      
      // Update with real data from the server
      setAccounts(prev => prev.map(acc => 
        acc.id === tempId ? response : acc
      ));
      
      setCreateDialogOpen(false);
      resetAccountFormData();
      showSnackbar('Account created successfully', 'success');
    } catch (error) {
      logError('Error creating account:', error);
      if (tempId) {
        setAccounts(prev => prev.filter(acc => acc.id !== tempId));
      }
      showSnackbar(error.message || 'Failed to create account', 'error');
    } finally {
      setOperationLoading(prev => ({ ...prev, create: false }));
    }
  };

  const handleUpdateAccount = async () => {
    let previousAccount = null;
    
    if (!selectedAccountForAction?.id) {
      showSnackbar('No account selected for update', 'error');
      return;
    }

    if (!cosmosUserData?.id) {
      showSnackbar('User ID is required', 'error');
      return;
    }

    if (!selectedOrg) {
      showSnackbar('Organization ID is required', 'error');
      return;
    }

    try {
      setOperationLoading(prev => ({ ...prev, update: true }));
      
      // Validate required fields
      if (!accountFormData.name) {
        showSnackbar('Account name is required', 'error');
        return;
      }
      
      // Store previous state for rollback
      previousAccount = accounts.find(acc => acc.id === selectedAccountForAction.id);
      
      if (!previousAccount) {
        throw new Error('Account not found');
      }
      
      // Create updated account object matching the exact structure expected by the API
      const updateData = {
        ...previousAccount, // First spread the previous account to preserve all fields
        id: selectedAccountForAction.id,
        name: accountFormData.name.trim(),
        industry: accountFormData.industry || '',
        website: accountFormData.website || '',
        address: accountFormData.address || '',
        phone: accountFormData.phone || '',
        email: accountFormData.email || '',
        description: accountFormData.description || '',
        notes: accountFormData.notes || '',
        labels: accountFormData.labels || [],
        nzbn: accountFormData.nzbn || '',
        linkedinUrl: accountFormData.linkedinUrl || '',
        source: 'puawai',
        userId: cosmosUserData.id,
        organizationId: selectedOrg,
        updatedAt: new Date().toISOString()
      };
      
      // Optimistic update
      setAccounts(prev => prev.map(acc =>
        acc.id === selectedAccountForAction.id ? updateData : acc
      ));
      
      // Actual API call with complete data
      const response = await accountService.updateLocalAccount(
        selectedOrg, 
        selectedAccountForAction.id, 
        updateData
      );
      
      if (!response || !response.id) {
        throw new Error('Invalid response from server');
      }
      
      // Update with the server response data
      setAccounts(prev => prev.map(acc =>
        acc.id === selectedAccountForAction.id ? response : acc
      ));
      
      setEditDialogOpen(false);
      resetAccountFormData();
      setSelectedAccountForAction(null);
      showSnackbar('Account updated successfully', 'success');
    } catch (error) {
      logError('Error updating account:', error);
      if (previousAccount) {
        setAccounts(prev => prev.map(acc =>
          acc.id === selectedAccountForAction?.id ? previousAccount : acc
        ));
      }
      showSnackbar(
        error.message?.includes('400') 
          ? 'Invalid account data. Please check your input and try again.'
          : error.message || 'Failed to update account', 
        'error'
      );
    } finally {
      setOperationLoading(prev => ({ ...prev, update: false }));
    }
  };

  const handleDeleteAccount = async () => {
    if (!selectedAccountForAction?.id) {
      showSnackbar('No account selected for deletion', 'error');
      return;
    }

    // Declare accountToDelete outside try block so it's accessible in catch
    const accountToDelete = accounts.find(acc => acc.id === selectedAccountForAction.id);
    if (!accountToDelete) {
      showSnackbar('Account not found', 'error');
      return;
    }

    try {
      setOperationLoading(prev => ({ ...prev, delete: true }));

      // Optimistic update - remove from UI
      setAccounts(prev => prev.filter(acc => acc.id !== selectedAccountForAction.id));

      // Actual API call
      await accountService.deleteLocalAccount(selectedOrg, selectedAccountForAction.id);

      setDeleteDialogOpen(false);
      setSelectedAccountForAction(null);
      showSnackbar('Account deleted successfully', 'success');
    } catch (error) {
      logError('Error deleting account:', error);
      // Rollback on error - restore the account
      setAccounts(prev => [...prev, accountToDelete]);
      showSnackbar(error.message || 'Failed to delete account', 'error');
    } finally {
      setOperationLoading(prev => ({ ...prev, delete: false }));
    }
  };

  const handleAccountMenuClick = (event, account) => {
    event.stopPropagation();
    setSelectedAccountForAction(account);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Update handleEditClick to include new fields
  const handleEditClick = () => {
    setAccountFormData({
      name: selectedAccountForAction.name || '',
      industry: selectedAccountForAction.industry || '',
      website: selectedAccountForAction.website || '',
      address: selectedAccountForAction.address || '',
      phone: selectedAccountForAction.phone || '',
      email: selectedAccountForAction.email || '',
      description: selectedAccountForAction.description || '',
      notes: selectedAccountForAction.notes || '',
      labels: selectedAccountForAction.labels || [],
      nzbn: selectedAccountForAction.nzbn || '',
      linkedinUrl: selectedAccountForAction.linkedinUrl || ''
    });
    setEditDialogOpen(true);
    handleAccountMenuClose();
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    handleAccountMenuClose();
  };

  // Form handlers
  const handleFormChange = (newData) => {
    setAccountFormData(newData);
  };

  // Update the loading check to show more information
  if (userLoading || !cosmosUserData) {
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: 2,
        height: 'calc(100vh - 64px)', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        <CircularProgress />
        <Typography color="textSecondary">
          Loading user data...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
      {/* Left Navigation */}
      <Paper
        elevation={3}
        sx={{
          width: navCollapsed ? 72 : 280,
          minWidth: navCollapsed ? 72 : 280,
          transition: theme => theme.transitions.create('width'),
          overflowX: 'hidden',
          borderRadius: 0,
          borderRight: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {/* Navigation Header */}
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          {!navCollapsed && <Typography variant="h6">CRM</Typography>}
          <IconButton onClick={() => setNavCollapsed(!navCollapsed)}>
            {navCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>

        {/* Main Navigation Items */}
        <List>
          {navigationItems[0].items.map((item) => (
            <Tooltip 
              key={item.path}
              title={navCollapsed ? item.label : ""}
              placement="right"
              arrow
            >
              <ListItem
                button={true}
                selected={location.pathname === `/accounts/${item.path}`}
                onClick={() => handleNavigation(item.path)}
                sx={{
                  py: 1.5,
                  px: 2,
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '& .MuiListItemIcon-root': { color: 'white' },
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: navCollapsed ? 'auto' : 40 }}>
                  {item.icon}
                </ListItemIcon>
                {!navCollapsed && <ListItemText primary={item.label} />}
              </ListItem>
            </Tooltip>
          ))}
        </List>

        {/* Accounts Section */}
        {!navCollapsed && (
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column',
            borderTop: 1,
            borderColor: 'divider',
            mt: 2
          }}>
            {/* Accounts Header with Create Button */}
            <Box sx={{ 
              p: 2, 
              bgcolor: 'grey.100',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Typography variant="subtitle1" color="text.secondary">
                Accounts
              </Typography>
              <IconButton
                color="primary"
                onClick={handleCreateDialogOpen}
                size="small"
                title="Create New Account"
              >
                <AddIcon />
              </IconButton>
            </Box>

            {/* Search Box */}
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                size="small"
                placeholder="Search accounts..."
                value={searchTerm}
                onChange={(e) => debouncedSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Accounts List */}
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <CircularProgress />
                </Box>
              ) : error ? (
                <Box sx={{ p: 2 }}>
                  <Alert 
                    severity="error" 
                    action={
                      <Button color="inherit" size="small" onClick={() => fetchAccounts(true)}>
                        Retry
                      </Button>
                    }
                  >
                    {error}
                  </Alert>
                </Box>
              ) : (
                <List>
                  {filteredAccounts.map((account) => (
                    <ListItem
                      button={true}
                      key={account.id}
                      selected={selectedAccount?.id === account.id}
                      onClick={() => handleSelectAccount(account)}
                      sx={{
                        py: 1,
                        '&.Mui-selected': {
                          bgcolor: 'primary.main',
                          color: 'white',
                          '& .MuiListItemIcon-root': { color: 'white' },
                        },
                      }}
                    >
                      <ListItemIcon>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary={account.name}
                        secondary={account.source}
                      />
                      {account.source === 'puawai' && (
                        <IconButton
                          size="small"
                          onClick={(e) => handleAccountMenuClick(e, account)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Box>
        )}
      </Paper>

      {/* Account Actions Menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleAccountMenuClose}
      >
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {/* Dialogs */}
      <CreateAccountDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreate={handleCreateAccount}
        formData={accountFormData}
        onFormChange={handleFormChange}
        isLoading={operationLoading.create}
      />

      <EditAccountDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleUpdateAccount}
        formData={accountFormData}
        onFormChange={handleFormChange}
        isLoading={operationLoading.update}
      />

      <DeleteAccountDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setSelectedAccountForAction(null);
        }}
        onDelete={handleDeleteAccount}
        accountName={selectedAccountForAction?.name || ''}
        isLoading={operationLoading.delete}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Main Content Area */}
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Outlet context={{ selectedAccount, selectedOrg, refreshAccounts: fetchAccounts }} />
      </Box>
    </Box>
  );
};

export default AccountsHome;
