import React from 'react';
import {
  Flag as FlagIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Error as ErrorIcon,
  Event as EventIcon
} from '@mui/icons-material';

export const getStatusIcon = (event) => {
  if (event.type === 'milestone') {
    return <FlagIcon fontSize="small" />;
  }

  if (event.type === 'non-task') {
    return <EventIcon fontSize="small" />;
  }

  switch (event.status) {
    case 'completed':
      return <CheckCircleIcon fontSize="small" />;
    case 'in_progress':
      return <ScheduleIcon fontSize="small" />;
    case 'overdue':
      return <ErrorIcon fontSize="small" color="error" />;
    default:
      return <ScheduleIcon fontSize="small" />;
  }
}; 