import { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import tasksService from '../../../services/tasksService';
import bidService from '../../../services/bidService';
import bidteamService from '../../../services/bidteamService';
import { getUser } from '../../../services/userService';
import { getOrganisationUsers } from '../../../services/organisationService';

const CACHE_TIME = 2000; // 2 seconds
const FORCE_REFRESH_TIME = 0; // No cache when force refresh

export const useCalendarData = (
  bidId, 
  organisationId, 
  bidVisibility,
  userProfile,
  onError
) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [bidDetails, setBidDetails] = useState(null);
  
  const lastFetchRef = useRef(0);
  const cacheRef = useRef({
    users: null,
    tasks: null,
    bidDetails: null,
    entries: null,
    lastFetch: 0
  });

  const handleError = useCallback((error, message) => {
    const errorMessage = error.message || message;
    setError(errorMessage);
    if (onError) {
      onError(errorMessage, 'error');
    }
  }, [onError]);

  const fetchAvailableUsers = useCallback(async (force = false) => {
    try {
      if (!bidId || !organisationId) {
        throw new Error('Missing required bid or organisation ID');
      }

      const now = Date.now();
      if (!force && cacheRef.current.users && (now - cacheRef.current.lastFetch) < CACHE_TIME) {
        return cacheRef.current.users;
      }

      const users = bidVisibility === 'organisation'
        ? await getOrganisationUsers(organisationId)
        : await bidteamService.getTeamMembers(bidId);

      if (!users || !Array.isArray(users)) {
        throw new Error('Invalid user data received');
      }

      const formattedUsers = users
        .map(user => {
          const userId = user.id || user.userId || user._id || user.objectId || user.email;
          if (!userId) return null;

          return {
            id: userId,
            name: `${user.givenName || ''} ${user.surname || ''}`.trim() || user.name || 'Unknown User',
            email: user.email,
            isCurrentUser: userProfile ? userId === userProfile.id : false
          };
        })
        .filter(Boolean);

      if (userProfile && !formattedUsers.some(u => u.id === userProfile.id)) {
        formattedUsers.push({
          id: userProfile.id,
          name: `${userProfile.givenName || ''} ${userProfile.surname || ''}`.trim(),
          email: userProfile.email,
          isCurrentUser: true
        });
      }

      cacheRef.current.users = formattedUsers;
      cacheRef.current.lastFetch = now;
      setAvailableUsers(formattedUsers);
      return formattedUsers;
    } catch (error) {
      handleError(error, 'Failed to fetch team members');
      return [];
    }
  }, [bidId, organisationId, bidVisibility, userProfile, handleError]);

  const fetchData = useCallback(async (force = false) => {
    try {
      const now = Date.now();
      const cacheTimeout = force ? FORCE_REFRESH_TIME : CACHE_TIME;
      
      if (!force && (now - lastFetchRef.current) < cacheTimeout) {
        return;
      }

      setLoading(true);
      setError(null);

      if (!bidId || !organisationId) {
        throw new Error('Missing required bid or organisation ID');
      }

      if (!force && cacheRef.current.tasks && cacheRef.current.bidDetails && cacheRef.current.entries &&
          (now - cacheRef.current.lastFetch) < cacheTimeout) {
        return;
      }

      const [tasks, bidDetailsResponse, entries] = await Promise.all([
        tasksService.getBidTasks(bidId),
        bidService.getBidDetails(bidId, organisationId),
        bidService.getNonTaskEntries(bidId)
      ]);

        cacheRef.current.tasks = tasks;
        cacheRef.current.bidDetails = bidDetailsResponse;
        cacheRef.current.entries = entries;
        cacheRef.current.lastFetch = now;
        lastFetchRef.current = now;

      setBidDetails(bidDetailsResponse);

      const milestoneEvents = [
        bidDetailsResponse.startDate && {
          title: 'Bid Start',
          start: new Date(bidDetailsResponse.startDate),
          end: new Date(bidDetailsResponse.startDate),
          allDay: false,
          type: 'milestone',
          description: 'Beginning of the bid process',
          createdBy: bidDetailsResponse.owner
        },
        bidDetailsResponse.submissionDate && {
          title: 'Submission Deadline',
          start: new Date(bidDetailsResponse.submissionDate),
          end: new Date(bidDetailsResponse.submissionDate),
          allDay: false,
          type: 'milestone',
          description: 'Final date for bid submission',
          createdBy: bidDetailsResponse.owner
        }
      ].filter(Boolean);

      const taskEvents = await Promise.all(tasks.map(async task => {
        let assignedToName = 'Unassigned';
        if (task.assignedTo) {
          try {
            const userDetails = await getUser(task.assignedTo);
            assignedToName = `${userDetails.givenName} ${userDetails.surname}`.trim();
          } catch (error) {
            assignedToName = 'Unknown User';
          }
        }

        // Create a date object for the task's due date
        const dueDate = new Date(task.dueDate);
        
        // Set a default due time of 5 PM if no specific time is set
        if (dueDate.getHours() === 0 && dueDate.getMinutes() === 0) {
          dueDate.setHours(17, 0, 0, 0);
        }

        return {
          id: task.id,
          title: task.title,
          start: dueDate,
          end: dueDate, // Use same time for start and end to show only due time
          allDay: false,
          type: 'task',
          status: task.status || 'not_started',
          description: task.description || '',
          assignedTo: task.assignedTo,
          assignedToName,
          isCurrentUserTask: task.assignedTo === userProfile?.id,
          createdBy: task.createdBy,
          assignedUsers: [{
            id: task.assignedTo,
            name: assignedToName
          }]
        };
      }));

      const nonTaskEvents = entries.map(entry => ({
        id: entry.id,
        title: entry.title,
        start: new Date(entry.start),
        end: new Date(entry.end),
        allDay: entry.allDay || false,
        type: 'non-task',
        description: entry.description || '',
        assignedUsers: entry.assignedUsers || [],
        isCurrentUserEvent: entry.assignedUsers?.some(u => u.id === userProfile?.id),
        createdBy: entry.createdBy
      }));

      const allEvents = [...milestoneEvents, ...taskEvents, ...nonTaskEvents];
      setEvents(allEvents);
    } catch (error) {
      handleError(error, 'Failed to fetch calendar data');
    } finally {
      setLoading(false);
    }
  }, [bidId, organisationId, userProfile, handleError]);

  const refreshData = useCallback(async () => {
    await Promise.all([
      fetchData(true),
      fetchAvailableUsers(true)
    ]);
  }, [fetchData, fetchAvailableUsers]);

  useEffect(() => {
    fetchData();
    fetchAvailableUsers();
  }, [fetchData, fetchAvailableUsers]);

  return {
    events,
    loading,
    error,
    availableUsers,
    bidDetails,
    refreshData
  };
}; 