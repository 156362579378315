import api from './api';
import { getAuthHeader } from './authService';
import { getUser } from './userService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api/bids${endpoint}`,
      headers: { Authorization: token },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      throw new Error('Access denied. You do not have permission to perform this action.');
    }
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

export const listBids = async (selectedOrg) => {
  try {
    const token = await getAuthHeader();
    if (!selectedOrg) {
      throw new Error('selectedOrg is required');
    }
    const response = await api.get(`/api/bids`, {
      headers: { Authorization: token },
      params: { organisationId: selectedOrg }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBid = async (bidData) => {
  try {
    const token = await getAuthHeader();
    const response = await api.post('/api/bids', bidData, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBid = async (bidId, updatedFields) => {
  try {
    const token = await getAuthHeader();
    const response = await api.put(`/api/bids/${bidId}`, updatedFields, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const archiveBid = async (bidId) => {
  return updateBid(bidId, { status: 'archived', previousStatus: 'active' });
};

export const unarchiveBid = async (bidId) => {
  return updateBid(bidId, { status: 'active' });
};

export const deleteBid = async (bidId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.delete(`/api/bids/${bidId}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveBidStrategyCanvas = async (bidId, canvasData) => {
  return apiCall('post', `/${bidId}/strategy-canvas`, canvasData);
};

export const saveRFPQualificationCanvas = async (bidId, canvasData) => {
  return apiCall('post', `/${bidId}/rfp-qualification-canvas`, canvasData);
};

export const getBidStrategyCanvas = async (bidId) => {
  try {
    return await apiCall('get', `/${bidId}/strategy-canvas`);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const getRFPQualificationCanvas = async (bidId) => {
  try {
    return await apiCall('get', `/${bidId}/rfp-qualification-canvas`);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const getBidTasks = async (bidId) => {
  return apiCall('get', `/${bidId}/tasks`);
};

export const getBidDetails = async (bidId, organisationId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.get(`/api/bids/${bidId}`, {
      headers: { Authorization: token },
      params: { organisationId }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listBidTasks = async (bidId) => {
  try {
    const response = await api.get(`/bids/${bidId}/tasks`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNonTaskEntries = async (bidId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.get(`/api/bids/${bidId}/entries`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createNonTaskEntry = async (bidId, entryData) => {
  try {
    console.debug('[bidService] createNonTaskEntry - Starting with:', {
      bidId,
      entryData
    });

    const token = await getAuthHeader();
    const dataToSend = {
      ...entryData,
      assignedUsers: entryData.assignedUsers || []  // Ensure assignedUsers is always an array
    };

    console.debug('[bidService] createNonTaskEntry - Sending data:', dataToSend);

    const response = await api.post(`/api/bids/${bidId}/entries`, dataToSend, {
      headers: { Authorization: token }
    });

    console.debug('[bidService] createNonTaskEntry - API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('[bidService] createNonTaskEntry - Error:', error);
    throw error;
  }
};

export const deleteNonTaskEntry = async (bidId, entryId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.delete(`/api/bids/${bidId}/entries/${entryId}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBidTeamMembers = async (bidId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.get(`/api/bids/${bidId}/team`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTeamMembers = async (bidId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.get(`/api/bids/${bidId}/team`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNonTaskEntry = async (bidId, entryId, entryData) => {
  try {
    console.debug('[bidService] updateNonTaskEntry - Starting with:', {
      bidId,
      entryId,
      entryData,
      assignedUsers: entryData.assignedUsers
    });

    const token = await getAuthHeader();
    const dataToSend = {
      ...entryData,
      assignedUsers: Array.isArray(entryData.assignedUsers) ? entryData.assignedUsers : []
    };

    console.debug('[bidService] updateNonTaskEntry - Sending data:', {
      ...dataToSend,
      assignedUsers: dataToSend.assignedUsers
    });

    const response = await api.put(`/api/bids/${bidId}/entries/${entryId}`, dataToSend, {
      headers: { Authorization: token }
    });

    console.debug('[bidService] updateNonTaskEntry - API response:', {
      ...response.data,
      assignedUsers: response.data.assignedUsers
    });
    return response.data;
  } catch (error) {
    console.error('[bidService] updateNonTaskEntry - Error:', error);
    throw error;
  }
};

// Add this new function to execute bid actions
export const executeBidAction = async (bidId, action) => {
  try {
    const token = await getAuthHeader();
    const response = await api.post(`/api/bids/${bidId}`, { action }, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  listBids,
  createBid,
  updateBid,
  deleteBid,
  archiveBid,
  unarchiveBid,
  saveBidStrategyCanvas,
  saveRFPQualificationCanvas,
  getBidStrategyCanvas,
  getRFPQualificationCanvas,
  getBidTasks,
  getBidDetails,
  listBidTasks,
  getNonTaskEntries,
  createNonTaskEntry,
  deleteNonTaskEntry,
  getBidTeamMembers,
  getTeamMembers,
  updateNonTaskEntry,
  executeBidAction,
};
