import api from './api'; // Importing the Axios instance
import { getAuthHeader } from './authService';
import { getUser, getOrganisationsForUser } from './userService';
import { addUserToOrganisation } from './organisationService';

// Function to get pending invitations
export const getPendingInvitations = async (orgId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.get(`/api/invitations?status=pending&orgId=${orgId}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching pending invitations:', error);
    throw error;
  }
};

// Function to generate an invite code
export const createInvitation = async (orgId, email) => {
  try {
    const token = await getAuthHeader();
    console.log('Creating invitation with:', { orgId, email });
    
    if (!orgId) {
      throw new Error('Organisation ID is required for creating an invitation');
    }

    // First create the invitation
    console.log('Sending invitation creation request:', { orgId, email });
    const response = await api.post(`/api/invitations?orgId=${orgId}`, { email }, {
      headers: { Authorization: token }
    });
    console.log('Invitation created:', response.data);

    // Then trigger the email notification
    console.log('Sending email notification:', {
      invitationId: response.data.id,
      email,
      organisationId: orgId
    });
    
    await api.post('/api/email', {
      type: 'invitation',
      data: {
        invitationId: response.data.id,
        email,
        organisationId: orgId,
        inviteCode: response.data.id
      }
    }, {
      headers: { Authorization: token }
    });

    return response.data;
  } catch (error) {
    console.error('Error creating invitation:', error.response || error);
    throw error;
  }
};

// Function to get invitation details
export const getInvitationDetails = async (invitationCode) => {
  try {
    const token = await getAuthHeader();
    ////console.log(`Fetching invitation details for code: ${invitationCode}`);
    const response = await api.get(`/api/invitations/${invitationCode}`, {
      headers: { Authorization: token }
    });
    ////console.log('Invitation details response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching invitation details:', error.response || error);
    if (error.response) {
      switch (error.response.status) {
        case 404:
          throw new Error('Invitation not found.');
        case 410:
          throw new Error('Invitation has expired.');
        case 400:
          throw new Error('Invitation has already been accepted.');
        default:
          throw new Error(`An error occurred while fetching invitation details: ${error.response.data.message}`);
      }
    }
    throw new Error('An unexpected error occurred while fetching invitation details.');
  }
};

// Function to accept an invitation
export const acceptInvitation = async (invitationCode) => {
  try {
    const token = await getAuthHeader();
    const response = await api.put(`/api/invitations/${invitationCode}`, 
      { action: 'accept' },
      { headers: { Authorization: token } }
    );
    ////console.log('Invitation accepted successfully:', response.data);
    
    // Update local user data
    await updateUserData();
    
    return response.data;
  } catch (error) {
    console.error('Error accepting invitation:', error);
    if (error.response) {
      switch (error.response.status) {
        case 404:
          throw new Error('Invitation not found.');
        case 410:
          throw new Error('Invitation has expired.');
        case 400:
          throw new Error('Invitation has already been accepted or is invalid.');
        default:
          throw new Error(`An error occurred while accepting the invitation: ${error.response.data.message}`);
      }
    }
    throw new Error('An unexpected error occurred while accepting the invitation.');
  }
};

async function updateUserData() {
  // Fetch updated user data and update local storage or state
  const updatedUser = await getUser(); // Implement this function in userService.js
  // Update local storage or state management with the new user data
  // For example, if you're using localStorage:
  localStorage.setItem('userData', JSON.stringify(updatedUser));
}

// Function to list all invitations
export const listInvitations = async (orgId) => {
  try {
    const token = await getAuthHeader();
    ////console.log(`Fetching invitations for orgId: ${orgId}`); // Add this log
    const response = await api.get(`/api/invitations`, {
      params: { orgId },
      headers: { Authorization: token }
    });
    ////console.log('Invitations response:', response.data); // Add this log
    return response.data;
  } catch (error) {
    console.error('Error listing invitations:', error.response || error);
    throw error;
  }
};

// Function to revoke an invitation
export const revokeInvitation = async (orgId, invitationId) => {
  try {
    const token = await getAuthHeader();
    //console.log('Revoking invitation:', { orgId, invitationId }); // Update this log
    const response = await api.delete(`/api/invitations/${invitationId}`, {
      headers: { Authorization: token },
      params: { orgId } // Add orgId as a query parameter
    });
    //console.log('Invitation revoked:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error revoking invitation:', error.response || error);
    throw error;
  }
};

export default {
  getPendingInvitations,
  createInvitation,
  getInvitationDetails,
  acceptInvitation,
  listInvitations,
  revokeInvitation
};

