import { useMemo } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { EventComponent } from '../events/EventComponent';
import { CalendarToolbar } from '../toolbar/CalendarToolbar';
import { getStatusIcon } from '../utils/statusIcons';
import React from 'react';

const localizer = momentLocalizer(moment);

// Memoize static values
const defaultStyle = { height: '100%' };
const getNow = () => new Date();
const emptyArray = [];

// Memoize the EventComponent wrapper
const EventComponentWrapper = React.memo((props) => (
  <EventComponent {...props} getStatusIcon={getStatusIcon} />
));

EventComponentWrapper.displayName = 'EventComponentWrapper';

// Base calendar props
const baseCalendarProps = {
  localizer,
  startAccessor: "start",
  endAccessor: "end",
  style: defaultStyle,
  longPressThreshold: 250,
  dayPropGetter: undefined,
  slotPropGetter: undefined,
  getNow,
  min: undefined,
  max: undefined,
  scrollToTime: undefined,
  showMultiDayTimes: true,
  step: 60,
  timeslots: 1,
  toolbar: true,
  selectable: true,
  popup: true,
  views: ['month', 'week', 'day', 'agenda'],
  defaultView: 'agenda',
  allDayAccessor: 'allDay',
  length: 1000, // Increased to show more items in agenda view
  messages: {
    agenda: 'All Events & Tasks'  // Custom header for agenda view
  },
  formats: {
    eventTimeRangeFormat: ({ start, end }, culture, local) => {
      return moment(start).format('HH:mm');  // Show only start time
    },
    agendaTimeRangeFormat: (range, culture, local) => {
      const { start, type } = range;
      return moment(start).format('HH:mm'); // Show only start time for all events
    },
    agendaTimeFormat: (date, culture, local) => {
      return moment(date).format('HH:mm');
    },
    eventTimeFormat: (date, culture, local) => {
      return ''; // Don't show time in the event card since it's already in the time column
    },
    agendaDateFormat: (date, culture, local) => {
      return moment(date).format('ddd D MMM'); // Format: Mon 15 Jan
    }
  }
};

// Memoize components object
const createComponents = (toolbarHandlers, view, events, bidName, organisationId, userEmail) => ({
  event: (props) => <EventComponentWrapper {...props} view={view} />,
  toolbar: (props) => <CalendarToolbar {...props} {...toolbarHandlers} events={events} bidName={bidName} organisationId={organisationId} userEmail={userEmail} />
});

export const useCalendarProps = ({
  events = emptyArray,
  view,
  date,
  handlers,
  toolbarHandlers,
  bidName,
  organisationId,
  userEmail
}) => {
  // Memoize components to prevent unnecessary re-renders
  const components = useMemo(
    () => createComponents(toolbarHandlers, view, events, bidName, organisationId, userEmail),
    [toolbarHandlers, view, events, bidName, organisationId, userEmail]
  );
  
  // Memoize eventPropGetter to style different event types
  const eventPropGetter = useMemo(() => (event) => {
    const isTask = event.type === 'task';
    const isMilestone = event.type === 'milestone';
    const isMonthView = view === 'month';
    
    return {
      className: `event-${event.type} event-${event.status || 'default'}`,
      style: isMonthView ? {
        // Month view styling
        backgroundColor: 'transparent',
        color: isTask ? '#1b5e20' : isMilestone ? '#e65100' : '#0d47a1',
        borderLeft: `3px solid ${isTask ? '#4caf50' : isMilestone ? '#ff9800' : '#2196f3'}`,
        padding: '0 4px',
        height: '22px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.8rem',
        margin: '1px 0'
      } : {
        // Other views styling
        backgroundColor: isTask ? 'rgba(76, 175, 80, 0.1)' : isMilestone ? 'rgba(255, 152, 0, 0.1)' : 'rgba(33, 150, 243, 0.1)',
        color: isTask ? '#1b5e20' : isMilestone ? '#e65100' : '#0d47a1',
        borderLeft: `3px solid ${isTask ? '#4caf50' : isMilestone ? '#ff9800' : '#2196f3'}`,
        borderRadius: '2px',
        padding: '4px 8px'
      }
    };
  }, [view]);

  // Set min/max dates for agenda view to show all events
  const dateRange = useMemo(() => {
    if (view === 'agenda' && events.length > 0) {
      const dates = events.map(event => [new Date(event.start), new Date(event.end)]).flat();
      return {
        min: new Date(Math.min(...dates)),
        max: new Date(Math.max(...dates))
      };
    }
    return {
      min: undefined,
      max: undefined
    };
  }, [view, events]);

  return useMemo(() => ({
    ...baseCalendarProps,
    events,
    view,
    date,
    onView: handlers.handleViewChange,
    onNavigate: handlers.handleNavigate,
    onSelectEvent: handlers.handleEventClick,
    onSelectSlot: handlers.handleSlotSelect,
    onSelecting: () => true,
    components,
    eventPropGetter,
    selectable: true,
    popup: true,
    dayLayoutAlgorithm: 'no-overlap',
    ...dateRange  // Apply min/max dates
  }), [
    events,
    view,
    date,
    handlers.handleViewChange,
    handlers.handleNavigate,
    handlers.handleEventClick,
    handlers.handleSlotSelect,
    components,
    eventPropGetter,
    dateRange
  ]);
}; 