import api from './api';

const apiCall = async (method, endpoint, data = null, orgId = null) => {
    try {
        const config = {
            method,
            url: `/api/email${endpoint}`,
            headers: {
                'Content-Type': 'application/json',
                ...(orgId && { 'x-organization-id': orgId })
            }
        };

        if (data) {
            config.data = data;
        }

        const response = await api(config);
        return response.data;
    } catch (error) {
        if (error.response?.status === 401) {
            throw new Error('Invalid token');
        }
        throw new Error(`API error: ${error.response?.data?.message || error.message}`);
    }
};

const sendAgendaEmail = async (bidName, agendaText, organisationId, userEmail) => {
    return apiCall('post', '', {
        type: 'agenda',
        data: {
            bidName,
            agendaText,
            organisationId,
            email: userEmail
        }
    }, organisationId);
};

export default {
    sendAgendaEmail
}; 
