import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';

// Helper functions for timezone handling
const convertToLocalTime = (date) => {
  if (!date) return '';
  // The input is in UTC, convert to local time
  const localTime = moment(date).local().format('YYYY-MM-DDTHH:mm');
  console.log('Converting to local time:', {
    input: date,
    output: localTime
  });
  return localTime;
};

const convertToUTC = (date) => {
  if (!date) return '';
  // The input is in local time, convert to UTC
  const utcTime = moment(date).utc().format();
  console.log('Converting to UTC:', {
    input: date,
    output: utcTime
  });
  return utcTime;
};

export const TaskDialog = ({
  open,
  onClose,
  onSave,
  task = null,
  availableUsers = [],
  onDelete,
  loading = false
}) => {
  const [formData, setFormData] = useState({
    title: '',
    dueDate: '',
    status: 'not_started',
    assignedTo: null,
    assignedToName: 'Unassigned',
    description: ''
  });

  const { enqueueSnackbar } = useSnackbar();

  // Filter out any users without IDs and ensure proper structure
  const validUsers = useMemo(() => {
    return availableUsers
      .filter(user => user.id)
      .map(user => ({
        id: user.id,
        name: user.name || 'Unknown User',
        email: user.email
      }));
  }, [availableUsers]);

  // Find the selected user based on the current assignedTo value
  const selectedUser = useMemo(() => {
    if (!formData.assignedTo) return null;
    return validUsers.find(u => u.id === formData.assignedTo);
  }, [validUsers, formData.assignedTo]);

  useEffect(() => {
    if (task) {
      setFormData({
        id: task.id,
        title: task.title || '',
        dueDate: convertToLocalTime(task.dueDate) || '',
        status: task.status || 'not_started',
        assignedTo: task.assignedTo || null,
        assignedToName: task.assignedToName || 'Unassigned',
        description: task.description || ''
      });
    } else {
      setFormData({
        title: '',
        dueDate: '',
        status: 'not_started',
        assignedTo: null,
        assignedToName: 'Unassigned',
        description: ''
      });
    }
  }, [task]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    
    // Validate required fields
    if (!formData.title?.trim()) {
      enqueueSnackbar('Title is required', { variant: 'error' });
      return;
    }
    if (!formData.dueDate) {
      enqueueSnackbar('Due date is required', { variant: 'error' });
      return;
    }

    const selectedUser = validUsers.find(u => u.id === formData.assignedTo);
    const submissionData = {
      ...formData,
      title: formData.title.trim(),
      dueDate: convertToUTC(formData.dueDate),
      assignedTo: selectedUser?.id || null,
      assignedToName: selectedUser?.name || 'Unassigned'
    };

    if (onSave) {
      try {
        let success = false;
        if (task?.id) {
          success = await onSave(task.id, submissionData);
        } else {
          success = await onSave(submissionData);
        }
        
        if (success) {
          handleClose();
        }
      } catch (error) {
        enqueueSnackbar('Failed to save task', { variant: 'error' });
      }
    }
  }, [formData, validUsers, onSave, handleClose, task, enqueueSnackbar]);

  const handleDelete = useCallback(async () => {
    if (onDelete && formData.id) {
      await onDelete(formData.id);
      handleClose();
    }
  }, [onDelete, formData.id, handleClose]);

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {task?.id ? 'Edit Task' : 'New Task'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            value={formData.title}
            onChange={handleChange}
            required
            error={!formData.title?.trim()}
            helperText={!formData.title?.trim() ? 'Title is required' : ''}
          />
          <TextField
            margin="dense"
            name="dueDate"
            label="Due Date"
            type="datetime-local"
            fullWidth
            value={formData.dueDate}
            onChange={handleChange}
            required
            error={!formData.dueDate}
            helperText={!formData.dueDate ? 'Due date is required' : ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value="not_started">Not Started</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="blocked">Blocked</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            value={selectedUser}
            onChange={(event, newValue) => {
              setFormData(prev => ({
                ...prev,
                assignedTo: newValue?.id || null,
                assignedToName: newValue?.name || 'Unassigned'
              }));
            }}
            options={validUsers}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Assigned To"
                fullWidth
              />
            )}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            value={formData.description}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          {task && onDelete && (
            <span>
              <Button 
                onClick={handleDelete}
                color="error"
                disabled={loading}
              >
                Delete
              </Button>
            </span>
          )}
          <span>
            <Button 
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </Button>
          </span>
          <span>
            <Button 
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || !formData.title}
            >
              Save
            </Button>
          </span>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.memo(TaskDialog); 
