import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { handleRedirectPromise, getActiveAccount, setActiveAccount, getToken } from './services/authService';
import { getOrganisationsForUser, getUser } from './services/userService';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider, responsiveFontSizes, createTheme } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import Dashboard from './pages/DashboardPage/Dashboard';
import Profile from './pages/ProfilePage/Profile';
import Settings from './pages/Settings';
import Layout from './components/layout/Layout';
import MyOrganisation from './pages/MyOrganisationPage/MyOrganisation';
import BidManagementDashboard from './pages/DashboardPage/BidManagementDashboard';
import { acceptInvitation } from './services/invitationService';
import { createOrGetUser } from './services/userService';
import LandingPage from './pages/LandingPage/LandingPage';
import AboutUs from './pages/AboutUs/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import Contact from './pages/Contact/Contact';
import Pricing from './pages/Pricing/Pricing';
import Subscriptions from './pages/Subscriptions/Subscriptions';
import FirstLoginHandler from './components/FirstLoginHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Snackbar, Alert } from '@mui/material';
import BrandBuilder from './components/BrandBuilder/BrandBuilder';
import BrandDashboard from './components/BrandBuilder/ToneOfVoiceGuide';
import PortfolioManager from './components/Portfolio/PortfolioManager';
import AccountsHome from './components/Accounts/AccountsHome';
import { createOrganisation } from './services/organisationService';
import PortfolioPage from './pages/PortfolioPage';
import CollateralLibrary from './components/Collateral/CollateralLibrary';
import { StripeProvider } from './contexts/StripeContext';
import { subscriptionService } from './services/subscriptionService';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

// Import Poppins font
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

// Import keyframes for animation
import { keyframes } from '@mui/material/styles';

// Define the animation keyframes
const moveGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Create and export the theme so it can be imported in other files if needed
const appTheme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#2BA84A',
    },
    secondary: {
      main: '#0077B6',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 500,
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          minHeight: '100vh',
          backgroundImage: 'url("/growthbackground.png")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          '&::before': {
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Add a semi-transparent white overlay
            zIndex: -1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
}));

// Import LanguageProvider
import { LanguageProvider } from './contexts/LanguageContext';

// First add these imports at the top
import CustomerLifecycleDashboard from './components/Accounts/CustomerLifecycleDashboard';
import ContactManagement from './components/Accounts/ContactManagement';
import ProposalBuilder from './components/Accounts/ProposalBuilder';
import CRMAnalytics from './components/Accounts/CRMAnalytics';
import AccountIntegrations from './components/Accounts/AccountIntegrations';
import AccountSettings from './components/Accounts/AccountSettings';
import AccountsDashboard from './components/Accounts/AccountsDashboard';
import DealManagement from './components/Accounts/DealManagement';
import ActivitiesHome from './components/Accounts/ActivitiesHome';
import BusinessStrategy from './components/BusinessStrategy/BusinessStrategy';
import FundraisingDashboard from './components/Fundraising/FundraisingDashboard';
import GrantsManager from './components/Fundraising/GrantsManager';
import PrivateCapital from './components/Fundraising/PrivateCapital';
import PublicOffering from './components/Fundraising/PublicOffering';
import FundraisingAnalytics from './components/Fundraising/FundraisingAnalytics';
import FundraisingJourney from './components/Fundraising/FundraisingJourney';
import { getBrandData } from './services/brandService';
import BrandPage from './pages/BrandPage';
import StoryBrandCanvas from './components/orgcanvases/StoryBrandCanvas';
import { updateOrgCanvas } from './services/organisationcanvasService';
import ToneOfVoiceGuide from './components/BrandBuilder/ToneOfVoiceGuide';
import CollateralPage from './pages/CollateralPage';
import AccountDetailsDashboard from './components/Accounts/AccountDetailsDashboard';

function AppContent() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [orgLoading, setOrgLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [brandData, setBrandData] = useState(null);
  const [toneOfVoiceData, setToneOfVoiceData] = useState(null);

  useEffect(() => {
    console.log('selectedOrg changed:', selectedOrg);
    console.log('selectedOrg.id:', selectedOrg?.id);
  }, [selectedOrg]);

  useEffect(() => {
    const initializeUserData = async () => {
      if (isAuthenticated && accounts.length > 0) {
        try {
          setOrgLoading(true);
          const profile = await getActiveAccount();
          if (profile) {
            setUserData(profile);
            const orgs = await getOrganisationsForUser(profile.localAccountId);
            if (orgs && orgs.length > 0) {
              setSelectedOrg(orgs[0]);
            } else {
              setIsNewUser(true);
            }
          } else {
            throw new Error('Failed to retrieve user profile');
          }
          setShowLandingPage(false);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
          setOrgLoading(false);
        }
      } else {
        setLoading(false);
        setOrgLoading(false);
      }
    };

    initializeUserData();
  }, [isAuthenticated, accounts]);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleLogin = (type) => {
    if (type === 'signup') {
      instance.loginRedirect({
        scopes: ["openid", "profile", "email"],
        prompt: "select_account",
        extraQueryParameters: { ui_locales: "en-US", signup: "true" }
      });
    } else {
      instance.loginRedirect({
        scopes: ["openid", "profile", "email"],
        prompt: "select_account"
      });
    }
  };

  const handleCreateOrganization = async (orgData) => {
    try {
      const newOrg = await createOrganisation({
        name: orgData.name,
        createdBy: userData.localAccountId,
      });
      setSelectedOrg(newOrg);
      setIsNewUser(false);
      toast.success('Organization created successfully!');
      return newOrg;
    } catch (error) {
      toast.error(`Failed to create organization: ${error.message}`);
      throw error;
    }
  };

  const fetchSubscriptionStatus = async (userId) => {
    try {
      const status = await subscriptionService.getUserSubscriptionStatus(userId);
      setSubscriptionStatus(status);
    } catch (error) {
      if (error.response?.status !== 404) {
        console.error('Error fetching subscription status:', error);
        toast.error('Failed to fetch subscription status');
      }
      setSubscriptionStatus({
        status: 'inactive',
        planId: null,
        trialEndDate: null
      });
    }
  };

  const calculateDaysRemaining = (endDate) => {
    const end = new Date(endDate);
    const now = new Date();
    const diffTime = end - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? diffDays : 0;
  };

  const renderTrialMessage = () => {
    if (!subscriptionStatus || subscriptionStatus.status !== 'trial') return null;

    const daysRemaining = calculateDaysRemaining(subscriptionStatus.trialEndDate);
    return (
      <Alert 
        severity="info" 
        icon={<StarIcon />}
        action={
          <Button color="inherit" size="small" variant="outlined" component={Link} to="/subscriptions">
            Subscribe Now
          </Button>
        }
        sx={{ mb: 2 }}
      >
        Your free trial ends in {daysRemaining} days. Subscribe now to continue enjoying our services!
      </Alert>
    );
  };

  useEffect(() => {
    if (userData?.localAccountId) {
      fetchSubscriptionStatus(userData.localAccountId);
    }
  }, [userData]);

  const fetchBrandData = async (orgId) => {
    try {
      const data = await getBrandData(orgId);
      setBrandData(data.brandData);
      setToneOfVoiceData(data.toneOfVoiceData);
    } catch (error) {
      console.error('Error fetching brand data:', error);
    }
  };

  useEffect(() => {
    if (selectedOrg) {
      fetchBrandData(selectedOrg.id || selectedOrg);
    }
  }, [selectedOrg]);

  useEffect(() => {
    console.log('Current selectedOrg:', selectedOrg);
  }, [selectedOrg]);

  if (loading || orgLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Routes>
        {showLandingPage ? (
          <Route path="/" element={<LandingPage onLoginClick={handleLogin} />} />
        ) : (
          <Route element={<Layout user={userData} selectedOrg={selectedOrg} />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            {isNewUser ? (
              <Route path="/dashboard" element={<FirstLoginHandler userId={userData?.localAccountId} email={userData?.email} name={userData?.name} onCreateOrganization={handleCreateOrganization} />} />
            ) : (
              <Route 
                path="/dashboard/*"
                element={
                  <Dashboard 
                    userData={userData} 
                    selectedOrgId={selectedOrg}
                  />
                } 
              />
            )}
            <Route path="/manage-organisations" element={<MyOrganisation userData={userData} selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />} />
            <Route path="/profile" element={
              userData ? <Profile userData={userData} /> : <Navigate to="/" replace />
            } />
            <Route path="/settings" element={<Settings />} />
            <Route path="/bids" element={<BidManagementDashboard userId={userData?.localAccountId} selectedOrg={selectedOrg} />} />
            <Route path="/bid-management/:bidId" element={<BidManagementDashboard userId={userData?.localAccountId} selectedOrg={selectedOrg} />} />
            <Route 
              path="/subscriptions" 
              element={
                <Subscriptions 
                  userData={userData} 
                  selectedOrg={selectedOrg}
                  subscriptionStatus={subscriptionStatus}
                  setSubscriptionStatus={setSubscriptionStatus}
                />
              } 
            />
            <Route path="/my-organisation" element={<MyOrganisation userData={userData} selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />} />
            <Route 
              path="/brand" 
              element={
                <BrandPage 
                  selectedOrg={selectedOrg}
                  userData={userData}
                  brandData={brandData}
                  toneOfVoiceData={toneOfVoiceData}
                />
              } 
            />
            <Route 
              path="/brand/tone-of-voice" 
              element={
                <BrandBuilder 
                  selectedOrg={selectedOrg}
                  userData={userData}
                  brandData={brandData}
                  toneOfVoiceData={toneOfVoiceData}
                  isEditMode={true}
                  onUpdate={(newData) => {
                    setBrandData(newData.brandData);
                    setToneOfVoiceData(newData.toneOfVoiceData);
                  }}
                />
              } 
            />
            <Route 
              path="/brand/story" 
              element={
                <StoryBrandCanvas 
                  user={userData}
                  userData={userData}
                  selectedOrg={selectedOrg}
                  canvas={brandData?.storyBrand || {}}
                  onClose={() => navigate('/brand')}
                  onSave={async (updatedCanvas) => {
                    try {
                      // Save the canvas data
                      const savedCanvas = await updateOrgCanvas(
                        selectedOrg.id, 
                        updatedCanvas.id || 'new', 
                        {
                          ...updatedCanvas,
                          type: 'StoryBrand Canvas'
                        }
                      );
                      // Update local state
                      setBrandData(prev => ({
                        ...prev,
                        storyBrand: savedCanvas
                      }));
                      return savedCanvas;
                    } catch (error) {
                      console.error('Error saving StoryBrand canvas:', error);
                      throw error;
                    }
                  }}
                />
              } 
            />
            <Route 
              path="/collateral" 
              element={
                <CollateralPage 
                  selectedOrg={selectedOrg} 
                  userData={userData}
                />
              } 
            />
            <Route path="/fundraising/*" element={<FundraisingDashboard userData={userData} selectedOrg={selectedOrg} />} />
            <Route path="/fundraising/marketplace" element={<FundraisingDashboard userData={userData} selectedOrg={selectedOrg} />} />
            <Route path="/fundraising/journey" element={<FundraisingJourney userData={userData} selectedOrg={selectedOrg} />} />
            <Route path="/fundraising/grants" element={<GrantsManager userData={userData} selectedOrg={selectedOrg} />} />
            <Route path="/fundraising/private-capital" element={<PrivateCapital userData={userData} selectedOrg={selectedOrg} />} />
            <Route path="/fundraising/public-offering" element={<PublicOffering userData={userData} selectedOrg={selectedOrg} />} />
            <Route path="/fundraising/analytics" element={<FundraisingAnalytics userData={userData} selectedOrg={selectedOrg} />} />
            <Route path="/accounts" element={<AccountsHome userData={userData} selectedOrg={selectedOrg} />}>
              <Route index element={<Navigate to="dashboard" replace />} />
              <Route path="dashboard" element={<AccountsDashboard userData={userData} selectedOrg={selectedOrg} />} />
              <Route path="account/:accountId" element={<AccountDetailsDashboard userData={userData} selectedOrg={selectedOrg} />} />
              <Route path="contacts" element={<ContactManagement userData={userData} selectedOrg={selectedOrg} />} />
              <Route path="deals" element={<DealManagement userData={userData} selectedOrg={selectedOrg} />} />
              <Route path="activities" element={<ActivitiesHome userData={userData} selectedOrg={selectedOrg} />} />
              <Route path="integrations" element={<AccountIntegrations userData={userData} selectedOrg={selectedOrg} />} />
              <Route path="settings" element={<AccountSettings userData={userData} selectedOrg={selectedOrg} />} />
              <Route path="lifecycle" element={<CustomerLifecycleDashboard userData={userData} selectedOrg={selectedOrg} />} />
            </Route>
            <Route 
              path="/business-strategy" 
              element={
                <BusinessStrategy 
                  userData={userData} 
                  user={userData} 
                  selectedOrg={selectedOrg}
                  brandData={brandData}
                  toneOfVoiceData={toneOfVoiceData}
                />
              } 
            />
            <Route 
              path="/brand-builder" 
              element={
                <BrandBuilder 
                  selectedOrg={selectedOrg}
                  userData={userData}
                  brandData={brandData}
                  toneOfVoiceData={toneOfVoiceData}
                  onUpdate={(newData) => {
                    setBrandData(newData.brandData);
                    setToneOfVoiceData(newData.toneOfVoiceData);
                  }}
                />
              } 
            />
            <Route 
              path="/portfolio" 
              element={
                <PortfolioManager 
                  selectedOrg={selectedOrg}
                  userData={userData}
                />
              } 
            />
          </Route>
        )}
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/first-login" element={<FirstLoginHandler />} />
      </Routes>
      <Snackbar open={isOffline} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="warning" sx={{ width: '100%' }}>
          You are currently offline. Some features may not be available.
        </Alert>
      </Snackbar>
      {renderTrialMessage()}
    </>
  );
}

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      await handleRedirectPromise();
      setIsInitialized(true);
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    const handleAuthentication = async () => {
      if (isAuthenticated && accounts.length > 0 && isInitialized) {
        const account = accounts[0];
        setActiveAccount(account);
        
        try {
          // Create or get the user
          const user = await createOrGetUser(account.localAccountId, account.username, account.name);

          // Check for invitation code in URL or localStorage
          const urlParams = new URLSearchParams(window.location.search);
          const invitationCode = urlParams.get('invitationCode') || localStorage.getItem('invitationCode');

          if (invitationCode) {
            try {
              const result = await acceptInvitation(invitationCode);
              // Clear the invitation code
              localStorage.removeItem('invitationCode');
              // Optionally, remove the invitationCode from the URL
              window.history.replaceState({}, document.title, window.location.pathname);
            } catch (error) {
              // Handle invitation acceptance error (e.g., show an error message to the user)
            }
          }

          // Fetch user's organisations after potential invitation acceptance
          await getOrganisationsForUser(account.localAccountId);
          
        } catch (error) {
          // Handle error during user creation or invitation acceptance
        }
      }
    };

    handleAuthentication();
  }, [isAuthenticated, accounts, isInitialized]);

  if (!isInitialized) {
    return <div>Initializing...</div>;
  }

  return (
    <StripeProvider>
      <LanguageProvider>
        <StylesProvider injectFirst>
          <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <Router>
              <AppContent />
            </Router>
            <ToastContainer />
          </ThemeProvider>
        </StylesProvider>
      </LanguageProvider>
    </StripeProvider>
  );
}

export default App;
