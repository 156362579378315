import React, { useState } from 'react';
import {
  Paper, Typography, Box, IconButton,
  TextField, Button, Grid, List,
  ListItem, ListItemText, ListItemSecondaryAction,
  Tooltip, Link, Dialog, DialogTitle,
  DialogContent, DialogActions
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Launch as LaunchIcon
} from '@mui/icons-material';

const extractMeaningfulPath = (url) => {
  try {
    // Handle SharePoint URLs
    if (url.includes('sharepoint.com')) {
      const matches = url.match(/\/([^\/]+)\?/);
      if (matches && matches[1]) {
        // URL decode the folder name
        return decodeURIComponent(matches[1]);
      }
    }
    // For other URLs, show the last meaningful part
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split('/').filter(Boolean);
    return pathParts[pathParts.length - 1] || urlObj.hostname;
  } catch (e) {
    return url;
  }
};

const BidDocumentLinks = ({ bid, onUpdateBid }) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [newLink, setNewLink] = useState({ title: '', url: '' });
  const [error, setError] = useState('');

  const handleAddLink = () => {
    if (!newLink.title || !newLink.url) {
      setError('Both title and URL are required');
      return;
    }

    try {
      new URL(newLink.url); // Validate URL format
      const updatedBid = {
        ...bid,
        documentLinks: [...(bid.documentLinks || []), newLink]
      };
      onUpdateBid(updatedBid);
      setIsAddDialogOpen(false);
      setNewLink({ title: '', url: '' });
      setError('');
    } catch (e) {
      setError('Please enter a valid URL');
    }
  };

  const handleDeleteLink = (index) => {
    const updatedBid = {
      ...bid,
      documentLinks: bid.documentLinks.filter((_, i) => i !== index)
    };
    onUpdateBid(updatedBid);
  };

  return (
    <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Document Links</Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setIsAddDialogOpen(true)}
          variant="contained"
          color="primary"
        >
          Add Link
        </Button>
      </Box>

      <List>
        {(bid.documentLinks || []).map((link, index) => (
          <ListItem key={index} divider
            sx={{
              pr: '100px', // Make room for controls
              '& .MuiListItemText-root': {
                mr: 0 // Remove default margin
              }
            }}
          >
            <ListItemText
              sx={{
                '& .MuiListItemText-primary': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                },
                '& .MuiListItemText-secondary': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }
              }}
              primary={link.title}
              secondary={
                <Tooltip title={link.url}>
                  <Link 
                    href={link.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {extractMeaningfulPath(link.url)}
                  </Link>
                </Tooltip>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title="Open in new tab">
                <IconButton
                  edge="end"
                  onClick={() => window.open(link.url, '_blank')}
                  sx={{ mr: 1 }}
                >
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  edge="end"
                  onClick={() => handleDeleteLink(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {(!bid.documentLinks || bid.documentLinks.length === 0) && (
          <ListItem>
            <ListItemText
              primary="No document links added"
              secondary="Click 'Add Link' to add documentation locations"
            />
          </ListItem>
        )}
      </List>

      <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
        <DialogTitle>Add Document Link</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={newLink.title}
                onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
                error={!!error && !newLink.title}
                helperText={error && !newLink.title ? 'Title is required' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="URL"
                value={newLink.url}
                onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
                error={!!error && !newLink.url}
                helperText={error && !newLink.url ? error : ''}
                placeholder="https://..."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddLink} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BidDocumentLinks; 