import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Button,
  Tabs,
  Tab,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Tooltip,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Snackbar,
  Autocomplete
} from '@mui/material';
import {
  Add as AddIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Sync as SyncIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Cloud as CloudIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import { contactService } from '../../services/contactService';
import { listNotes, addNote, updateNote, deleteNote } from '../../services/contactNotesService';
import accountService from '../../services/accountService';
import { pipedriveService } from '../../services/pipedriveService';
import accountManagementService from '../../services/accountManagementService';

const ContactManagement = ({ userData, selectedOrg }) => {
  const { selectedAccount } = useOutletContext();
  const [currentTab, setCurrentTab] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [syncStatus, setSyncStatus] = useState('idle');
  const [formData, setFormData] = useState({
    name: '',
    firstName: '',
    lastName: '',
    email: [{
      value: '',
      primary: true,
      label: 'work'
    }],
    phone: [{
      value: '',
      primary: true,
      label: 'work'
    }],
    jobTitle: '',
    accountId: '',
    visibleTo: '3',
    marketingStatus: 'no_consent',
    address: '',
    customFields: {},
    role: '',
    notes: '',
    source: 'puawai'
  });
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [roleData, setRoleData] = useState({
    role: '',
    notes: ''
  });
  const [contactNotes, setContactNotes] = useState({});
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editedNoteContent, setEditedNoteContent] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [hasFormChanges, setHasFormChanges] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Add isPipedriveContact computation
  const isPipedriveContact = selectedContact?.source === 'pipedrive';

  // Add role options
  const roleOptions = [
    { value: '', label: 'No Role' },
    { value: 'decision_maker', label: 'Decision Maker', color: 'primary' },
    { value: 'influencer', label: 'Influencer', color: 'secondary' },
    { value: 'user', label: 'End User', color: 'default' },
    { value: 'technical', label: 'Technical Contact', color: 'info' },
    { value: 'finance', label: 'Finance Contact', color: 'warning' }
  ];

  useEffect(() => {
    if (selectedOrg) {
      fetchContacts();
      fetchAccounts(); // Fetch accounts when component mounts
    }
  }, [selectedOrg, selectedAccount]);

  const fetchAccounts = async () => {
    try {
      setLoadingAccounts(true);
      console.debug('Fetching accounts for:', selectedOrg);
      const response = await accountService.listAccounts(selectedOrg);
      console.debug('Accounts response:', response);
      if (response.accounts) {
        // Map accounts to a consistent format
        const mappedAccounts = response.accounts.map(account => ({
          id: account.id,
          name: account.name || 'Unnamed Account',
          source: account.source || 'puawai'
        }));
        console.debug('Mapped accounts:', mappedAccounts);
        setAccounts(mappedAccounts);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      showSnackbar('Failed to fetch accounts', 'error');
    } finally {
      setLoadingAccounts(false);
    }
  };

  const fetchContactRole = async (contactId) => {
    try {
      const response = await contactService.getContactRole(
        selectedOrg,
        selectedAccount?.id || 'unassigned',
        contactId
      );
      console.log('Got role response:', response);
      
      if (response.success && response.contactRole) {
        console.log('Role data for contact:', contactId, response.contactRole);
        
        // Update the contact with role data
        setContacts(prevContacts => 
          prevContacts.map(contact => 
            contact.id === contactId
              ? {
                  ...contact,
                  role: response.contactRole.role,
                  roleMetadata: {
                    assignedBy: response.contactRole.assignedBy,
                    assignedDate: response.contactRole.updatedAt,
                    notes: response.contactRole.notes
                  }
                }
              : contact
          )
        );
      }
    } catch (err) {
      console.error('Error fetching contact role:', err);
    }
  };

  const fetchContacts = async () => {
    try {
      setLoading(true);
      const response = await contactService.listContacts(selectedOrg, selectedAccount?.id);
      
      if (response && Array.isArray(response.contacts)) {
        // Set contacts first
        const contactsWithRoles = await Promise.all(
          response.contacts.map(async (contact) => {
            try {
              const roleResponse = await contactService.getContactRole(
                selectedOrg,
                selectedAccount?.id || 'unassigned',
                contact.id
              );
              
              // If roleResponse is successful and contains role data
              if (roleResponse && roleResponse.role) {
                return {
                  ...contact,
                  role: roleResponse.role,
                  roleMetadata: {
                    assignedBy: roleResponse.assignedBy || '',
                    assignedDate: roleResponse.updatedAt || '',
                    notes: roleResponse.notes || ''
                  }
                };
              }
              
              // If no role data, return contact with null role
              return {
                ...contact,
                role: null,
                roleMetadata: null
              };
            } catch (err) {
              return {
                ...contact,
                role: null,
                roleMetadata: null
              };
            }
          })
        );
        
        setContacts(contactsWithRoles);
      } else {
        setContacts([]);
      }
      setError(null);
    } catch (err) {
      setError('Failed to load contacts');
      setContacts([]);
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleSyncContacts = async () => {
    if (!selectedOrg) {
      setError('No organization selected');
      showSnackbar('No organization selected', 'error');
      return;
    }

    try {
      setSyncStatus('syncing');
      setError(null);
      
      // Call the pipedriveService to sync contacts
      await pipedriveService.syncData(selectedOrg, 'contacts');
      
      // Refresh the contacts list
      await fetchContacts();
      
      setSyncStatus('completed');
      showSnackbar('Contacts synced successfully with Pipedrive');
    } catch (err) {
      console.error('Error syncing contacts:', err);
      setError(err.message || 'Failed to sync contacts with Pipedrive');
      setSyncStatus('error');
      showSnackbar(err.message || 'Failed to sync contacts with Pipedrive', 'error');
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedContact(null);
    setFormData({
      name: '',
      firstName: '',
      lastName: '',
      email: [{
        value: '',
        primary: true,
        label: 'work'
      }],
      phone: [{
        value: '',
        primary: true,
        label: 'work'
      }],
      jobTitle: '',
      accountId: '',
      visibleTo: '3',
      marketingStatus: 'no_consent',
      address: '',
      customFields: {},
      role: '',
      notes: '',
      source: 'puawai'
    });
    setHasFormChanges(false);
  };

  const handleSubmit = async () => {
    console.debug('Submitting form with data:', {
      formData,
      selectedContact,
      selectedOrg,
      effectiveAccountId: formData.accountId || 'unassigned'
    });

    try {
      setLoading(true);
      setError(null);
      
      const isPipedriveContact = selectedContact?.source === 'pipedrive';
      // Get the selected organization for role updates
      const effectiveAccountId = formData.accountId || 'unassigned';

      if (!selectedOrg) {
        throw new Error('No organization context found');
      }

      if (isPipedriveContact) {
        console.debug('Updating Pipedrive contact role:', {
          organisationId: selectedOrg,
          accountId: effectiveAccountId,
          contactId: selectedContact.id,
          role: formData.role,
          notes: formData.notes
        });

        await contactService.updateContactRole(
          selectedOrg,
          effectiveAccountId,
          selectedContact.id,
          {
            role: formData.role,
            assignedBy: userData.name,
            notes: formData.notes || '',
            updatedAt: new Date().toISOString()
          }
        );
      } else {
        const contactData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          name: `${formData.firstName} ${formData.lastName}`.trim(),
          email: [{
            value: formData.email[0]?.value || '',
            primary: true,
            label: 'work'
          }],
          phone: [{
            value: formData.phone[0]?.value || '',
            primary: true,
            label: 'work'
          }],
          jobTitle: formData.jobTitle || '',
          accountId: effectiveAccountId,
          visibleTo: formData.visibleTo || '3',
          marketingStatus: formData.marketingStatus || 'no_consent',
          address: formData.address || '',
          customFields: formData.customFields || {},
          role: formData.role || '',
          roleMetadata: {
            assignedBy: userData.name,
            assignedDate: new Date().toISOString(),
            notes: formData.notes || ''
          },
          source: 'puawai'
        };

        console.debug('Saving contact data:', {
          contactData,
          isUpdate: Boolean(selectedContact),
          selectedContactId: selectedContact?.id
        });

        if (selectedContact) {
          await contactService.updateContact(
            selectedOrg,
            effectiveAccountId,
            selectedContact.id,
            contactData
          );

          console.debug('Updating contact role:', {
            organisationId: selectedOrg,
            accountId: effectiveAccountId,
            contactId: selectedContact.id,
            role: formData.role,
            notes: formData.notes
          });

          await contactService.updateContactRole(
            selectedOrg,
            effectiveAccountId,
            selectedContact.id,
            {
              role: formData.role,
              assignedBy: userData.name,
              notes: formData.notes || '',
              updatedAt: new Date().toISOString()
            }
          );
        } else {
          const response = await contactService.createContact(
            selectedOrg,
            effectiveAccountId,
            contactData
          );

          if (response.success && formData.role) {
            console.debug('Creating contact role for new contact:', {
              organisationId: selectedOrg,
              accountId: effectiveAccountId,
              contactId: response.contact.id,
              role: formData.role,
              notes: formData.notes
            });

            await contactService.updateContactRole(
              selectedOrg,
              effectiveAccountId,
              response.contact.id,
              {
                role: formData.role,
                assignedBy: userData.name,
                notes: formData.notes || '',
                updatedAt: new Date().toISOString()
              }
            );
          }
        }
      }

      showSnackbar('Contact saved successfully');
      handleClose();
      await fetchContacts();
    } catch (error) {
      console.error('Error saving contact:', error);
      setError(error.message || 'Failed to save contact');
      showSnackbar(error.message || 'Failed to save contact', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (contact) => {
    setContactToDelete(contact);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      setError(null);

      await contactService.deleteContact(
        selectedOrg,
        selectedAccount?.id || 'unassigned',
        contactToDelete.id
      );

      // Update local state immediately
      setContacts(prevContacts => 
        prevContacts.filter(c => c.id !== contactToDelete.id)
      );

      // Show success message
      setError(null);
      
      // Close dialog
      setDeleteDialogOpen(false);
      setContactToDelete(null);

      // Refresh contacts list
      await fetchContacts();
    } catch (err) {
      console.error('Error deleting contact:', err);
      setError(err.message || 'Failed to delete contact');
    } finally {
      setLoading(false);
    }
  };

  const handleEditContact = (contact) => {
    console.debug('Editing contact:', {
      contact,
      accountId: contact.accountId,
      role: contact.role,
      roleMetadata: contact.roleMetadata
    });

    if (contact.source === 'pipedrive') {
      // For Pipedrive contacts, map organization from data.org_id
      const pipedriveOrgId = contact.data?.org_id?.value;
      
      // Get email and phone from top level properties
      const primaryEmail = contact.email || contact.data?.primary_email || '';
      const primaryPhone = contact.phone || '';

      setFormData({
        firstName: contact.data?.first_name || contact.name?.split(' ')[0] || '',
        lastName: contact.data?.last_name || contact.name?.split(' ').slice(1).join(' ') || '',
        email: [{
          value: primaryEmail,
          primary: true,
          label: 'work'
        }],
        phone: [{
          value: primaryPhone,
          primary: true,
          label: 'work'
        }],
        jobTitle: contact.data?.job_title || '',
        accountId: contact.accountId || '',
        visibleTo: contact.data?.visible_to || '3',
        role: contact.role || '',
        notes: contact.roleMetadata?.notes || '',
        marketingStatus: 'no_consent',
        address: contact.data?.postal_address || '',
        customFields: contact.data?.custom_fields || {}
      });
    } else {
      // For local contacts, allow full editing
      const [firstName, ...lastNameParts] = contact.name.split(' ');
      const formDataUpdate = {
        firstName: firstName || '',
        lastName: lastNameParts.join(' ') || '',
        email: contact.email || [{
          value: '',
          primary: true,
          label: 'work'
        }],
        phone: contact.phone || [{
          value: '',
          primary: true,
          label: 'work'
        }],
        jobTitle: contact.jobTitle || '',
        accountId: contact.accountId || '',
        visibleTo: contact.visibleTo || '3',
        marketingStatus: contact.marketingStatus || 'no_consent',
        address: contact.address || '',
        customFields: contact.customFields || {},
        role: contact.role || '',
        notes: contact.roleMetadata?.notes || '',
        source: 'puawai'
      };
      console.debug('Setting form data:', formDataUpdate);
      setFormData(formDataUpdate);
    }

    // Set role data for the role dialog
    const roleDataUpdate = {
      role: contact.role || '',
      notes: contact.roleMetadata?.notes || ''
    };
    console.debug('Setting role data:', roleDataUpdate);
    setRoleData(roleDataUpdate);
    setSelectedContact(contact);
    setDialogOpen(true);
  };

  const handleAdd = () => {
    setSelectedContact(null);
    setFormData({
      name: '',
      firstName: '',
      lastName: '',
      email: [{
        value: '',
        primary: true,
        label: 'work'
      }],
      phone: [{
        value: '',
        primary: true,
        label: 'work'
      }],
      jobTitle: '',
      accountId: '',
      visibleTo: '3',
      marketingStatus: 'no_consent',
      address: '',
      customFields: {},
      role: '',
      notes: '',
      source: 'puawai'
    });
    setDialogOpen(true);
  };

  const filterContacts = () => {
    switch (currentTab) {
      case 0: // All Contacts
        return contacts;
      case 1: // Decision Makers
        return contacts.filter(contact => 
          contact.role === 'decision_maker'
        );
      case 2: // Influencers
        return contacts.filter(contact => 
          contact.role === 'influencer'
        );
      case 3: // Recent Activity
        return contacts
          .filter(contact => contact.lastUpdated)
          .sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated))
          .slice(0, 10);
      default:
        return contacts;
    }
  };

  const getTabStats = () => {
    const filteredContacts = filterContacts();
    return {
      total: filteredContacts.length,
      active: filteredContacts.filter(c => c.status === 'active').length,
      newContacts: filteredContacts.filter(c => {
        const createdDate = new Date(c.created || c.lastUpdated);
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        return createdDate > thirtyDaysAgo;
      }).length
    };
  };

  const handleUpdateRole = async () => {
    if (!selectedContact || !selectedOrg) {
      showSnackbar('Missing required information', 'error');
      return;
    }

    try {
      const effectiveAccountId = selectedContact.accountId || selectedAccount?.id || 'unassigned';
      
      await contactService.updateContactRole(
        selectedOrg,
        effectiveAccountId,
        selectedContact.id,
        {
          role: roleData.role,
          notes: roleData.notes,
          assignedBy: userData.name,
          updatedAt: new Date().toISOString()
        }
      );
      setRoleDialogOpen(false);
      showSnackbar('Role updated successfully');
      fetchContacts(); // Refresh contacts list
    } catch (error) {
      console.error('Error updating contact role:', error);
      showSnackbar('Failed to update contact role', 'error');
    }
  };

  const handleRoleClick = (contact) => {
    setSelectedContact(contact);
    setRoleData({
      role: contact.role || '',
      notes: contact.roleMetadata?.notes || ''
    });
    setRoleDialogOpen(true);
  };

  const [newNote, setNewNote] = useState('');

  const handleAddNote = async () => {
    if (!newNote.trim() || !selectedContact) return;

    try {
      setIsSavingNote(true);
      const noteData = {
        content: newNote.trim(),
        author: userData.name,
        timestamp: new Date().toISOString(),
        accountId: selectedAccount?.id
      };

      await addNote(selectedOrg, selectedContact.id, noteData);
      
      // Refresh notes for this contact
      await fetchContactNotes(selectedContact.id);
      
      // Clear the input
      setNewNote('');
    } catch (err) {
      console.error('Error adding note:', err);
      setError('Failed to add note');
    } finally {
      setIsSavingNote(false);
    }
  };

  const fetchContactNotes = async (contactId) => {
    try {
      setLoadingNotes(true);
      console.log('Fetching notes for contact:', contactId); // Debug log
      const notes = await listNotes(selectedOrg, contactId);
      setContactNotes(prev => ({
        ...prev,
        [contactId]: notes
      }));
    } catch (err) {
      console.error('Error fetching contact notes:', err);
      // Don't set error state for notes fetch failure
    } finally {
      setLoadingNotes(false);
    }
  };

  const handleEditNote = (note) => {
    setEditingNoteId(note.id);
    setEditedNoteContent(note.content);
  };

  const handleCancelEditNote = () => {
    setEditingNoteId(null);
    setEditedNoteContent('');
  };

  const handleSaveNote = async (noteId) => {
    try {
      await updateNote(selectedOrg, selectedContact.id, noteId, {
        content: editedNoteContent
      });
      
      // Refresh notes
      await fetchContactNotes(selectedContact.id);
      
      // Reset edit state
      setEditingNoteId(null);
      setEditedNoteContent('');
    } catch (err) {
      console.error('Error updating note:', err);
      setError('Failed to update note');
    }
  };

  const handleDeleteNote = async (noteId) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        await deleteNote(selectedOrg, selectedContact.id, noteId);
        
        // Refresh notes
        await fetchContactNotes(selectedContact.id);
      } catch (err) {
        console.error('Error deleting note:', err);
        setError('Failed to delete note');
      }
    }
  };

  const handleFormChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setHasFormChanges(true);
  };

  const renderTabContent = () => {
    const filteredContacts = filterContacts();
    const stats = getTabStats();

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            {filteredContacts.length > 0 ? (
              <List>
                {filteredContacts.map((contact) => {
                  const contactNotesList = contactNotes[contact.id]?.notes || [];
                  // Extract email and phone values
                  const primaryEmail = Array.isArray(contact.email) 
                    ? contact.email.find(e => e.primary)?.value || contact.email[0]?.value 
                    : contact.email;
                  const primaryPhone = Array.isArray(contact.phone)
                    ? contact.phone.find(p => p.primary)?.value || contact.phone[0]?.value
                    : contact.phone;

                  return (
                    <ListItem key={contact.id}>
                      <ListItemIcon>
                        {contact.type === 'individual' ? <PersonIcon /> : <BusinessIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {contact.name}
                            {contact.role && (
                              <Chip 
                                size="small" 
                                label={roleOptions.find(opt => opt.value === contact.role)?.label || contact.role}
                                color={roleOptions.find(opt => opt.value === contact.role)?.color || 'default'}
                              />
                            )}
                            {contact.source && (
                              <Chip 
                                size="small" 
                                label={contact.source}
                                color={contact.source === 'pipedrive' ? 'primary' : 'default'}
                                sx={{ ml: 1 }}
                              />
                            )}
                          </Box>
                        }
                        secondary={
                          <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <EmailIcon fontSize="small" />
                              {primaryEmail || 'No email'}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <PhoneIcon fontSize="small" />
                              {primaryPhone || 'No phone'}
                            </Box>
                            {contact.jobTitle && (
                              <Typography variant="body2" color="text.secondary">
                                {contact.jobTitle}
                              </Typography>
                            )}
                            {contactNotesList.length > 0 && (
                              <Box sx={{ mt: 1 }}>
                                <Typography variant="subtitle2" color="primary">
                                  Notes ({contactNotesList.length})
                                </Typography>
                                {contactNotesList.slice(-2).map((note, index) => (
                                  <Box
                                    key={note.id || index}
                                    sx={{
                                      mt: 1,
                                      p: 1,
                                      bgcolor: 'action.hover',
                                      borderRadius: 1,
                                      borderLeft: '4px solid',
                                      borderColor: 'primary.main'
                                    }}
                                  >
                                    <Typography variant="body2" color="text.secondary">
                                      {note.content}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                      Added by {note.author} on {new Date(note.timestamp).toLocaleString()}
                                    </Typography>
                                  </Box>
                                ))}
                                {contactNotesList.length > 2 && (
                                  <Button
                                    size="small"
                                    onClick={() => handleEditContact(contact)}
                                    sx={{ mt: 1 }}
                                  >
                                    View all {contactNotesList.length} notes
                                  </Button>
                                )}
                              </Box>
                            )}
                            {contact.lastUpdated && (
                              <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                                Last Updated: {new Date(contact.lastUpdated).toLocaleDateString()}
                              </Typography>
                            )}
                            {contact.lastSynced && contact.source === 'pipedrive' && (
                              <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                                Last Synced: {new Date(contact.lastSynced).toLocaleDateString()}
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title="Edit">
                          <IconButton edge="end" sx={{ mr: 1 }} onClick={() => handleEditContact(contact)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton edge="end" onClick={() => handleDeleteClick(contact)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <Typography color="text.secondary">No contacts found in this category</Typography>
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Statistics</Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Total Contacts" 
                  secondary={stats.total}
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Active Contacts" 
                  secondary={stats.active}
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="New (Last 30 Days)" 
                  secondary={stats.newContacts}
                />
              </ListItem>
            </List>
          </Paper>

          {/* Quick Actions */}
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Quick Actions</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button variant="outlined" startIcon={<EmailIcon />}>
                Send Email
              </Button>
              <Button variant="outlined" startIcon={<PhoneIcon />}>
                Log Call
              </Button>
              <Button variant="outlined" startIcon={<AddIcon />}>
                Create Task
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const renderContactDialog = () => {
    const dialogTitle = selectedContact ? 'Edit Contact' : 'Add Contact';
    const isPipedriveContact = selectedContact?.source === 'pipedrive';
    const currentNotes = selectedContact ? (contactNotes[selectedContact.id]?.notes || []) : [];

    return (
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {isPipedriveContact && (
              <Alert severity="info" sx={{ mb: 2 }}>
                This contact is synced with Pipedrive. Contact details are read-only, but you can manage role assignments and add notes.
              </Alert>
            )}

            {/* Editable Sections First */}
            <Paper elevation={0} sx={{ 
              p: 2, 
              bgcolor: 'background.default',
              border: '1px solid',
              borderColor: 'primary.main',
              borderRadius: 1
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'medium' }}>
                  Role Information
                </Typography>
                <Chip 
                  label="Editable"
                  color="success"
                  size="small"
                  icon={<EditIcon />}
                  variant="outlined"
                />
              </Box>

              {/* Role Selection */}
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom color="text.secondary">
                  Contact Role
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {roleOptions.map(option => (
                    <Chip
                      key={option.value}
                      label={option.label}
                      color={formData.role === option.value ? option.color : 'default'}
                      onClick={() => setFormData({ ...formData, role: option.value })}
                      sx={{ 
                        cursor: 'pointer',
                        border: formData.role === option.value ? 2 : 1,
                        borderColor: formData.role === option.value ? 'primary.main' : 'divider'
                      }}
                    />
                  ))}
                </Box>
              </Box>

              {/* Role Metadata */}
              {selectedContact?.roleMetadata && (
                <Typography variant="caption" color="text.secondary">
                  Last updated by {selectedContact.roleMetadata.assignedBy} on{' '}
                  {new Date(selectedContact.roleMetadata.assignedDate).toLocaleDateString()}
                </Typography>
              )}
            </Paper>

            {/* Notes Section */}
            <Paper elevation={0} sx={{ 
              p: 2, 
              bgcolor: 'background.default',
              border: '1px solid',
              borderColor: 'primary.main',
              borderRadius: 1
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'medium' }}>
                  Contact Notes
                </Typography>
                <Chip 
                  label="Editable"
                  color="success"
                  size="small"
                  icon={<EditIcon />}
                  variant="outlined"
                />
              </Box>

              {/* Notes List */}
              <Box sx={{ 
                mb: 2, 
                maxHeight: 200, 
                overflowY: 'auto',
                bgcolor: 'background.paper',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                p: currentNotes.length > 0 ? 1 : 0
              }}>
                {loadingNotes ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                ) : currentNotes.length > 0 ? (
                  currentNotes.map((note, index) => (
                    <Box
                      key={note.id || index}
                      sx={{
                        mb: index < currentNotes.length - 1 ? 1 : 0,
                        p: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'divider',
                        position: 'relative'
                      }}
                    >
                      <Typography variant="body2">{note.content}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Added by {note.author} on {new Date(note.timestamp).toLocaleString()}
                      </Typography>
                      <Box 
                        sx={{ 
                          position: 'absolute',
                          top: 4,
                          right: 4,
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          '.MuiBox-root:hover > &': {
                            opacity: 1
                          }
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleEditNote(note)}
                          sx={{ mr: 1 }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteNote(note.id)}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ textAlign: 'center', py: 2 }}>
                    <Typography color="text.secondary">No notes yet</Typography>
                  </Box>
                )}
              </Box>

              {/* Add New Note */}
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  label="Add a note"
                  multiline
                  rows={2}
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  fullWidth
                  sx={{
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.default'
                    }
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleAddNote}
                  disabled={!newNote.trim() || loadingNotes}
                  sx={{ minWidth: 100 }}
                >
                  Add Note
                </Button>
              </Box>
            </Paper>

            {/* Read-only Contact Information */}
            <Paper elevation={0} sx={{ 
              p: 2, 
              bgcolor: isPipedriveContact ? 'action.hover' : 'background.default',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              opacity: isPipedriveContact ? 0.9 : 1
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 'medium' }}>
                  Contact Details
                </Typography>
                {isPipedriveContact && (
                  <Chip 
                    label="Read Only"
                    color="default"
                    size="small"
                    icon={<LockIcon />}
                    variant="outlined"
                  />
                )}
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    value={formData.firstName}
                    onChange={(e) => handleFormChange('firstName', e.target.value)}
                    fullWidth
                    required
                    InputProps={{
                      readOnly: isPipedriveContact,
                      sx: { bgcolor: isPipedriveContact ? 'background.paper' : 'inherit' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    value={formData.lastName}
                    onChange={(e) => handleFormChange('lastName', e.target.value)}
                    fullWidth
                    InputProps={{
                      readOnly: isPipedriveContact,
                      sx: { bgcolor: isPipedriveContact ? 'background.paper' : 'inherit' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    type="email"
                    value={formData.email[0]?.value || ''}
                    onChange={(e) => handleFormChange('email', [{
                      value: e.target.value,
                      primary: true,
                      label: 'work'
                    }])}
                    fullWidth
                    required
                    InputProps={{
                      readOnly: isPipedriveContact,
                      sx: { bgcolor: isPipedriveContact ? 'background.paper' : 'inherit' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Phone"
                    value={formData.phone[0]?.value || ''}
                    onChange={(e) => handleFormChange('phone', [{
                      value: e.target.value,
                      primary: true,
                      label: 'work'
                    }])}
                    fullWidth
                    InputProps={{
                      readOnly: isPipedriveContact,
                      sx: { bgcolor: isPipedriveContact ? 'background.paper' : 'inherit' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Job Title"
                    value={formData.jobTitle}
                    onChange={(e) => handleFormChange('jobTitle', e.target.value)}
                    fullWidth
                    InputProps={{
                      readOnly: isPipedriveContact,
                      sx: { bgcolor: isPipedriveContact ? 'background.paper' : 'inherit' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderAccountDropdown()}
                </Grid>
              </Grid>
            </Paper>

            {/* Role Notes */}
            {formData.role && (
              <TextField
                label="Role Notes"
                multiline
                rows={3}
                value={formData.notes}
                onChange={(e) => {
                  setFormData(prev => ({
                    ...prev,
                    notes: e.target.value
                  }));
                  setHasFormChanges(true);
                }}
                fullWidth
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={loading || (!hasFormChanges && !selectedContact)}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderRoleDialog = () => (
    <Dialog open={roleDialogOpen} onClose={() => setRoleDialogOpen(false)}>
      <DialogTitle>Update Contact Role</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={roleData.role}
              onChange={(e) => setRoleData({ ...roleData, role: e.target.value })}
            >
              {roleOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Notes"
            multiline
            rows={3}
            value={roleData.notes}
            onChange={(e) => setRoleData({ ...roleData, notes: e.target.value })}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setRoleDialogOpen(false)}>Cancel</Button>
        <Button 
          onClick={handleUpdateRole} 
          variant="contained"
          disabled={!roleData.role}
        >
          Update Role
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        Delete Contact
      </DialogTitle>
      <DialogContent>
        <Typography id="delete-dialog-description">
          Are you sure you want to delete {contactToDelete?.name}? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => setDeleteDialogOpen(false)} 
          disabled={loading}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleDeleteConfirm} 
          color="error" 
          variant="contained"
          disabled={loading}
        >
          {loading ? 'Deleting...' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderAccountDropdown = () => {
    // Get isPipedriveContact status for the current context
    const isReadOnly = selectedContact?.source === 'pipedrive';
    const isLocalContact = !selectedContact?.source || selectedContact?.source === 'puawai';
    
    console.debug('Rendering account dropdown:', {
      accounts,
      isReadOnly,
      isLocalContact,
      selectedContactSource: selectedContact?.source,
      formDataAccountId: formData.accountId
    });

    // Filter accounts based on contact source
    const filteredAccounts = accounts.filter(account => {
      if (isLocalContact) {
        // Local contacts can be assigned to any account
        return !account.source || account.source === 'puawai';
      }
      // Pipedrive contacts can only be assigned to Pipedrive accounts
      return account.source === 'pipedrive';
    });

    console.debug('Filtered accounts:', {
      filteredAccounts,
      count: filteredAccounts.length,
      currentAccountId: formData.accountId
    });

    return (
      <FormControl fullWidth>
        <Autocomplete
          options={filteredAccounts}
          getOptionLabel={(option) => option.name || 'Unnamed Account'}
          value={filteredAccounts.find(account => account.id === formData.accountId) || null}
          onChange={(event, newValue) => {
            console.debug('Account selected:', {
              newValue,
              previousAccountId: formData.accountId
            });
            setFormData(prev => ({
              ...prev,
              accountId: newValue?.id || ''
            }));
            setHasFormChanges(true);
          }}
          disabled={isReadOnly || loadingAccounts}
          loading={loadingAccounts}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Account"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <BusinessIcon color="action" sx={{ mr: 1 }} />
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {loadingAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormHelperText>
          {loadingAccounts ? 'Loading accounts...' : 
           filteredAccounts.length === 0 ? 'No accounts available' : 
           `${filteredAccounts.length} account(s) available`}
        </FormHelperText>
      </FormControl>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ mb: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              {selectedAccount ? `Contacts - ${selectedAccount.name}` : 'All Company Contacts'}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAdd}
              >
                Add Contact
              </Button>
            </Box>
          </Box>
          <Tabs value={currentTab} onChange={(e, val) => setCurrentTab(val)}>
            <Tab label={`All Contacts (${contacts.length})`} />
            <Tab label={`Decision Makers (${contacts.filter(c => c.role === 'decision_maker').length})`} />
            <Tab label={`Influencers (${contacts.filter(c => c.role === 'influencer').length})`} />
            <Tab label="Recent Activity" />
          </Tabs>
        </Box>
      </Paper>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      ) : (
        renderTabContent()
      )}

      {renderContactDialog()}
      {renderRoleDialog()}
      {renderDeleteDialog()}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactManagement; 