import React from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import { 
  CalendarMonth as EventIcon,
  QueryStats as StrategyIcon,
  Assignment as RFPIcon,
  Group as TeamIcon,
  Warning as WarningIcon
} from '@mui/icons-material';

const QuickActions = React.memo(({ 
  bidStrategyCanvases, 
  rfpQualificationCanvases, 
  teamMembers, 
  selectedBid,
  onActionClick,
  onTabChange
}) => {
  const renderActions = () => {
    const actions = [];

    // Task and Timeline actions
    actions.push(
      <Button
        key="addTask"
        variant="outlined"
        color="primary"
        onClick={() => onTabChange(3)}
        startIcon={<EventIcon />}
        sx={{ mr: 1, mb: 1 }}
      >
        Tasks and Timeline
      </Button>
    );

    // Bid Strategy Canvas actions
    if (!bidStrategyCanvases.length) {
      actions.push(
        <Button
          key="createBidStrategy"
          variant="outlined"
          color="primary"
          onClick={() => onActionClick('bidStrategy')}
          startIcon={<StrategyIcon />}
          sx={{ mr: 1, mb: 1 }}
        >
          Create Bid Strategy Canvas
        </Button>
      );
    } else {
      actions.push(
        <Button
          key="updateBidStrategy"
          variant="outlined"
          color="primary"
          onClick={() => onActionClick('bidStrategy', bidStrategyCanvases[0].id)}
          startIcon={<StrategyIcon />}
          sx={{ mr: 1, mb: 1 }}
        >
          Update Bid Strategy Canvas
        </Button>
      );
    }

    // RFP Qualification Canvas actions
    if (!rfpQualificationCanvases.length) {
      actions.push(
        <Button
          key="createRFPQualification"
          variant="outlined"
          color="primary"
          onClick={() => onActionClick('rfpQualification')}
          startIcon={<RFPIcon />}
          sx={{ mb: 1 }}
        >
          Create RFP Qualification Canvas
        </Button>
      );
    } else {
      actions.push(
        <Button
          key="updateRFPQualification"
          variant="outlined"
          color="primary"
          onClick={() => onActionClick('rfpQualification', rfpQualificationCanvases[0].id)}
          startIcon={<RFPIcon />}
          sx={{ mb: 1 }}
        >
          Update RFP Qualification Canvas
        </Button>
      );
    }

    // Team actions
    if (!teamMembers.length) {
      actions.push(
        <Button
          key="addTeamMembers"
          variant="outlined"
          color="primary"
          onClick={() => onTabChange(2)}
          startIcon={<TeamIcon />}
          sx={{ mr: 1, mb: 1 }}
        >
          Add Team Members
        </Button>
      );
    }

    // Task review actions
    const overdueTasks = selectedBid.tasks ? selectedBid.tasks.filter(task => 
      new Date(task.dueDate) < new Date() && task.status !== 'completed'
    ) : [];

    if (overdueTasks.length > 0) {
      actions.push(
        <Button
          key="reviewOverdueTasks"
          variant="contained"
          color="error"
          onClick={() => onTabChange(3)}
          startIcon={<WarningIcon />}
          sx={{ mb: 1 }}
        >
          Review {overdueTasks.length} Overdue Tasks
        </Button>
      );
    }

    return actions;
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>Quick Actions</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {renderActions()}
      </Box>
    </Paper>
  );
});

export default QuickActions; 