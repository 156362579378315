import { useCallback } from 'react';
import { useTheme } from '@mui/material';

export const useEventStyles = () => {
  const theme = useTheme();

  const getEventStyle = useCallback((event) => {
    const baseStyle = {
      borderRadius: '4px',
      padding: '4px 8px',
      height: 'auto',
      fontSize: '0.875rem',
      fontWeight: 500,
      border: 'none'
    };

    if (event.type === 'milestone') return { 
      ...baseStyle,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}40`
    };

    if (event.type === 'non-task') return { 
      ...baseStyle,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}40`
    };

    switch (event.status) {
      case 'completed':
        return { 
          ...baseStyle,
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.secondary.main,
          boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.main}40`
        };
      case 'in_progress':
        return { 
          ...baseStyle,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.secondary.main,
          boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.main}40`
        };
      case 'not_started':
        return { 
          ...baseStyle,
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.secondary.main,
          boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.main}40`
        };
      case 'overdue':
        return { 
          ...baseStyle,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.error.main,
          boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}40`
        };
      default:
        return { 
          ...baseStyle,
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.secondary.main,
          boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.main}40`
        };
    }
  }, [theme]);

  return { getEventStyle };
}; 