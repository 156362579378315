import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme
} from '@mui/material';
import BaseCanvas from './BaseCanvas';
import CampaignIcon from '@mui/icons-material/Campaign';

const StoryBrandCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  const canvasId = React.useMemo(() => canvas?.id || crypto.randomUUID(), [canvas]);

  const [canvasData, setCanvasData] = useState(canvas || {
    id: canvasId,
    type: 'storybrand',
    orgId: selectedOrg.id || selectedOrg,
    character: '',
    problemExternal: '',
    problemInternal: '',
    guideEmpathy: '',
    guideAuthority: '',
    plan: '',
    callToAction: '',
    success: '',
    createdAt: new Date().toISOString(),
    createdBy: user.id || user
  });
  const theme = useTheme();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
      setHasUnsavedChanges(false);
    }
  }, [canvas]);

  const handleFieldChange = (field, value) => {
    setCanvasData(prev => ({ ...prev, [field]: value }));
    setHasUnsavedChanges(true);
  };

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmedClose = () => {
    setShowUnsavedDialog(false);
    onClose();
  };

  const handleSave = async (updatedData) => {
    try {
      if (!selectedOrg?.id && !selectedOrg) {
        throw new Error('No organization ID provided');
      }
      
      const orgId = String(selectedOrg?.id || selectedOrg);
      const id = String(canvasId);

      if (!orgId || !id) {
        throw new Error('orgId and id are required for updating a canvas');
      }

      const dataToSave = {
        ...canvasData,
        ...updatedData,
        id,
        orgId,
        type: 'storybrand',
        createdAt: canvasData.createdAt || new Date().toISOString(),
        createdBy: canvasData.createdBy || user.id || user,
        updatedAt: new Date().toISOString(),
        updatedBy: user.id || user
      };

      const savedCanvas = await onSave(orgId, id, dataToSave);
      
      if (savedCanvas) {
        setCanvasData(savedCanvas);
        setHasUnsavedChanges(false);
        setShowUnsavedDialog(false);
        
        if (updatedData.status === 'completed' || updatedData._closeAfterSave) {
          onClose();
        }
      }
      
      return true;
    } catch (error) {
      console.error('Error saving StoryBrand canvas:', error);
      console.error('Save attempted with:', { 
        id: canvasId, 
        orgId: selectedOrg?.id || selectedOrg,
        data: updatedData 
      });
      return false;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // Define sections
  const sections = [
    { 
      field: 'character', 
      title: 'Step 1: Identify the Character (Customer)', 
      width: 12,
      instruction: 'Define who your main character (customer) is and what they want.',
      explanation: 'In the StoryBrand framework, your customer is the hero of the story, not your brand.',
      rows: 4
    },
    { 
      field: 'problemExternal', 
      title: 'Step 2: External Problem', 
      width: 12,
      instruction: 'What is the external problem your customer faces?',
      explanation: 'This is the tangible, physical problem that your customer experiences.',
      rows: 4
    },
    { 
      field: 'problemInternal', 
      title: 'Step 3: Internal Problem', 
      width: 12,
      instruction: 'What internal frustration does your customer feel about the external problem?',
      explanation: 'This is the emotional or psychological impact of the external problem.',
      rows: 4
    },
    { 
      field: 'guideEmpathy', 
      title: 'Step 4: Guide Empathy', 
      width: 12,
      instruction: 'How do you understand and relate to your customer\'s problems?',
      explanation: 'Show that you understand their pain points and frustrations.',
      rows: 4
    },
    { 
      field: 'guideAuthority', 
      title: 'Step 5: Guide Authority', 
      width: 12,
      instruction: 'What makes you qualified to help solve their problem?',
      explanation: 'Demonstrate your expertise and credibility.',
      rows: 4
    },
    { 
      field: 'plan', 
      title: 'Step 6: The Plan', 
      width: 12,
      instruction: 'What is your step-by-step plan to help them solve their problem?',
      explanation: 'Break down your solution into clear, actionable steps.',
      rows: 4
    },
    { 
      field: 'callToAction', 
      title: 'Step 7: Call to Action', 
      width: 12,
      instruction: 'What specific action do you want them to take?',
      explanation: 'Make it clear and compelling what they should do next.',
      rows: 4
    },
    { 
      field: 'success', 
      title: 'Step 8: Success', 
      width: 12,
      instruction: 'What success will they experience by working with you?',
      explanation: 'Paint a picture of their transformed life after using your solution.',
      rows: 4
    }
  ];

  // Define tooltips
  const tooltips = {
    character: `Your Customer (Hero):
• Who are they specifically?
• What do they want to achieve?
• What's their current situation?
• What are their aspirations?`,

    problemExternal: `External Problem:
• What tangible, physical problem do they face?
• What's the measurable impact?
• What are the concrete obstacles?
• How does it affect their business/life?`,

    problemInternal: `Internal Problem:
• How does the external problem make them feel?
• What frustrations do they experience?
• What keeps them up at night?
• What emotions drive their decisions?`,

    guideEmpathy: `Show Empathy:
• How do you understand their struggle?
• What similar experiences can you share?
• How do you relate to their pain points?
• What insights do you have about their situation?`,

    guideAuthority: `Establish Authority:
• What makes you qualified to help?
• What relevant experience do you have?
• What results have you achieved?
• What credentials support your expertise?`,

    plan: `The Plan:
• What are the specific steps to success?
• How will you help them achieve their goal?
• What's the implementation process?
• What support do you provide?`,

    callToAction: `Call to Action:
• What specific action should they take?
• Why should they act now?
• What's the first step?
• What will they receive?`,

    success: `Success Outcome:
• What transformation will they experience?
• What specific results can they expect?
• How will their life/business improve?
• What metrics will change?`
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CampaignIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>StoryBrand Canvas</Typography>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        <BaseCanvas
          user={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={handleCloseAttempt}
          onUpdate={(field, value) => handleFieldChange(field, value)}
          onSave={handleSave}
          canvasType="StoryBrand Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={CampaignIcon}
        />
      </Box>

      <Dialog open={showUnsavedDialog} onClose={() => setShowUnsavedDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. What would you like to do?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedDialog(false)}>
            Continue Editing
          </Button>
          <Button onClick={handleConfirmedClose} color="error">
            Discard Changes
          </Button>
          <Button 
            onClick={async () => {
              const success = await handleSave({
                ...canvasData,
                _closeAfterSave: true
              });
              if (success) {
                setShowUnsavedDialog(false);
                onClose();
              }
            }} 
            variant="contained" 
            color="primary"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StoryBrandCanvas;