import React from 'react';
import { Button, Typography, Container, Grid, Box, Paper, AppBar, Toolbar, Link, Chip, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import StrategyIcon from '@mui/icons-material/TrendingUp';
import BidIcon from '@mui/icons-material/Gavel';
import TeamIcon from '@mui/icons-material/Groups';
import { useCurrency } from '../../hooks/useCurrency';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link as RouterLink } from 'react-router-dom';
import { Grow } from '@mui/material';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PageHeader from '../../components/PageHeader/PageHeader';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';

const StyledHeroTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  padding: theme.spacing(1, 0),
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& i': {
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
  },
}));

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(12, 0),
}));

const features = [
  {
    icon: <StrategyIcon sx={{ fontSize: 60 }} />,
    title: "Organisation Strategy",
    description: "Build and evolve your organization's strategy with AI-powered tools that help you develop clear vision, mission, and strategic objectives. Our intelligent assistant guides you through proven frameworks and methodologies."
  },
  {
    icon: <BidIcon sx={{ fontSize: 60 }} />,
    title: "Brand Development",
    description: "Create and maintain a compelling brand identity with AI-assisted brand strategy tools. From brand story to visual identity guidelines, develop a consistent and powerful brand presence."
  },
  {
    icon: <TeamIcon sx={{ fontSize: 60 }} />,
    title: "Product & Service Catalogue",
    description: "Design and manage your product and service offerings with intelligent assistance. Our AI helps you optimize descriptions, pricing strategies, and market positioning for maximum impact."
  },
  {
    icon: <StrategyIcon sx={{ fontSize: 60 }} />,
    title: "Collateral Generation",
    description: "Generate professional marketing and sales collateral with AI assistance. Create compelling presentations, proposals, and marketing materials that align with your brand and messaging."
  },
  {
    icon: <BidIcon sx={{ fontSize: 60 }} />,
    title: "Fundraising Intelligence",
    description: "Streamline your fundraising efforts with AI-powered insights. Track opportunities, generate investor materials, and manage relationships with smart automation and strategic guidance."
  },
  {
    icon: <TeamIcon sx={{ fontSize: 60 }} />,
    title: "CRM & Bid Management",
    description: "Manage customer relationships and bid processes with integrated AI assistance. Track opportunities, automate responses, and increase win rates with intelligent bid management tools."
  }
];

const PlanPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[10],
  },
}));

const PriceTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

function FeatureCard({ icon, title, description }) {
  return (
    <Paper elevation={3} sx={{ 
      p: 3, 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      textAlign: 'center',
      minHeight: 400, // Set a minimum height for all cards
    }}>
      <Box sx={{ mb: 2, color: 'primary.main' }}>
        {icon}
      </Box>
      <Typography variant="h5" component="h3" gutterBottom>
        {title}
      </Typography>
      <Typography sx={{ 
        flexGrow: 1, 
        overflow: 'auto', 
        display: '-webkit-box',
        WebkitLineClamp: 8,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
      }}>
        {description}
      </Typography>
    </Paper>
  );
}

function AppScreenshots() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    cssEase: "linear",
    fade: true,
  };

  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto', mt: 4 }}>
      <Slider {...settings}>
        <div>
          <img src="/images/appscreenshot1.png" alt="App Screenshot 1" style={{ width: '100%', height: 'auto' }} />
        </div>
        <div>
          <img src="/images/appscreenshot2.png" alt="App Screenshot 2" style={{ width: '100%', height: 'auto' }} />
        </div>
        <div>
          <img src="/images/appscreenshot3.png" alt="App Screenshot 3" style={{ width: '100%', height: 'auto' }} />
        </div>
        {/* Add more screenshots as needed */}
      </Slider>
    </Box>
  );
}

const FeatureItem = ({ text, isAI }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
    <CheckCircleOutlineIcon sx={{ mr: 1, color: isAI ? 'secondary.main' : 'primary.main' }} />
    <Typography variant="body1">{text}</Typography>
  </Box>
);

function LandingPage({ onLoginClick }) {
  const { localPrice, localCurrency, formatPrice } = useCurrency();
  const theme = useTheme();
  const navigate = useNavigate();

  const plans = [
    {
      title: "Standard",
      monthlyPrice: 19.99,
      annualPrice: 199.99,
      features: [
        "Comprehensive growth strategy tools",
        "Strategic canvases for visualisation",
        "Basic bid management and RFP handling",
        "Team collaboration features",
        "Task management for projects",
        "Standard analytics and reporting"
      ],
      isAI: false,
    },
    {
      title: "Intelligence+",
      monthlyPrice: 39.99,
      annualPrice: 399.99,
      features: [
        "All Standard features, plus:",
        "AI-powered growth strategy assistant",
        "Generative AI copilot for strategic planning",
        "Advanced AI-assisted bid management",
        "Intelligent RFP analysis and response",
        "Predictive analytics for bid success",
        "AI-driven task prioritization"
      ],
      isAI: true,
      comingSoon: false,
    }
  ];

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      fontFamily: theme.typography.fontFamily,
    }}>
      <PageHeader onLoginClick={onLoginClick} />

      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: 'url("/images/hero.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          color: 'common.white',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Container sx={{ position: 'relative', zIndex: 1, mt: 8 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" component="h1" gutterBottom>
                Grow Your Business with Puāwai
              </Typography>
              <Typography variant="h4" color="primary" gutterBottom>
                Start Your 30-Day Free Trial Today!
              </Typography>
              <Typography variant="h5" paragraph>
                Empower your business growth journey with our comprehensive suite of tools.
              </Typography>
              <Box mt={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => onLoginClick('signup')}
                  sx={{ mr: 2, fontSize: '1.2rem', padding: '10px 30px' }}
                >
                  Start Free Trial
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  href="#features"
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <SectionBox id="features" sx={{ backgroundColor: 'background.default' }}>
        <Container>
          <Typography variant="h2" align="center" gutterBottom>
            Our Features
          </Typography>
          <Grid container spacing={4} mt={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Grow in={true} timeout={1000 + index * 500}>
                  <div style={{ height: '100%' }}> {/* Ensure full height */}
                    <FeatureCard
                      icon={feature.icon}
                      title={feature.title}
                      description={feature.description}
                    />
                  </div>
                </Grow>
              </Grid>
            ))}
          </Grid>
        </Container>
      </SectionBox>

      {/* App Screenshots Section */}
      <SectionBox sx={{ backgroundColor: 'background.paper' }}>
        <Container>
          <Typography variant="h2" align="center" gutterBottom>
            See Puāwai Platform in Action
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Take a tour of our intuitive interface and powerful features
          </Typography>
          <AppScreenshots />
        </Container>
      </SectionBox>

      {/* Updated Pricing Section */}
      <SectionBox sx={{ backgroundColor: 'background.default' }}>
        <Container>
          <Typography variant="h2" align="center" gutterBottom>
            Choose Your Growth Path
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Scalable solutions for businesses of all sizes
          </Typography>
          <Grid container spacing={4} justifyContent="center" mt={4}>
            {plans.map((plan, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Paper 
                  elevation={3} 
                  sx={{ 
                    p: 4, 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: 8,
                    },
                  }}
                >
                  {plan.isAI && (
                    <Box 
                      sx={{ 
                        position: 'absolute', 
                        top: 20, 
                        right: -35, 
                        transform: 'rotate(45deg)',
                        backgroundColor: 'secondary.main',
                        color: 'white',
                        padding: '5px 40px',
                      }}
                    >
                      AI-Powered
                    </Box>
                  )}
                  <Typography variant="h3" component="h3" gutterBottom align="center">
                    {plan.title}
                  </Typography>
                  {plan.comingSoon && (
                    <Chip 
                      label="Coming Soon" 
                      color="primary" 
                      icon={<StarIcon />}
                      sx={{ alignSelf: 'center', mb: 2 }}
                    />
                  )}
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h4" align="center" color="primary.main">
                      ${plan.monthlyPrice}
                      <Typography component="span" variant="h6" color="text.secondary">
                        /month
                      </Typography>
                    </Typography>
                    <Typography variant="body2" align="center" color="text.secondary">
                      or ${(plan.annualPrice / 12).toFixed(2)}/month billed annually
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ flexGrow: 1 }}>
                    {plan.features.map((feature, idx) => (
                      <FeatureItem key={idx} text={feature} isAI={plan.isAI} />
                    ))}
                  </Box>
                  <Button 
                    variant="contained" 
                    color={plan.isAI ? "secondary" : "primary"}
                    size="large" 
                    onClick={() => onLoginClick('signup')}
                    sx={{ 
                      mt: 3, 
                      py: 1.5,
                      fontSize: '1.1rem',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                    fullWidth
                    disabled={plan.comingSoon}
                  >
                    {plan.comingSoon ? 'Coming Soon' : 'Start Free Trial'}
                  </Button>
                  {!plan.comingSoon && (
                    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, textAlign: 'center' }}>
                      No credit card required for 30-day trial
                    </Typography>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </SectionBox>

      <Footer />

      <Fab 
        color="secondary" 
        aria-label="scroll to top" 
        sx={{ 
          position: 'fixed', 
          bottom: 16, 
          right: 16,
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-4px)',
          }
        }}
        onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
      >
        <ArrowUpwardIcon />
      </Fab>
    </Box>
  );
}

export default LandingPage;