import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import { Event as CalendarIcon } from '@mui/icons-material';
import { useEventStyles } from './useEventStyles';
import moment from 'moment';

const generateGoogleUrl = (event) => {
  // Handle assigned users text differently for tasks vs events
  const assignedText = event.type === 'task'
    ? event.assignedToName || 'Unassigned'
    : (event.assignedUsers?.map(u => u.name).join(', ') || 'Unassigned');

  const details = `${event.description || ''}\n\nAssigned to: ${assignedText}`;
  
  // Get attendees based on event type
  const attendees = event.type === 'task'
    ? (event.assignedToEmail ? [event.assignedToEmail] : [])
    : (event.assignedUsers?.map(u => u.email).filter(Boolean) || []);
  
  const params = new URLSearchParams({
    action: 'TEMPLATE',
    text: event.title,
    details,
    dates: `${moment(event.start).format('YYYYMMDDTHHmmss')}/${moment(event.end || event.start).format('YYYYMMDDTHHmmss')}`,
    ctz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    add: attendees.join(',')
  });
  return `https://calendar.google.com/calendar/render?${params}`;
};

const generateOffice365Url = (event) => {
  // Handle assigned users text differently for tasks vs events
  const assignedText = event.type === 'task'
    ? event.assignedToName || 'Unassigned'
    : (event.assignedUsers?.map(u => u.name).join(', ') || 'Unassigned');

  // Get attendees based on event type
  const attendees = event.type === 'task'
    ? (event.assignedToEmail ? [event.assignedToEmail] : [])
    : (event.assignedUsers?.map(u => u.email).filter(Boolean) || []);
  
  const params = new URLSearchParams({
    subject: event.title,
    body: `${event.description || ''}\n\nAssigned to: ${assignedText}`,
    startdt: moment(event.start).format('YYYY-MM-DDTHH:mm:ss'),
    enddt: moment(event.end || event.start).format('YYYY-MM-DDTHH:mm:ss'),
    to: attendees.join(';')
  });
  return `https://outlook.office.com/calendar/0/deeplink/compose?${params}`;
};

const CalendarMenu = ({ event, anchorEl, onClose }) => {
  const handleGoogleCalendar = () => {
    window.open(generateGoogleUrl(event), '_blank');
    onClose();
  };

  const handleOffice365 = () => {
    window.open(generateOffice365Url(event), '_blank');
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={handleGoogleCalendar}>
        Add to Google Calendar
      </MenuItem>
      <MenuItem onClick={handleOffice365}>
        Add to Office 365
      </MenuItem>
    </Menu>
  );
};

// Compact version for calendar view
const CompactEventComponent = React.memo(({ 
  event,
  onSelectEvent,
  getStatusIcon
}) => {
  const { getEventStyle } = useEventStyles();
  const eventStyle = useMemo(() => getEventStyle(event), [event, getEventStyle]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = useCallback((e) => {
    if (onSelectEvent) {
      onSelectEvent(event);
    }
  }, [event, onSelectEvent]);

  const handleCalendarClick = useCallback((e) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  // Create tooltip content with full details
  const tooltipContent = (
    <Box sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
      <Typography variant="body2">
        {event.allDay ? 'All Day' : moment(event.start).format('HH:mm')}
      </Typography>
      {event.assignedUsers?.length > 0 && (
        <Typography variant="body2">
          Assigned: {event.assignedUsers.map(u => u.name).join(', ')}
        </Typography>
      )}
      {event.description && (
        <Typography variant="body2">{event.description}</Typography>
      )}
    </Box>
  );

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ position: 'relative' }}
    >
      <Tooltip title={tooltipContent} arrow>
        <Box
          onClick={handleClick}
          sx={{
            ...eventStyle,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            minHeight: '20px',
            fontSize: '0.8rem',
            width: '100%'
          }}
        >
          {getStatusIcon(event)}
          <Typography 
            variant="body2" 
            component="span"
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
              fontSize: 'inherit',
              lineHeight: 1.2
            }}
          >
            {event.title}
          </Typography>
          {isHovered && (
            <IconButton
              size="small"
              onClick={handleCalendarClick}
              sx={{ 
                padding: '2px',
                opacity: 0.7,
                '&:hover': { opacity: 1 }
              }}
            >
              <CalendarIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Tooltip>
      <CalendarMenu
        event={event}
        anchorEl={menuAnchor}
        onClose={handleMenuClose}
      />
    </Box>
  );
});

CompactEventComponent.displayName = 'CompactEventComponent';

// Full version for agenda view
export const EventComponent = React.memo(({ 
  event,
  onSelectEvent,
  getStatusIcon,
  view
}) => {
  // Use compact version for month/week/day views
  if (view !== 'agenda') {
    return (
      <CompactEventComponent
        event={event}
        onSelectEvent={onSelectEvent}
        getStatusIcon={getStatusIcon}
      />
    );
  }

  const [isHovered, setIsHovered] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const { getEventStyle } = useEventStyles();
  const eventStyle = useMemo(() => getEventStyle(event), [event, getEventStyle]);

  const getAssignedUsersString = (event) => {
    if (event.type === 'task') {
      return event.assignedToName || 'Unassigned';
    }
    if (!event.assignedUsers || event.assignedUsers.length === 0) {
      return 'Unassigned';
    }
    return event.assignedUsers
      .map(user => {
        if (!user) return 'Unknown User';
        return user.name || user.displayName || user.email || 'Unknown User';
      })
      .join(', ');
  };

  const getEventTimeString = (event) => {
    if (event.allDay) {
      return 'All Day';
    }
    if (event.type === 'task') {
      return `Due: ${moment(event.start).format('DD MMM, HH:mm')}`;
    }
    return `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`;
  };

  const getEventTypeLabel = (type) => {
    switch (type) {
      case 'milestone':
        return 'MILESTONE';
      case 'task':
        return 'TASK';
      case 'non-task':
        return 'KEY DATE';
      default:
        return type.toUpperCase();
    }
  };

  const handleClick = useCallback((e) => {
    if (onSelectEvent) {
      onSelectEvent(event);
    }
  }, [event, onSelectEvent]);

  const handleCalendarClick = useCallback((e) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  return (
    <Box
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        ...eventStyle,
        cursor: 'pointer',
        transition: 'opacity 0.2s ease-in-out',
        opacity: isHovered ? 0.8 : 1,
        padding: '4px 8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2px'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
          {getStatusIcon(event)}
          <Typography variant="body2" noWrap>
            {event.title}
          </Typography>
        </Box>
        {isHovered && (
          <IconButton
            size="small"
            onClick={handleCalendarClick}
            sx={{ 
              padding: '2px',
              opacity: 0.7,
              '&:hover': { opacity: 1 }
            }}
          >
            <CalendarIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Typography variant="caption" sx={{ display: 'block', opacity: 0.7 }}>
        {getEventTimeString(event)}
      </Typography>
      <Typography variant="caption" sx={{ display: 'block', opacity: 0.7 }}>
        {getAssignedUsersString(event)}
      </Typography>
      <Typography variant="caption" sx={{ 
        display: 'block', 
        opacity: 0.7,
        color: event.type === 'task' ? '#4caf50' : 'inherit',
        fontWeight: event.type === 'task' ? 'bold' : 'normal'
      }}>
        {getEventTypeLabel(event.type)}
      </Typography>
      <CalendarMenu
        event={event}
        anchorEl={menuAnchor}
        onClose={handleMenuClose}
      />
    </Box>
  );
});

EventComponent.displayName = 'EventComponent'; 