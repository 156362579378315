import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import { styled } from '@mui/material/styles';

// Helper functions for timezone handling
const convertToLocalTime = (date) => {
  if (!date) return '';
  // The input is in UTC, convert to local time
  const localTime = moment(date).local().format('YYYY-MM-DDTHH:mm');
  console.log('Converting to local time:', {
    input: date,
    output: localTime
  });
  return localTime;
};

const convertToUTC = (date) => {
  if (!date) return '';
  // The input is in local time, convert to UTC
  const utcTime = moment(date).utc().format();
  console.log('Converting to UTC:', {
    input: date,
    output: utcTime
  });
  return utcTime;
};

const initialFormState = {
  title: '',
  start: '',
  isAllDay: false,
  type: 'non-task',
  status: 'not_started',
  assignedUsers: [],
  description: ''
};

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.success.main
  }
}));

export const EventDialog = React.memo(({
  open,
  onClose,
  event,
  onSave,
  onDelete,
  availableUsers = [],
  loading = false
}) => {
  const [formData, setFormData] = useState(initialFormState);
  const [touched, setTouched] = useState({
    title: true,
    start: true
  });
  const { enqueueSnackbar } = useSnackbar();

  // Filter out any users without IDs and ensure proper structure
  const validUsers = useMemo(() => {
    return availableUsers
      .filter(user => user && user.id)
      .map(user => ({
        id: user.id,
        name: user.name || user.displayName || user.email || 'Unknown User',
        email: user.email || ''
      }));
  }, [availableUsers]);

  // Find selected users based on the current assignedUsers value
  const selectedUsers = useMemo(() => {
    if (!formData.assignedUsers?.length) return [];
    
    return formData.assignedUsers
      .map(userId => {
        // If userId is already a user object with an id, use that
        if (typeof userId === 'object' && userId.id) {
          return validUsers.find(u => u.id === userId.id) || null;
        }
        // If userId is a string (ID), find the user
        return validUsers.find(u => u.id === userId) || null;
      })
      .filter(Boolean); // Remove any null values
  }, [validUsers, formData.assignedUsers]);

  // Reset form and touched state when dialog opens/closes
  useEffect(() => {
    if (open) {
      // Set touched to true for new events to show validation immediately
      setTouched({
        title: !event?.id,
        start: !event?.id
      });
    } else {
      setTouched({
        title: false,
        start: false
      });
    }
  }, [open, event]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => {
      const newData = {
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      };
      
      // Handle all-day toggle
      if (name === 'isAllDay') {
        if (checked && newData.start) {
          // Converting to all-day: keep only the date part
          try {
            const dateOnly = newData.start.includes('T') 
              ? newData.start.split('T')[0] 
              : newData.start;
            newData.start = dateOnly;
          } catch (error) {
            // Keep the original value if conversion fails
          }
        } else if (!checked && newData.start) {
          // Converting to timed: add default time if it's just a date
          try {
            if (!newData.start.includes('T')) {
              newData.start = `${newData.start}T00:00`;
            }
          } catch (error) {
            // Keep the original value if conversion fails
          }
        }
      }
      
      return newData;
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };

  // Reset form when event changes or dialog opens
  useEffect(() => {
    if (event) {
      const assignedUsers = Array.isArray(event.assignedUsers)
        ? event.assignedUsers.map(user => {
            if (typeof user === 'object' && user.id) {
              return user.id;
            }
            return user;
          })
        : [];

      // Format the date based on whether it's an all-day event
      let formattedStart = event.start || '';
      const isAllDay = event.isAllDay || false;
      
      try {
        if (formattedStart) {
          formattedStart = isAllDay 
            ? moment(formattedStart).format('YYYY-MM-DD')  // YYYY-MM-DD for all-day
            : convertToLocalTime(formattedStart);  // YYYY-MM-DDTHH:mm for timed
        }
      } catch (error) {
        // Silently handle date formatting errors
      }

      setFormData({
        id: event.id,
        title: event.title || '',
        start: formattedStart,
        isAllDay,
        type: event.type || 'non-task',
        status: event.status || 'not_started',
        assignedUsers,
        description: event.description || ''
      });
    } else {
      // For new events, set the start time to current time rounded to nearest hour
      const now = new Date();
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      
      setFormData({
        ...initialFormState,
        start: moment(now).format('YYYY-MM-DDTHH:mm') // Format as local time
      });
    }
  }, [event]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    
    // Validate required fields
    const errors = [];
    if (!formData.title?.trim()) {
      errors.push('Title is required');
    }
    if (!formData.start) {
      errors.push('Date & Time is required');
    }

    if (errors.length > 0) {
      errors.forEach(error => enqueueSnackbar(error, { variant: 'error' }));
      return;
    }

    try {
      // Ensure we have valid user objects
      const validAssignedUsers = selectedUsers
        .filter(user => user && user.id)
        .map(user => ({
          id: user.id,
          name: user.name,
          email: user.email
        }));

      const submissionData = {
        title: formData.title.trim(),
        start: formData.isAllDay ? formData.start : convertToUTC(formData.start),
        end: formData.isAllDay ? formData.start : convertToUTC(formData.start), // Set end time same as start for single-time events
        isAllDay: formData.isAllDay,
        allDay: formData.isAllDay, // Add allDay flag to match backend expectation
        type: 'non-task',
        status: formData.status || 'not_started',
        description: formData.description || '',
        assignedUsers: validAssignedUsers
      };

      let success = false;
      if (event?.id) {
        success = await onSave(event.id, submissionData);
      } else {
        success = await onSave(submissionData);
      }
      
      if (success) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Failed to save event', { variant: 'error' });
    }
  }, [formData, selectedUsers, onSave, handleClose, event, enqueueSnackbar]);

  const handleDelete = useCallback(async () => {
    if (!formData.id || !onDelete) return;

    try {
      const deleted = await onDelete(formData.id);
      if (deleted) {
        handleClose();
      }
    } catch (error) {
      // Error is already handled by the hook
      // Just prevent dialog from closing on error
    }
  }, [formData.id, onDelete, handleClose]);

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown={false}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {event?.id ? 'Edit Event' : 'New Event'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            value={formData.title}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            error={touched.title && !formData.title?.trim()}
            helperText={touched.title && !formData.title?.trim() ? 'Title is required' : ''}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="isAllDay"
                  checked={formData.isAllDay}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'all day event toggle' }}
                />
              }
              label="All Day Event"
            />
          </Box>
          <TextField
            margin="dense"
            name="start"
            label="Date & Time"
            type={formData.isAllDay ? "date" : "datetime-local"}
            value={formData.start}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
            error={touched.start && !formData.start}
            helperText={touched.start && !formData.start ? 'Date & Time is required' : ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Autocomplete
            multiple
            value={selectedUsers}
            onChange={(event, newValue) => {
              setFormData(prev => ({
                ...prev,
                assignedUsers: newValue.map(user => user.id)
              }));
            }}
            options={validUsers}
            getOptionLabel={(option) => option.name || 'Unknown User'}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Assigned Users"
                fullWidth
              />
            )}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            value={formData.description}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          {event && onDelete && (
            <span>
              <Button 
                onClick={handleDelete}
                color="error"
                disabled={loading}
              >
                Delete
              </Button>
            </span>
          )}
          <span>
            <Button 
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </Button>
          </span>
          <span>
            <Button 
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || !formData.title}
            >
              Save
            </Button>
          </span>
        </DialogActions>
      </form>
    </Dialog>
  );
});

EventDialog.displayName = 'EventDialog'; 
