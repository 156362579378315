import api from './api';
import { getAuthHeader } from './authService';
import { pipedriveService } from './pipedriveService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method: method,
      url: `/api/puawai-account-management${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

export const contactService = {
  async listContacts(organisationId, accountId = null) {
    try {
      const token = await getAuthHeader();
      
      // Get local contacts using the new endpoint
      const localResponse = await api.post('/api/puawai-account-management', {
        action: 'listContacts',
        data: accountId ? { accountId } : {}
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });

      // Get Pipedrive contacts
      const pipedriveResponse = await pipedriveService.getContacts(organisationId);

      // Combine both sets of contacts
      const allContacts = [
        ...(localResponse.data.contacts || []),
        ...(pipedriveResponse.contacts || [])
      ];

      // Fetch roles for all contacts
      const contactRoles = await Promise.all(
        allContacts.map(async contact => {
          const effectiveAccountId = contact.accountId || 'unassigned';
          try {
            const roleData = await this.getContactRole(
              organisationId, 
              effectiveAccountId,
              contact.id
            );
            return roleData;
          } catch (error) {
            return null;
          }
        })
      );

      // Merge role data with contacts
      const contactsWithRoles = allContacts.map((contact, index) => {
        const roleData = contactRoles[index];
        return {
          ...contact,
          accountId: contact.accountId || 'unassigned',
          role: roleData?.role || null,
          roleMetadata: roleData || null
        };
      });

      return { contacts: contactsWithRoles };
    } catch (error) {
      return { contacts: [] };
    }
  },

  async getLocalContacts(organisationId, accountId = null) {
    const token = await getAuthHeader();
    
    const response = await api.post('/api/puawai-account-management', {
      action: 'listContacts',
      data: accountId ? { accountId } : {}
    }, {
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-organization-id': organisationId
      }
    });

    return response.data.contacts || [];
  },

  async updateContact(organisationId, accountId, contactId, data) {
    try {
      const token = await getAuthHeader();
      const effectiveAccountId = accountId || 'unassigned';
      
      // If it's just a role update, use the role endpoint
      if (Object.keys(data).length === 1 && data.role) {
        const normalizedRole = data.role.toLowerCase();
        
        const response = await api.post('/api/puawai-account-management', {
          action: 'updateContactRole',
          data: {
            accountId: effectiveAccountId,
            contactId,
            role: normalizedRole,
            source: 'puawai',
            updatedAt: new Date().toISOString()
          }
        }, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        });

        return response.data;
      }

      // For full contact updates
      const response = await api.post('/api/puawai-account-management', {
        action: 'updateContact',
        data: {
          accountId: effectiveAccountId,
          contactId,
          ...data,
          source: 'puawai',
          updatedAt: new Date().toISOString()
        }
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to update contact');
      }

      return response.data;
    } catch (error) {
      console.error('Error updating contact:', {
        error,
        response: error.response?.data,
        status: error.response?.status,
        requestData: {
          organisationId,
          accountId,
          contactId,
          data
        }
      });
      throw error;
    }
  },

  async deleteContact(organisationId, accountId, contactId) {
    try {
      const token = await getAuthHeader();
      const effectiveAccountId = accountId || 'unassigned';
      
      const response = await api.post('/api/puawai-account-management', {
        action: 'deleteContact',
        data: {
          accountId: effectiveAccountId,
          contactId
        }
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to delete contact');
      }

      return response.data;
    } catch (error) {
      console.error('Error deleting contact:', {
        error,
        response: error.response?.data,
        status: error.response?.status,
        requestData: {
          organisationId,
          accountId,
          contactId
        }
      });
      throw error;
    }
  },

  async createContact(organisationId, accountId, data) {
    try {
      const token = await getAuthHeader();
      const effectiveAccountId = accountId || 'unassigned';
      
      const response = await api.post('/api/puawai-account-management', {
        action: 'createContact',
        data: {
          accountId: effectiveAccountId,
          name: data.name,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          jobTitle: data.jobTitle,
          organization: data.organization,
          visibleTo: data.visibleTo,
          marketingStatus: data.marketingStatus,
          address: data.address,
          customFields: data.customFields || {},
          role: data.role,
          source: 'puāwai',
          organizationId: organisationId
        }
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to create contact');
      }

      // If successful, immediately fetch the contact role if a role was specified
      if (response.data.success && data.role) {
        try {
          await this.updateContactRole(
            organisationId,
            effectiveAccountId,
            response.data.contact.id,
            { role: data.role }
          );
        } catch (roleError) {
          console.error('Error updating contact role:', roleError);
          // Continue even if role update fails
        }
      }

      return response.data;
    } catch (error) {
      console.error('Error creating contact:', {
        error,
        response: error.response?.data,
        status: error.response?.status,
        requestData: {
          organisationId,
          accountId,
          data
        }
      });
      throw error;
    }
  },

  async getContactRole(organisationId, accountId, contactId) {
    try {
      const response = await api.post('/api/puawai-account-management', {
        action: 'getContactRole',
        data: {
          accountId,
          contactId
        }
      }, {
        headers: {
          'Authorization': getAuthHeader(),
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });

      if (response.data.success && response.data.contactRole) {
        return response.data.contactRole;
      }
      return null;
    } catch (error) {
      console.error('Error getting contact role:', error);
      return null;
    }
  },

  getRoleOptions() {
    return [
      { value: 'decision_maker', label: 'Decision Maker' },
      { value: 'influencer', label: 'Influencer' },
      { value: 'user', label: 'End User' },
      { value: 'technical', label: 'Technical Contact' },
      { value: 'finance', label: 'Finance Contact' },
      { value: 'other', label: 'Other' }
    ];
  },

  async syncPipedriveContacts(organisationId, accountId = null) {
    try {
      const response = await api.post('/api/puawai-account-management', {
        action: 'syncPipedriveContacts',
        data: {
          accountId
        }
      }, {
        headers: { 
          'Authorization': `Bearer ${getAuthHeader()}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error syncing Pipedrive contacts:', error);
      throw error;
    }
  },

  updateContactRole: async (organisationId, accountId, contactId, roleData) => {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'updateContactRole',
        data: {
          accountId: accountId || 'unassigned',
          contactId,
          role: roleData.role || null,
          assignedBy: roleData.assignedBy || '',
          notes: roleData.notes || '',
          updatedAt: new Date().toISOString()
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to update contact role');
      }
      return response.data;
    } catch (error) {
      console.error('Error updating contact role:', error);
      throw new Error(`API error: ${error.response?.data?.message || error.message}`);
    }
  },

  addContactNote: async (organisationId, accountId, contactId, noteData) => {
    try {
      const response = await api.post('/api/puawai-account-management', {
        action: 'addContactNote',
        data: {
          organisationId,
          accountId,
          contactId,
          content: noteData.content,
          type: 'contact_note'
        }
      }, {
        headers: { 
          'Authorization': getAuthHeader(),
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('Error adding contact note:', error);
      throw new Error(`API error: ${error.response?.data?.message || error.message}`);
    }
  },

  getContactNotes: async (organisationId, accountId, contactId) => {
    try {
      const response = await api.post('/api/puawai-account-management', {
        action: 'listContactNotes',
        data: {
          organisationId,
          accountId,
          contactId,
          type: 'contact_note'
        }
      }, {
        headers: { 
          'Authorization': getAuthHeader(),
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('Error getting contact notes:', error);
      throw new Error(`API error: ${error.response?.data?.message || error.message}`);
    }
  }
};

export default contactService; 