import React, { useState, useEffect, useCallback, useMemo, useReducer } from 'react';
import {
  Box, Grid, Paper, Typography, Tabs, Tab, Divider, List, ListItem, ListItemText, ListItemIcon, IconButton, Tooltip, Fade, CircularProgress, Button, Dialog, DialogContent, DialogActions, DialogTitle, TextField, MenuItem, Menu, Skeleton, Snackbar, useTheme, Chip, ListItemButton, Collapse, ListItemSecondaryAction, InputAdornment, Stack
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
  Dashboard as DashboardIcon, Assignment, Group, Event, Description, Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, EmojiEvents, AttachMoney as MoneyIcon, AccessTime as AccessTimeIcon, Assessment as AssessmentIcon, MoreVert as MoreVertIcon,
  // Add these new imports
  DraftsOutlined,
  SearchOutlined,
  CheckCircleOutline,
  CancelOutlined,
  PlayArrowOutlined,
  SendOutlined,
  AssignmentTurnedInOutlined,
  EmojiEventsOutlined,
  SentimentVeryDissatisfiedOutlined,
  FolderOutlined as DocumentIcon,
  Visibility,
  VisibilityOff,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  ExpandMore,
  ExpandLess,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  listBids, createBid, updateBid, deleteBid, archiveBid, unarchiveBid, getBidDetails
} from '../../services/bidService';
import {
  getUser, getOrganisationsForUser
} from '../../services/userService';
import {
  getBidCanvas, createBidCanvas, updateBidCanvas, deleteBidCanvas, listBidCanvases
} from '../../services/bidcanvasService';
import BidReadinessScore from '../../components/bids/BidReadinessScore';
import BidTasks from '../../components/bids/BidTasks';
import BidTeams from '../../components/bids/BidTeams';
import CalendarTimeline from '../../components/bids/CalendarTimeline';
import CanvasTable from '../../components/bids/CanvasTable';
import BidStrategyCanvas from '../../components/bids/bidcanvases/BidStrategyCanvas';
import RFPQualificationCanvas from '../../components/bids/bidcanvases/RFPQualificationCanvas';
import { getToken, getActiveAccount } from '../../services/authService';
import bidteamService from '../../services/bidteamService';
import { revokeInvitation } from '../../services/invitationService';
import { getOrganisation, getOrganisationUsers } from '../../services/organisationService';
import Confetti from 'react-confetti';
import BidBudget from '../../components/bids/BidBudget';
import TimeTracking from '../../components/bids/TimeTracking';
import BidGateStatus from '../../components/bids/BidGateStatus';
import { updateBidGate, getBidGate } from '../../services/bidGateService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BidDocuments from '../../components/bids/BidDocuments';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useDataFetcher } from '../../hooks/useDataFetcher';
import { format } from 'date-fns';
import DialogContentText from '@mui/material/DialogContentText';
import BidAI from '../../components/bids/BidAI';
import { SmartToy as AIIcon } from '@mui/icons-material';
import BidList from '../../components/bids/BidList';
import BidDetails from '../../components/bids/BidDetails';
import QuickActions from '../../components/bids/QuickActions';
import BidHeader from '../../components/bids/BidHeader';
import { useBidData } from '../../hooks/useBidData';
import BidDocumentLinks from '../../components/bids/BidDocumentLinks';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Update navigation items configuration with the correct order
const navigationItems = [
  {
    type: 'overview',
    items: [
      { path: 'overview', label: 'Overview', icon: <DashboardIcon /> },
      { path: 'canvases', label: 'Planning', icon: <Description /> },
      { path: 'team', label: 'Team', icon: <Group /> },
      { path: 'timeline', label: 'Tasks and Timeline', icon: <Event /> },
      { path: 'budget', label: 'Budget', icon: <MoneyIcon /> },
      { path: 'time', label: 'Time Tracking', icon: <AccessTimeIcon /> },
    ]
  }
];

const BidManagementDashboard = React.memo(({ userId, selectedOrg }) => {
  const theme = useTheme();
  const { bidId } = useParams();
  
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editingBid, setEditingBid] = useState({ 
    name: '', 
    client: '', 
    status: 'draft',
    dueDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"), // Set default to current date and time
    visibility: 'invite-only'
  });
  const [user, setUser] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [canvasDialogState, setCanvasDialogState] = useState({
    open: false,
    type: '',
    data: null,
    versions: [],
  });
  const [canvases, setCanvases] = useState({
    bidStrategyCanvas: null,
    rfpQualificationCanvas: null,
  });
  const [bidStrategyCanvases, setBidStrategyCanvases] = useState([]);
  const [rfpQualificationCanvases, setRfpQualificationCanvases] = useState([]);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState('');
  const [readinessImprovement, setReadinessImprovement] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuBid, setMenuBid] = useState(null);
  const [bidToDelete, setBidToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [currentGate, setCurrentGate] = useState(0);
  const [bidProgressExpanded, setBidProgressExpanded] = useState(false);
  const [showArchivedBids, setShowArchivedBids] = useState(false);
  const [bidToArchive, setBidToArchive] = useState(null);
  const [deleteCanvasDialog, setDeleteCanvasDialog] = useState({ open: false, canvasType: null, canvasId: null });
  const [navCollapsed, setNavCollapsed] = useState(false);

  const bidStatusOptions = [
    'draft',
    'qualifying',
    'qualified',
    'qualified out',
    'in progress',
    'approved to submit',
    'approved to contract',
    'won',
    'lost'
  ];

  // Use a reducer for complex state updates
  const bidReducer = (state, action) => {
    switch (action.type) {
      case 'SET_BIDS':
        return {
          ...state,
          allBids: action.payload,
          activeBids: action.payload.filter(bid => bid.status !== 'archived'),
          archivedBids: action.payload.filter(bid => bid.status === 'archived'),
        };
      case 'UPDATE_BID':
        const updatedBids = state.allBids.map(bid => 
          bid.id === action.payload.id ? { ...bid, ...action.payload } : bid
        );
        return {
          ...state,
          allBids: updatedBids,
          activeBids: updatedBids.filter(bid => bid.status !== 'archived'),
          archivedBids: updatedBids.filter(bid => bid.status === 'archived'),
          selectedBid: state.selectedBid?.id === action.payload.id 
            ? { ...state.selectedBid, ...action.payload }
            : state.selectedBid,
        };
      case 'SELECT_BID':
        return { ...state, selectedBid: action.payload };
      default:
        return state;
    }
  };

  const [bidState, dispatchBidAction] = useReducer(bidReducer, {
    allBids: [],
    activeBids: [],
    archivedBids: [],
    selectedBid: null,
  });

  // Use the imported useDataFetcher hook
  const { data: fetchedBids, isLoading: bidsLoading, error: bidsError } = useDataFetcher(
    async () => {
      if (selectedOrg) {
        return await listBids(selectedOrg);
      }
      return [];
    },
    [selectedOrg]
  );

  // Use an effect to update the bid state when fetchedBids changes
  useEffect(() => {
    if (fetchedBids) {
      dispatchBidAction({ type: 'SET_BIDS', payload: fetchedBids });
      if (!bidState.selectedBid && fetchedBids.length > 0) {
        dispatchBidAction({ type: 'SELECT_BID', payload: fetchedBids[0] });
      }
    }
  }, [fetchedBids]);

  // Function to update a bid
  const updateBidState = useCallback((updatedBid) => {
    dispatchBidAction({ type: 'UPDATE_BID', payload: updatedBid });
  }, []);

  // Function to select a bid
  const handleSelectBid = async (bid) => {
    dispatchBidAction({ type: 'SELECT_BID', payload: bid });
    localStorage.setItem('lastSelectedBidId', bid.id);
    
    try {
      const fullBidDetails = await getBidDetails(bid.id, selectedOrg);
      dispatchBidAction({ type: 'UPDATE_BID', payload: { ...fullBidDetails, owner: bid.owner } });
    } catch (error) {
      console.error('Error fetching full bid details:', error);
      setError('Failed to fetch complete bid details. Please try again.');
    }
  };

  // Function to handle bid creation
  const handleCreateBid = async (newBid) => {
    try {
      const createdBid = await createBid({
        ...newBid,
        owner: user.id,
        organisationId: selectedOrg
      });
      updateBidState(createdBid);
      handleSelectBid(createdBid);
      handleOpenSnackbar('Bid created successfully', 'success');
    } catch (error) {
      console.error('Error creating bid:', error);
      handleOpenSnackbar(`Error: ${error.message}. Please try again.`, 'error');
    }
  };

  // Function to handle bid update
  const handleUpdateBid = async (updatedBid) => {
    try {
      const result = await updateBid(updatedBid.id, updatedBid);
      updateBidState(result);
      handleOpenSnackbar('Bid updated successfully', 'success');
    } catch (error) {
      console.error('Error updating bid:', error);
      handleOpenSnackbar(`Error: ${error.message}. Please try again.`, 'error');
    }
  };

  // Function to handle bid archiving
  const handleArchiveBid = (bid) => {
    try {
      setBidToArchive(bid);
      setArchiveDialogOpen(true);
      handleMenuClose();
    } catch (error) {
      console.error('Error preparing bid archive:', error);
      handleOpenSnackbar(`Error preparing bid archive: ${error.message}`, 'error');
    }
  };

  const handleArchiveConfirm = async () => {
    if (bidToArchive) {
      try {
        const archivedBid = await archiveBid(bidToArchive.id, selectedOrg);
        dispatchBidAction({ 
          type: 'UPDATE_BID', 
          payload: { ...archivedBid, status: 'archived' } 
        });
        
        // If the archived bid was selected, select another bid
        if (bidState.selectedBid?.id === bidToArchive.id) {
          const nextActiveBid = bidState.activeBids.find(b => b.id !== bidToArchive.id);
          if (nextActiveBid) {
            handleSelectBid(nextActiveBid);
          } else {
            dispatchBidAction({ type: 'SELECT_BID', payload: null });
          }
        }
        
        handleOpenSnackbar('Bid archived successfully', 'success');
      } catch (error) {
        console.error('Error archiving bid:', error);
        handleOpenSnackbar(`Error archiving bid: ${error.message}`, 'error');
      } finally {
        setArchiveDialogOpen(false);
        setBidToArchive(null);
      }
    }
  };

  // Data fetching hooks
  const { data: userData, isLoading: userLoading } = useDataFetcher(async () => {
    const account = await getActiveAccount();
    return account ? getUser(account.localAccountId) : null;
  }, []);
  const { data: organisationData, isLoading: organisationLoading } = useDataFetcher(async () => {
    const account = await getActiveAccount();
    if (!account) return null;
    const orgs = await getOrganisationsForUser(account.localAccountId);
    return orgs.length > 0 ? getOrganisation(orgs[0]) : null;
  }, []);

  // Effects for setting user and organisation data
  useEffect(() => {
    if (userData) setUser(userData);
  }, [userData]);

  useEffect(() => {
    if (organisationData) setOrganisation(organisationData);
  }, [organisationData]);

  const {
    ownerDetails,
    teamMembers,
    organizationUsers,
    isLoading: isLoadingBidDetails,
    error: bidDataError,
    refreshData: refreshBidData
  } = useBidData(bidState.selectedBid, organisation);

  // Memoized callback functions
  const fetchTeamMembers = useCallback(async () => {
    if (!bidState.selectedBid || !organisation) return;
    try {
      await refreshBidData();
    } catch (error) {
      console.error('Error fetching team members:', error);
      setError('Failed to fetch team members. Please try again later.');
    }
  }, [bidState.selectedBid?.id, organisation?.id, refreshBidData]);

  const handleOpenDialog = (type, bid = null) => {
    setDialogType(type);
    if (type === 'edit' && bid) {
      setEditingBid({
        id: bid.id,
        name: bid.name,
        client: bid.client,
        status: bid.status,
        dueDate: bid.dueDate ? format(new Date(bid.dueDate), "yyyy-MM-dd'T'HH:mm") : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        visibility: bid.visibility || 'invite-only'
      });
    } else {
      setEditingBid({
        name: '',
        client: '',
        status: 'draft',
        dueDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        visibility: 'invite-only'
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingBid(null);
  };

  const handleSaveBid = async () => {
    try {
      if (!selectedOrg) {
        throw new Error('No organisation selected');
      }

      let updatedBid;
      if (dialogType === 'edit' && editingBid.id) {
        updatedBid = await updateBid(editingBid.id, editingBid);
        dispatchBidAction({ type: 'UPDATE_BID', payload: updatedBid });
      } else {
        const newBid = await createBid({
          ...editingBid,
          owner: user.id,
          organisationId: selectedOrg
        });
        dispatchBidAction({ type: 'SET_BIDS', payload: [...bidState.allBids, newBid] });
        handleSelectBid(newBid);
      }

      handleCloseDialog();
      handleOpenSnackbar(
        dialogType === 'create' ? 'Bid created successfully' : 'Bid updated successfully',
        'success'
      );
    } catch (error) {
      console.error('Error saving bid:', error);
      handleOpenSnackbar(`Error: ${error.message}`, 'error');
    }
  };

  const handleDeleteBidConfirm = (bid) => {
    try {
      setBidToDelete(bid);
      setDeleteDialogOpen(true);
      handleMenuClose();
    } catch (error) {
      console.error('Error preparing bid deletion:', error);
      handleOpenSnackbar(`Error preparing bid deletion: ${error.message}`, 'error');
    }
  };

  const handleDeleteConfirm = async () => {
    if (bidToDelete) {
      try {
        await deleteBid(bidToDelete.id, selectedOrg);
        
        // Remove the bid from state
        dispatchBidAction({ 
          type: 'SET_BIDS', 
          payload: bidState.allBids.filter(b => b.id !== bidToDelete.id) 
        });
        
        // If the deleted bid was selected, select another bid
        if (bidState.selectedBid?.id === bidToDelete.id) {
          const nextActiveBid = bidState.activeBids.find(b => b.id !== bidToDelete.id);
          if (nextActiveBid) {
            handleSelectBid(nextActiveBid);
          } else {
            dispatchBidAction({ type: 'SELECT_BID', payload: null });
          }
        }
        
        handleOpenSnackbar('Bid deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting bid:', error);
        handleOpenSnackbar(`Error deleting bid: ${error.message}`, 'error');
      } finally {
        setDeleteDialogOpen(false);
        setBidToDelete(null);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCanvasOpen = (canvasType, canvasId = null) => {
    handleActionClick(canvasType, canvasId);
  };

  const handleActionClick = async (action, canvasId = null) => {
    try {
      let fetchedCanvasData = null;

      if (canvasId) {
        fetchedCanvasData = await getBidCanvas(bidState.selectedBid.id, canvasId);
      } else {
        const existingCanvas = action === 'bidStrategy' 
          ? bidStrategyCanvases[0] 
          : rfpQualificationCanvases[0];

        if (existingCanvas) {
          fetchedCanvasData = existingCanvas;
        } else {
          const canvasType = action === 'bidStrategy' ? 'Bid Strategy Canvas' : 'RFP Qualification Canvas';
          const canvasName = `${bidState.selectedBid.name} - ${canvasType}`;

          fetchedCanvasData = await createBidCanvas(bidState.selectedBid.id, {
            name: canvasName,
            type: canvasType,
            createdBy: user?.id,
          });
        }
      }

      if (!fetchedCanvasData || !fetchedCanvasData.id) {
        console.error('Failed to fetch or create canvas:', fetchedCanvasData);
        return;
      }

      setCanvasDialogState({
        open: true,
        type: action,
        data: fetchedCanvasData,
        versions: fetchedCanvasData.versions || [],
      });
    } catch (error) {
      console.error('Error handling canvas action:', error);
      setSnackbar({
        open: true,
        message: `Error: ${error.message}`,
        severity: 'error'
      });
    }
  };
  
  const handleDeleteCanvas = async (canvasType, canvasId) => {
    setDeleteCanvasDialog({ open: true, canvasType, canvasId });
  };

  const handleConfirmDeleteCanvas = async () => {
    const { canvasType, canvasId } = deleteCanvasDialog;
    if (!bidState.selectedBid || !bidState.selectedBid.id) {
      console.error('No bid selected');
      return;
    }

    try {
      await deleteBidCanvas(bidState.selectedBid.id, canvasId);
      if (canvasType === 'bidStrategy') {
        setBidStrategyCanvases([]);
        updateBidState({ ...bidState.selectedBid, bidStrategyStatus: 'not_started' });
      } else {
        setRfpQualificationCanvases([]);
        updateBidState({ ...bidState.selectedBid, rfpQualificationStatus: 'not_started' });
      }
      setSnackbar({
        open: true,
        message: 'Canvas deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting canvas:', error);
      setSnackbar({
        open: true,
        message: `Error deleting canvas: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setDeleteCanvasDialog({ open: false, canvasType: null, canvasId: null });
    }
  };
  

  const handleCloseCanvasDialog = () => {
    setCanvasDialogState({ open: false, type: '', data: null, versions: [] });
  };

  const handleSaveCanvas = async (savedCanvas, status) => {
    try {
      if (!bidState.selectedBid || !bidState.selectedBid.id) {
        throw new Error('No bid selected');
      }

      let updatedCanvas;
      if (savedCanvas.id) {
        updatedCanvas = await updateBidCanvas(bidState.selectedBid.id, savedCanvas.id, {
          ...savedCanvas,
          status,
          updatedAt: new Date().toISOString()
        });
      } else {
        updatedCanvas = await createBidCanvas(bidState.selectedBid.id, {
          ...savedCanvas,
          status,
          type: canvasDialogState.type === 'bidStrategy' ? 'Bid Strategy Canvas' : 'RFP Qualification Canvas',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          createdBy: user?.id
        });
      }

      // Update the correct canvas state
      if (updatedCanvas.type === 'Bid Strategy Canvas') {
        setBidStrategyCanvases([updatedCanvas]);
      } else if (updatedCanvas.type === 'RFP Qualification Canvas') {
        setRfpQualificationCanvases([updatedCanvas]);
      }

      setCanvases(prev => ({
        ...prev,
        [canvasDialogState.type === 'bidStrategy' ? 'bidStrategyCanvas' : 'rfpQualificationCanvas']: updatedCanvas,
      }));

      setCanvasDialogState(prev => ({ ...prev, open: false }));
      
      // Show success message
      setSnackbar({
        open: true,
        message: `Canvas ${status === 'completed' ? 'completed' : 'saved as draft'} successfully`,
        severity: 'success'
      });

      // Update bid readiness
      const oldReadiness = calculateBidReadiness(bidState.selectedBid);
      const updatedBid = {
        ...bidState.selectedBid,
        [canvasDialogState.type === 'bidStrategy' ? 'bidStrategyStatus' : 'rfpQualificationStatus']: status
      };
      const newReadiness = calculateBidReadiness(updatedBid);
      const improvement = newReadiness - oldReadiness;

      updateBidState(updatedBid);

      // Only show celebration for first-time completion
      if (status === 'completed' && improvement > 0) {
        const canvasType = canvasDialogState.type === 'bidStrategy' ? 'Bid Strategy Canvas' : 'RFP Qualification Canvas';
        const previousStatus = bidState.selectedBid[canvasDialogState.type === 'bidStrategy' ? 'bidStrategyStatus' : 'rfpQualificationStatus'];
        
        if (previousStatus !== 'completed') {
          setReadinessImprovement(improvement);
          setCelebrationMessage(`Congratulations! You've completed the ${canvasType}.`);
          setShowCelebration(true);
          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 5000);
        }
      }

    } catch (error) {
      console.error('Error saving canvas:', error);
      setSnackbar({
        open: true,
        message: `Error saving canvas: ${error.message}`,
        severity: 'error'
      });
    }
  };
  

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return !isNaN(date) ? date.toLocaleDateString() : 'Invalid Date';
  };

  const fetchCanvases = useCallback(async (bidId) => {
    if (!bidId || typeof bidId !== 'string') {
      console.error('Invalid bidId:', bidId);
      setError('Invalid bid ID. Unable to fetch canvases.');
      return;
    }
    try {
      const authToken = await getToken();
      const canvases = await listBidCanvases(bidId, authToken);
      const bidStrategyCanvases = canvases.filter(canvas => canvas.type === 'Bid Strategy Canvas');
      const rfpQualificationCanvases = canvases.filter(canvas => canvas.type === 'RFP Qualification Canvas');
      setBidStrategyCanvases(bidStrategyCanvases);
      setRfpQualificationCanvases(rfpQualificationCanvases);
    } catch (error) {
      console.error('Error fetching canvases:', error);
      setError('Failed to fetch canvases. Please try again later.');
    }
  }, [getToken, listBidCanvases, setBidStrategyCanvases, setRfpQualificationCanvases, setError]);

  useEffect(() => {
    if (bidState.selectedBid?.id) {
      fetchCanvases(bidState.selectedBid.id);
    }
  }, [bidState.selectedBid?.id, fetchCanvases]);

  const handleTeamUpdate = useCallback(async () => {
    if (!bidState.selectedBid || !organisation) return;
    
    try {
      await refreshBidData();
    } catch (error) {
      console.error('Error updating team:', error);
      setError('Failed to update team. Please try again later.');
    }
  }, [bidState.selectedBid, organisation, refreshBidData]);

  const refreshAuthToken = async () => {
    try {
      await refreshToken();
      return await getToken();
    } catch (error) {
      console.error('Error refreshing token:', error);
      // Handle logout or redirect to login page
      return null;
    }
  };

  // Memoized callback functions
  const handleBidUpdate = useCallback(async () => {
    if (bidState.selectedBid) {
      await refreshBidData();
    }
  }, [bidState.selectedBid, refreshBidData]);

  const toggleBidProgress = useCallback(() => {
    setBidProgressExpanded(!bidProgressExpanded);
  }, []);

  const handleOpenSnackbar = useCallback((message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const handleCloseSnackbar = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);

  const handleRevokeInvitation = async (orgId, invitationId) => {
    try {
      await revokeInvitation(orgId, invitationId);
      // If successful, update your state or UI accordingly
      setInvitations(prevInvitations => prevInvitations.filter(inv => inv.id !== invitationId));
      // Show success message
      ////console.log('Invitation revoked successfully');
      // You might want to show a success message to the user
    } catch (error) {
      console.error('Failed to revoke invitation:', error.message);
      // Handle specific error cases
      if (error.message.includes('Invitation not found')) {
        ////console.log('This invitation has already been revoked or does not exist.');
        // Remove the invitation from the list if it doesn't exist
        setInvitations(prevInvitations => prevInvitations.filter(inv => inv.id !== invitationId));
      } else if (error.message.includes('permission')) {
        ////console.log('You do not have permission to revoke this invitation.');
      } else {
        ////console.log('An unexpected error occurred. Please try again later.');
      }
      // You might want to show these error messages to the user in a toast or alert
    }
  };

  const handleReadinessAction = (action) => {
    switch (action) {
      case 'bidStrategy':
        handleActionClick('bidStrategy');
        break;
      case 'rfpQualification':
        handleActionClick('rfpQualification');
        break;
      case 'tasks':
        setCurrentTab(0); // Switch to Tasks tab
        break;
      case 'team':
        setCurrentTab(1); // Switch to Teams tab
        break;
      default:
        ////console.log('Unknown action:', action);
    }
  };

  const updateBidReadiness = useCallback(() => {
    if (bidState.selectedBid) {
      const updatedBid = { ...bidState.selectedBid };
      updatedBid.bidStrategyStatus = bidStrategyCanvases[0]?.status || 'not_started';
      updatedBid.rfpQualificationStatus = rfpQualificationCanvases[0]?.status || 'not_started';
      updateBidState(updatedBid);
      // You might want to update this bid in your backend as well
    }
  }, [bidState.selectedBid, bidStrategyCanvases, rfpQualificationCanvases]);

  const calculateBidReadiness = (bid) => {
    const getCanvasScore = (status) => {
      switch (status) {
        case 'completed': return 25;
        case 'draft': return 10;
        default: return 0;
      }
    };

    const strategyScore = getCanvasScore(bid.bidStrategyStatus);
    const rfpScore = getCanvasScore(bid.rfpQualificationStatus);
    const tasksScore = bid.tasks && bid.tasks.length > 0 ? Math.min(25, bid.tasks.length * 2.5) : 0;
    const teamScore = bid.teamMembers && bid.teamMembers.length > 0 ? Math.min(25, bid.teamMembers.length * 5) : 0;

    return Math.round(strategyScore + rfpScore + tasksScore + teamScore);
  };

  const handleCloseCelebration = () => {
    setShowCelebration(false);
  };

  const handleMenuOpen = (event, bid, isArchived) => {
    event.stopPropagation(); // Prevent bid selection when opening menu
    setAnchorEl(event.currentTarget);
    setMenuBid({ ...bid, isArchived });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuBid(null);
  };

  const handleViewBid = () => {
    if (menuBid) {
      handleSelectBid(menuBid);
      handleMenuClose();
    }
  };

  const handleEditBid = () => {
    if (menuBid) {
      handleOpenDialog('edit', menuBid);
      handleMenuClose();
    }
  };

  const handleUnarchiveBid = async (bid) => {
    try {
      const unarchivedBid = await unarchiveBid(bid.id);
      updateBidState(unarchivedBid);
      handleOpenSnackbar('Bid unarchived successfully', 'success');
      handleMenuClose();
    } catch (error) {
      handleOpenSnackbar(`Error unarchiving bid: ${error.message}`, 'error');
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'draft':
        return <DraftsOutlined fontSize="small" />;
      case 'qualifying':
        return <SearchOutlined fontSize="small" />;
      case 'qualified':
        return <CheckCircleOutline fontSize="small" />;
      case 'qualified out':
        return <CancelOutlined fontSize="small" />;
      case 'in progress':
        return <PlayArrowOutlined fontSize="small" />;
      case 'approved to submit':
        return <SendOutlined fontSize="small" />;
      case 'approved to contract':
        return <AssignmentTurnedInOutlined fontSize="small" />;
      case 'won':
        return <EmojiEventsOutlined fontSize="small" />;
      case 'lost':
        return <SentimentVeryDissatisfiedOutlined fontSize="small" />;
      default:
        return null;
    }
  };

  const memoizedBids = useMemo(() => bidState.allBids, [bidState.allBids]);

  const handleVisibilityChange = async (bid) => {
    const newVisibility = bid.visibility === 'organisation' ? 'invite-only' : 'organisation';
    try {
      const updatedBid = await updateBid(bid.id, { visibility: newVisibility });
      updateBidState(updatedBid);
      setSnackbar({
        open: true,
        message: `Bid visibility changed to ${newVisibility === 'organisation' ? 'Organisation-wide' : 'Invite-only'}`,
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error updating bid visibility: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const toggleArchivedBids = () => {
    setShowArchivedBids(!showArchivedBids);
  };

  const handleUpdateGate = async (newGate) => {
    if (!bidState.selectedBid) return;
    
    try {
      await updateBidGate(bidState.selectedBid.id, newGate);
      setCurrentGate(newGate);
      handleOpenSnackbar('Progress status updated successfully', 'success');
    } catch (error) {
      console.error('Error updating progress status:', error);
      handleOpenSnackbar(`Error updating progress status: ${error.message}`, 'error');
    }
  };

  const renderNoBidsView = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 600, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Bid Management!
        </Typography>
        <Typography variant="body1" paragraph>
          You haven't created any bids yet. Start your journey to winning more business by creating your first bid.
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Organize your bid process" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Collaborate with your team" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Track progress and deadlines" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Increase your win rate" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => handleOpenDialog('create')}
        >
          Create Your First Bid
        </Button>
      </Paper>
    </Box>
  );

  const handleGateAction = (action) => {
    switch (action) {
      case 'setupBudget':
        // Open budget feature
        break;
      case 'completeRFPCanvas':
        // Open RFP Qualification Canvas
        break;
      case 'completeBidStrategyCanvas':
        // Open Bid Strategy Canvas
        break;
      case 'makeBidDecision':
        // Open Bid/No-Bid decision dialog
        // This should only be accessible if all prerequisites are met
        break;
      // ... handle other actions
    }
  };

  const renderLoadingSkeleton = () => (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Bid List Skeleton */}
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              <Skeleton width="60%" />
            </Typography>
            <List>
              {[1, 2, 3, 4, 5].map((_, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <Skeleton variant="circular" width={40} height={40} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Skeleton width="80%" />}
                      secondary={<Skeleton width="60%" />}
                    />
                  </ListItem>
                </motion.div>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Main Content Skeleton */}
        <Grid item xs={12} md={9}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Tabs value={0}>
              {['Overview', 'Tasks', 'Team', 'Timeline', 'Canvases'].map((tab, index) => (
                <Tab key={index} label={<Skeleton width={60} />} />
              ))}
            </Tabs>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Skeleton variant="rectangular" height={200} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton variant="rectangular" height={200} />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  // First, add a function to check if user is owner
  const isUserOwner = useMemo(() => {
    return menuBid && user && (menuBid.owner === user.id || user.role === 'admin');
  }, [menuBid, user]);

  if (bidsLoading || userLoading || organisationLoading) {
    return (
      <Fade in={true} style={{ transitionDelay: '300ms' }}>
        <div>{renderLoadingSkeleton()}</div>
      </Fade>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
      {/* Left Navigation */}
      <Paper
        elevation={3}
        sx={{
          width: navCollapsed ? 72 : 280,
          minWidth: navCollapsed ? 72 : 280,
          transition: theme => theme.transitions.create('width'),
          overflowX: 'hidden',
          borderRadius: 0,
          borderRight: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {/* Navigation Header */}
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          {!navCollapsed && <Typography variant="h6">Bid Management</Typography>}
          <IconButton onClick={() => setNavCollapsed(!navCollapsed)}>
            {navCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>

        {/* Main Navigation Items */}
        <List>
          {navigationItems[0].items.map((item) => (
            <Tooltip 
              key={item.path}
              title={navCollapsed ? item.label : ""}
              placement="right"
              arrow
            >
              <ListItem
                button
                selected={currentTab === navigationItems[0].items.indexOf(item)}
                onClick={() => setCurrentTab(navigationItems[0].items.indexOf(item))}
                sx={{
                  py: 1.5,
                  px: 2,
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '& .MuiListItemIcon-root': { color: 'white' },
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: navCollapsed ? 'auto' : 40 }}>
                  {item.icon}
                </ListItemIcon>
                {!navCollapsed && <ListItemText primary={item.label} />}
              </ListItem>
            </Tooltip>
          ))}
        </List>

        {/* Bids Section */}
        {!navCollapsed && (
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column',
            borderTop: 1,
            borderColor: 'divider',
            mt: 2
          }}>
            {/* Bids Header with Create Button */}
            <Box sx={{ 
              p: 2, 
              bgcolor: 'grey.100',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Bids
                </Typography>
                <Tooltip title={showArchivedBids ? "Hide archived bids" : "Show archived bids"}>
                  <IconButton 
                    size="small" 
                    onClick={() => setShowArchivedBids(!showArchivedBids)}
                    color={showArchivedBids ? "primary" : "default"}
                  >
                    <ArchiveIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Button
                startIcon={<AddIcon />}
                size="small"
                onClick={() => handleOpenDialog('create')}
              >
                Create
              </Button>
            </Box>

            {/* Search Box */}
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                size="small"
                placeholder="Search bids..."
                onChange={(e) => {
                  // Implement bid search functionality
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Bids List */}
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              {bidsLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <CircularProgress />
                </Box>
              ) : bidsError ? (
                <Box sx={{ p: 2 }}>
                  <Alert severity="error">
                    {bidsError}
                  </Alert>
                </Box>
              ) : (
                <>
                  <List>
                    {bidState.activeBids.map((bid) => (
                      <ListItem
                        button
                        key={bid.id}
                        selected={bidState.selectedBid?.id === bid.id}
                        onClick={() => handleSelectBid(bid)}
                        sx={{
                          py: 1,
                          '&.Mui-selected': {
                            bgcolor: 'primary.main',
                            color: 'white',
                            '& .MuiListItemIcon-root': { color: 'white' },
                          },
                        }}
                      >
                        <ListItemIcon>
                          <Description />
                        </ListItemIcon>
                        <ListItemText 
                          primary={bid.name}
                          secondary={bid.client}
                        />
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMenuOpen(e, bid, false);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>

                  {/* Archived Bids Section */}
                  {showArchivedBids && bidState.archivedBids.length > 0 && (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ px: 2, py: 1 }}
                      >
                        Archived Bids
                      </Typography>
                      <List>
                        {bidState.archivedBids.map((bid) => (
                          <ListItem
                            button
                            key={bid.id}
                            selected={bidState.selectedBid?.id === bid.id}
                            onClick={() => handleSelectBid(bid)}
                            sx={{
                              py: 1,
                              opacity: 0.7,
                              '&:hover': { opacity: 1 },
                              '&.Mui-selected': {
                                bgcolor: 'primary.main',
                                color: 'white',
                                opacity: 1,
                                '& .MuiListItemIcon-root': { color: 'white' },
                              },
                            }}
                          >
                            <ListItemIcon>
                              <ArchiveIcon />
                            </ListItemIcon>
                            <ListItemText 
                              primary={bid.name}
                              secondary={bid.client}
                            />
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMenuOpen(e, bid, true);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </Paper>

      {/* Main Content Area */}
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 3 }}>
        {bidState.activeBids.length > 0 && bidState.selectedBid ? (
          <>
            <BidHeader
              bidName={bidState.selectedBid.name}
              theme={theme}
            />
            <Box sx={{ p: 3, flexGrow: 1, overflowY: 'auto' }}>
              {isLoadingBidDetails || bidsLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {currentTab === 0 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <BidDetails
                          bid={bidState.selectedBid}
                          ownerDetails={ownerDetails}
                          onVisibilityChange={handleVisibilityChange}
                          onUpdateBid={handleUpdateBid}
                          bidStatusOptions={bidStatusOptions}
                          currentGate={currentGate}
                          onGateChange={handleUpdateGate}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={3}>
                          <QuickActions
                            bidStrategyCanvases={bidStrategyCanvases}
                            rfpQualificationCanvases={rfpQualificationCanvases}
                            teamMembers={teamMembers}
                            selectedBid={bidState.selectedBid}
                            onActionClick={handleActionClick}
                            onTabChange={setCurrentTab}
                          />
                          <BidDocumentLinks
                            bid={bidState.selectedBid}
                            onUpdateBid={handleUpdateBid}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                  {currentTab === 1 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <CanvasTable
                          canvasType="bidStrategy"
                          canvases={bidStrategyCanvases}
                          onActionClick={handleActionClick}
                          onDeleteCanvas={handleDeleteCanvas}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CanvasTable
                          canvasType="rfpQualification"
                          canvases={rfpQualificationCanvases}
                          onActionClick={handleActionClick}
                          onDeleteCanvas={handleDeleteCanvas}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {currentTab === 2 && (
                    <BidTeams
                      bidId={bidState.selectedBid.id}
                      userId={userId}
                      owner={bidState.selectedBid.owner}
                      teamMembers={teamMembers}
                      selectedOrg={selectedOrg}
                      isLoading={isLoadingBidDetails}
                      onTeamUpdate={refreshBidData}
                      bidVisibility={bidState.selectedBid.visibility}
                      updateBidState={updateBidState}
                    />
                  )}
                  {currentTab === 3 && (
                    <CalendarTimeline
                      bidId={bidState.selectedBid.id}
                      organisationId={selectedOrg}
                      bidVisibility={bidState.selectedBid}
                      userProfile={user}
                    />
                  )}
                  {currentTab === 4 && <BidBudget bidId={bidState.selectedBid.id} />}
                  {currentTab === 5 && <TimeTracking bidId={bidState.selectedBid.id} />}
                  {currentTab === 6 && <BidDocuments bidId={bidState.selectedBid.id} bidName={bidState.selectedBid.name} />}
                </>
              )}
            </Box>

            {/* Add BidAI component here */}
            <BidAI
              bid={bidState.selectedBid}
              userProfile={user}
              organisation={organisation}
              toneOfVoiceData={organisation?.toneOfVoice}
              onBidUpdate={handleUpdateBid}
            />
          </>
        ) : (
          renderNoBidsView()
        )}
      </Box>

      {/* Existing dialogs and other components remain the same */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleViewBid}>
          <ListItemIcon>
            <Visibility fontSize="small" />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>
        {!menuBid?.isArchived && (
          <Tooltip 
            title={!isUserOwner ? "Only the bid owner can edit this bid" : ""}
            placement="right"
          >
            <span>
              <MenuItem 
                onClick={handleEditBid}
                disabled={!isUserOwner}
                sx={{
                  opacity: !isUserOwner ? 0.5 : 1,
                  '&.Mui-disabled': {
                    color: 'text.secondary',
                  }
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {menuBid?.isArchived ? (
          <>
            <Tooltip 
              title={!isUserOwner ? "Only the bid owner can unarchive this bid" : ""}
              placement="right"
            >
              <span>
                <MenuItem 
                  onClick={() => handleUnarchiveBid(menuBid)}
                  disabled={!isUserOwner}
                  sx={{
                    opacity: !isUserOwner ? 0.5 : 1,
                    '&.Mui-disabled': {
                      color: 'text.secondary',
                    }
                  }}
                >
                  <ListItemIcon>
                    <UnarchiveIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Unarchive</ListItemText>
                </MenuItem>
              </span>
            </Tooltip>
            <Tooltip 
              title={!isUserOwner ? "Only the bid owner can delete this bid" : ""}
              placement="right"
            >
              <span>
                <MenuItem 
                  onClick={() => handleDeleteBidConfirm(menuBid)}
                  disabled={!isUserOwner}
                  sx={{
                    opacity: !isUserOwner ? 0.5 : 1,
                    color: 'error.main',
                    '&.Mui-disabled': {
                      color: 'error.light',
                    }
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" color="error" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </span>
            </Tooltip>
          </>
        ) : (
          <Tooltip 
            title={!isUserOwner ? "Only the bid owner can archive this bid" : ""}
            placement="right"
          >
            <span>
              <MenuItem 
                onClick={() => handleArchiveBid(menuBid)}
                disabled={!isUserOwner}
                sx={{
                  opacity: !isUserOwner ? 0.5 : 1,
                  '&.Mui-disabled': {
                    color: 'text.secondary',
                  }
                }}
              >
                <ListItemIcon>
                  <ArchiveIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Archive</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}
      </Menu>

      {/* First, add the dialog component at the end of the component, before the Menu */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {dialogType === 'create' ? 'Create New Bid' : 'Edit Bid'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              label="Bid Name"
              fullWidth
              value={editingBid?.name || ''}
              onChange={(e) => setEditingBid(prev => ({ ...prev, name: e.target.value }))}
              required
            />
            <TextField
              margin="dense"
              label="Client"
              fullWidth
              value={editingBid?.client || ''}
              onChange={(e) => setEditingBid(prev => ({ ...prev, client: e.target.value }))}
              required
            />
            <TextField
              select
              margin="dense"
              label="Status"
              fullWidth
              value={editingBid?.status || 'draft'}
              onChange={(e) => setEditingBid(prev => ({ ...prev, status: e.target.value }))}
            >
              {bidStatusOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              label="Due Date"
              type="datetime-local"
              fullWidth
              value={editingBid?.dueDate || ''}
              onChange={(e) => setEditingBid(prev => ({ ...prev, dueDate: e.target.value }))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              select
              margin="dense"
              label="Visibility"
              fullWidth
              value={editingBid?.visibility || 'invite-only'}
              onChange={(e) => setEditingBid(prev => ({ ...prev, visibility: e.target.value }))}
            >
              <MenuItem value="invite-only">Invite Only</MenuItem>
              <MenuItem value="organisation">Organisation-wide</MenuItem>
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            onClick={handleSaveBid} 
            variant="contained" 
            disabled={!editingBid?.name || !editingBid?.client}
          >
            {dialogType === 'create' ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Bid</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this bid? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={archiveDialogOpen}
        onClose={() => setArchiveDialogOpen(false)}
      >
        <DialogTitle>Archive Bid</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this bid? You can unarchive it later if needed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleArchiveConfirm} color="primary" variant="contained">
            Archive
          </Button>
        </DialogActions>
      </Dialog>

      {/* Canvas Dialog */}
      <Dialog
        open={canvasDialogState.open}
        onClose={handleCloseCanvasDialog}
        maxWidth="xl"
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        {canvasDialogState.type === 'bidStrategy' ? (
          <BidStrategyCanvas
            user={user}
            selectedOrg={selectedOrg}
            canvas={canvasDialogState.data}
            onClose={handleCloseCanvasDialog}
            onSave={handleSaveCanvas}
            bidId={bidState.selectedBid?.id}
            bidName={bidState.selectedBid?.name}
            brandData={organisation?.brandData}
            toneOfVoiceData={organisation?.toneOfVoice}
            bidData={bidState.selectedBid}
          />
        ) : canvasDialogState.type === 'rfpQualification' ? (
          <RFPQualificationCanvas
            user={user}
            selectedOrg={selectedOrg}
            canvas={canvasDialogState.data}
            onClose={handleCloseCanvasDialog}
            onSave={handleSaveCanvas}
            bidId={bidState.selectedBid?.id}
            bidName={bidState.selectedBid?.name}
            brandData={organisation?.brandData}
            toneOfVoiceData={organisation?.toneOfVoice}
            bidData={bidState.selectedBid}
          />
        ) : null}
      </Dialog>

      {/* Canvas Delete Dialog */}
      <Dialog
        open={deleteCanvasDialog.open}
        onClose={() => setDeleteCanvasDialog({ open: false, canvasType: null, canvasId: null })}
      >
        <DialogTitle>Delete Canvas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {deleteCanvasDialog.canvasType} canvas? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteCanvasDialog({ open: false, canvasType: null, canvasId: null })}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDeleteCanvas} 
            color="error" 
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default BidManagementDashboard;