import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Alert,
  Chip,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Avatar
} from '@mui/material';
import {
  Business as BusinessIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Language as WebsiteIcon,
  LocationOn as LocationIcon,
  Description as DescriptionIcon,
  AttachMoney as MoneyIcon,
  Event as EventIcon,
  Person as PersonIcon,
  CalendarIcon,
  LinkedIn as LinkedInIcon,
  Factory as IndustryIcon,
  Badge as NZBNIcon,
  Groups as ContactsIcon,
  Handshake as DealsIcon,
  Calculate as AverageIcon
} from '@mui/icons-material';
import { accountManagementService } from '../../services/accountManagementService';
import { dealService } from '../../services/dealService';
import { format } from 'date-fns';

const AccountDetailsDashboard = ({ userData, selectedOrg }) => {
  const { selectedAccount } = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [recentDeals, setRecentDeals] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (selectedOrg && selectedAccount?.id) {
      fetchAccountData();
    }
  }, [selectedOrg, selectedAccount?.id]);

  const fetchAccountData = async () => {
    try {
      setLoading(true);
      setError(null);

      const [metricsData, dealsData, activitiesData] = await Promise.all([
        accountManagementService.getAccountAnalytics(
          selectedOrg, 
          selectedAccount.id,
          selectedAccount.source
        ),
        dealService.listDeals(selectedOrg, selectedAccount),
        accountManagementService.listActivities(selectedOrg, selectedAccount.id)
      ]);

      setMetrics(metricsData?.data || {});
      setRecentDeals(dealsData?.deals?.slice(0, 5) || []);
      setRecentActivities(activitiesData?.activities?.slice(0, 5) || []);
    } catch (err) {
      console.error('Error fetching account data:', err);
      setError('Failed to load account information');
    } finally {
      setLoading(false);
    }
  };

  // Format metric values helper
  const formatMetricValue = (value) => {
    if (typeof value === 'object') {
      if (value.hasOwnProperty('value')) return value.value;
      if (value.hasOwnProperty('count')) return value.count;
      return JSON.stringify(value);
    }
    if (typeof value === 'number') {
      if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`;
      if (value >= 1000) return `$${(value / 1000).toFixed(1)}K`;
      return `$${value.toString()}`;
    }
    return String(value || '0');
  };

  // Format date helper
  const formatDate = (date) => {
    try {
      return format(new Date(date), 'MMM d, yyyy');
    } catch (e) {
      return 'Invalid date';
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!selectedAccount) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">Please select an account to view details</Alert>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Metrics Panel - Now at the top */}
        {metrics && Object.keys(metrics).length > 0 && (
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Account Metrics
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                {/* Total Deals */}
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <DealsIcon color="primary" sx={{ mb: 1, fontSize: 32 }} />
                    <Typography variant="h4" color="primary">
                      {metrics.totalDeals || 0}
                    </Typography>
                    <Typography variant="body2">
                      Total Deals
                    </Typography>
                  </Box>
                </Grid>

                {/* Total Deal Value */}
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <MoneyIcon color="primary" sx={{ mb: 1, fontSize: 32 }} />
                    <Typography variant="h4" color="primary">
                      {formatMetricValue(metrics.totalDealValue)}
                    </Typography>
                    <Typography variant="body2">
                      Total Deal Value
                    </Typography>
                  </Box>
                </Grid>

                {/* Total Contacts */}
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ContactsIcon color="primary" sx={{ mb: 1, fontSize: 32 }} />
                    <Typography variant="h4" color="primary">
                      {metrics.totalContacts || 0}
                    </Typography>
                    <Typography variant="body2">
                      Total Contacts
                    </Typography>
                  </Box>
                </Grid>

                {/* Average Deal Size */}
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <AverageIcon color="primary" sx={{ mb: 1, fontSize: 32 }} />
                    <Typography variant="h4" color="primary">
                      {formatMetricValue(metrics.averageDealSize)}
                    </Typography>
                    <Typography variant="body2">
                      Average Deal Size
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        {/* Account Information Panel */}
        <Grid item xs={12} md={4}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Account Information
            </Typography>
            <Divider sx={{ my: 2 }} />
            
            <Box sx={{ '& > div': { mb: 2 } }}>
              <Box display="flex" alignItems="center">
                <BusinessIcon sx={{ mr: 2 }} />
                <Typography>{selectedAccount.name || 'Not specified'}</Typography>
              </Box>
              
              <Box display="flex" alignItems="center">
                <IndustryIcon sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">Industry</Typography>
                  <Typography>{selectedAccount.industry || 'Not specified'}</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <WebsiteIcon sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">Website</Typography>
                  <Typography>{selectedAccount.website || 'Not specified'}</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <LinkedInIcon sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">LinkedIn</Typography>
                  <Typography>{selectedAccount.linkedin || 'Not specified'}</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <NZBNIcon sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">NZBN</Typography>
                  <Typography>{selectedAccount.nzbn || 'Not specified'}</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <LocationIcon sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">Address</Typography>
                  <Typography>{selectedAccount.address || 'Not specified'}</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <PhoneIcon sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">Phone</Typography>
                  <Typography>{selectedAccount.phone || 'Not specified'}</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <EmailIcon sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">Email</Typography>
                  <Typography>{selectedAccount.email || 'Not specified'}</Typography>
                </Box>
              </Box>
            </Box>

            {selectedAccount.labels && selectedAccount.labels.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Labels
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selectedAccount.labels.map((label, index) => (
                    <Chip key={index} label={label} size="small" />
                  ))}
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Activity Panel */}
        <Grid item xs={12} md={8}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
              <Tab label="Recent Deals" />
              <Tab label="Recent Activity" />
            </Tabs>

            {activeTab === 0 && (
              <List>
                {recentDeals.length > 0 ? (
                  recentDeals.map((deal) => (
                    <ListItem key={deal.id} divider>
                      <ListItemIcon>
                        <MoneyIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={deal.title}
                        secondary={
                          <React.Fragment>
                            <Typography variant="body2" color="text.secondary">
                              Value: {formatMetricValue(deal.value)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Stage: {deal.stage || 'Not set'}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <Chip 
                        label={formatDate(deal.created_at || deal.createdAt)} 
                        size="small" 
                        variant="outlined"
                      />
                    </ListItem>
                  ))
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography color="text.secondary">No recent deals</Typography>
                  </Box>
                )}
              </List>
            )}

            {activeTab === 1 && (
              <List>
                {recentActivities.length > 0 ? (
                  recentActivities.map((activity) => (
                    <ListItem key={activity.id} divider>
                      <ListItemAvatar>
                        <Avatar>
                          <EventIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={activity.type}
                        secondary={
                          <React.Fragment>
                            <Typography variant="body2" color="text.secondary">
                              {activity.description}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {activity.assignedTo && `Assigned to: ${activity.assignedTo}`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <Chip 
                        label={formatDate(activity.date || activity.createdAt)} 
                        size="small" 
                        variant="outlined"
                      />
                    </ListItem>
                  ))
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography color="text.secondary">No recent activities</Typography>
                  </Box>
                )}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountDetailsDashboard; 
